import React from "react";

const Popup5 = () => {
  return (
    <>
      <div className="clients-pop4">
        <div className="client-container4">
          <div className="form87">
            <h2>Reminder sent successfully</h2>
            <form>
              <div className="pending_btn pending_btn1">
                <button className="pend">Back to Dashboard</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup5;
