import React, { useContext, useRef, useState } from "react";
import SideBar from "../component/SideBar";
import leftArrow from "../images/left-arrow.svg";
import enclouse from "../images/enclouse.png";
import DoneImg from "../images/done.svg";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/common/data";
import axios from "axios";
import { toast } from "react-toastify";
import { AlertContainer } from "../component/Alerts/ToastifyContainer";
import SidebarContext from "../context/sidebar/SidebarContext";
import { useDispatch, useSelector } from "react-redux";
import { message, notification } from "antd";

const Insurance = () => {
  const side = useContext(SidebarContext);
  const { currentUser, hospitalId } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    hospitalId: state.global.hospitalId,

  }));
  const dispatch = useDispatch();
  const { open, toggleOpen } = side;
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const navigate = useNavigate();

  const initialValue = {
    image1: null,
    image2: null,
    image3: null,
  };
  const [formData, setFormData] = useState(initialValue);
  const [showPreview, setShowPreview] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleImage = (e, img) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { image3 } = formData;

    var formDataUpdated = new FormData();
    formDataUpdated.append("insuranceImg1", formData.image1);
    formDataUpdated.append("insuranceImg2", formData.image2);
    formDataUpdated.append("rohiniCertificateImg", image3);
    formDataUpdated.append("hospitalId", hospitalId);



    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/signup/existingInsurance",
        data: formDataUpdated,
        headers: {
          "content-type": "multipart/form-data",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.status === 200) {
        setLoader(false);
        dispatch({ type: "SET_CURRENT_STEP", payload: "declarationStatus" });
      }
    } catch (e) {
      if (e.response.status === 500 || e.response.status === 400) {
        setLoader(false);
        notification.error({message : e.response.data.message});

      }
    }
  };
  return (
    <>

      <div className="form-bg">
        <div style={{ marginBottom: "16px" }}>
          <div className="cards">
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Basic Information</h4>
              </div>
            </div>
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Documents upload</h4>
              </div>
            </div>
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Add Facilities</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">4</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Existing Insurance</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">5</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Declaration</h4>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bg-gray card rounded bg-white shadow-sm"
          style={{ padding: "30px" }}
        >
          <h3 className="text-dark py-2 mb-4 justify-content-start d-flex">
            Existing Insurance
          </h3>
          <div className="text-center">
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-md-5 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Upload Existing Insurance / T. P. List
                </label>
                <div className="dar">
                  <label htmlFor="image1" className="form-control">
                    <img src={enclouse} alt="" />
                    {formData.image1 === null
                      ? "Upload"
                      : formData.image1.name.substring(0, 50)}
                    <input
                      type="file"
                      ref={imageRef1}
                      onChange={(e) => handleImage(e, "image1")}
                      id="image1"
                      hidden
                      aria-describedby="emailHelp"
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-5 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Upload Blacklisted / T. P. List
                </label>
                <div className="dar">
                  <label htmlFor="image2" className="form-control">
                    {formData.image2 === null
                      ? "Upload"
                      : formData.image2.name.substring(0, 50)}
                    <img src={enclouse} alt="" />
                    <input
                      type="file"
                      ref={imageRef2}
                      onChange={(e) => handleImage(e, "image2")}
                      id="image2"
                      hidden
                      aria-describedby="emailHelp"
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-5 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Rohini Certificate
                </label>
                <div className="dar">
                  <label htmlFor="image3" className="form-control">
                    {formData.image3 === null
                      ? "Upload"
                      : formData.image3.name.substring(0, 50)}
                    <img src={enclouse} alt="" />
                    <input
                      type="file"
                      ref={imageRef3}
                      onChange={(e) => handleImage(e, "image3")}
                      id="image3"
                      hidden
                      aria-describedby="emailHelp"
                    />
                  </label>
                </div>
              </div>
              <div
                className="flex save "
                style={{ justifyContent: "unset" }}
              >
                <button
                  onClick={handleSubmit}
                >
                  Save and Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {loader ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Insurance;
