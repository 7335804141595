import React, { useState } from "react";
import SidebarContext from "./SidebarContext";

const SidebarState = (props) => {
  const [open, setOpen] = useState(false);
  function toggleOpen() {
    setOpen(!open);
  }
  return (
    <SidebarContext.Provider value={{open, toggleOpen}}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export default SidebarState;
