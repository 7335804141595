import React from "react";
import { useNavigate } from 'react-router-dom';

const Approve = () => {
  const navigate=useNavigate();

  return (
    <>
      <div className="clients-pop5">
        <div className="client-container5">
          <div className="form87">
            <div className="d-flex flex-column" style={{ gap: "26px" }}>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Hospital</p>
                <p className="approve-item m-0">DDk Global Hospital</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Policy Holder</p>
                <p className="approve-item m-0">Arun Kumar Mehra</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Patient</p>
                <p className="approve-item m-0">Kartar Singh Mehra</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Insurance</p>
                <p className="approve-item m-0">Acko Insurance</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">TPA</p>
                <p className="approve-item m-0">FHPL</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Policy Active</p>
                <p className="approve-item m-0">25-Jul-2024</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">End Date</p>
                <p className="approve-item m-0">25-Jul-2024</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Sum Insured</p>
                <p className="approve-item m-0">500000</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Room Rent</p>
                <p className="approve-item m-0">5000</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Copay</p>
                <p className="approve-item m-0">NA</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Policy Type</p>
                <p className="approve-item m-0">Corporate</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Treatment</p>
                <p className="approve-item m-0">High grade fever, Multipal Vomiting, and lower abdomen Pain</p>
              </div>
              <div className="d-flex justify-around">
                <p className="approve-name m-0">Patient Pass History</p>
                <p className="approve-item m-0">Since 15 Year DM</p>
              </div>
            </div>
            <div className="flex" style={{gap : "12px", marginTop: "30px"}}>
              <button className="approve-btn" onClick={() => navigate('/superAdmin/approve/modal')}>Approve</button>
              <button className="reject-btn" onClick={() => navigate('/superAdmin/approve/reject')}>Reject</button>
              <button className="reject-btn" onClick={() => navigate('/superAdmin/approve/raise')}>Raise a Query</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Approve;
