import React, { useContext, useRef, useState } from "react";
import SideBar from "../component/SideBar";
import enclouse from "../images/enclouse.png";
import EditIcon from "../images/edit-icon.svg";
import axios from "axios";
import { baseUrl } from "../context/common/data";
import leftArrow from "../images/left-arrow.svg";
import DoneImg from "../images/done.svg";
import { toast } from "react-toastify";
import { AlertContainer } from "../component/Alerts/ToastifyContainer";
import SidebarContext from "../context/sidebar/SidebarContext";
import NavBar from "../component/NavBar";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

const banks = [
  "Bank of Baroda",
  "Bank of India",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "Indian Bank",
  "Indian Overseas Bank",
  "Punjab and Sind Bank",
  "Punjab National Bank",
  "State Bank of India",
  "UCO Bank",
  "Union Bank of India",
  "Axis Bank",
  "City Union Bank",
  "DCB Bank",
  "Federal Bank",
  "HDFC Bank",
  "ICICI Bank",
  "IDBI Bank",
  "IDFC FIRST Bank",
  "IndusInd Bank",
  "Jammu and Kashmir Bank",
  "Karnataka Bank",
  "Karur Vysya Bank",
  "Kotak Mahindra Bank",
  "RBL Bank",
  "South Indian Bank",
  "Yes Bank",
  "Dhanlaxmi Bank",
  "Bandhan Bank",
  "CSB Bank",
  "Tamilnad Mercantile Bank",
  "DBS Bank India Limited"
];

const Financial = () => {
  const { policyDetails, prompt } = useSelector((state) => ({
    policyDetails: state.global.policyDetails,
    prompt: state.global.prompt,

  }));
  console.log("Financial component mounted");
  const side = useContext(SidebarContext);
  const navigate = useNavigate();
  const { open, toggleOpen } = side;
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const toastId = React.useRef(null);

  const initialValue = {
    netBanking: "",
    debit: "",
    cName: "",
    cAddress: "",
    cCode: "",
    image1: null,
    image2: null,
  };
  const [formData, setFormData] = useState(initialValue);
  const [showPreview, setShowPreview] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resShow, setResShow] = useState(false);
  const [complete, setComplete] = useState(false);
  const [data, setData] = useState();
  console.log(formData)
  const ifscRegex = /\b[A-Z]{4}0[A-Z0-9]{6}\b/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImage = async (e, img) => {
    toastId.current = toast.loading("Uploading");
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });

    var formDataUpdated = new FormData();
    formDataUpdated.append("doc", e.target.files[0]);

    const res = await axios({
      method: "post",
      url: baseUrl + "/awstesseract/imgocr",
      data: formDataUpdated,
      headers: {
        "Content-Type": "multipart/form-data",
        //"Accept":"application/json, text/plain, */*"
      },
    });

    if (res.status === 200) {
      if (img === "image1") {
        toast.dismiss(toastId.current);
        const raw = res.data.text;
        console.log(raw);
        const foundBanks = banks.filter(bank => raw.toLowerCase().includes(bank.toLowerCase()));
        let newCName;
        if (foundBanks[0] === banks[1] && foundBanks.length > 1) {
          newCName = foundBanks[1];
        } else {
          newCName = foundBanks[0];
        }
        console.log(foundBanks);

        let ifsCode = raw.match(ifscRegex)
        let newCCode = ifsCode ? ifsCode[0].trim() : '';

        console.log({ ...formData, cName: newCName, cCode: newCCode });

        // Update state here
        setFormData(prevFormData => ({
          ...prevFormData,
          cName: newCName,
          cCode: newCCode
        }));
      }
    }


  };
  const handleSubmit = async (e) => {
    console.log("handleSubmit called");
    const { image1, image2 } = formData;
    if (
      image1 === null &&
      toast.error("Policy holder’s Canceled Cheque image is Required!")
    )
      return;
    // if (image2 === null && toast.error("Other document image is Required!"))
    //   return;
    var formDataUpdated = new FormData();
    formDataUpdated.append("_id", localStorage.getItem("PatientId"));
    formDataUpdated.append("canceledChequeImg", formData.image1);
    formDataUpdated.append("isSameAccount", formData.netBanking || "");
    formDataUpdated.append("isSameDebitCard", formData.debit || "");
    if (image2 !== null) {
      formDataUpdated.append("additinalDocImg", formData.image2);
    }
    formDataUpdated.append("canceledChequeData", JSON.stringify({ bankName: formData?.cName, ifscCode: formData?.cCode }) || {})
    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/patient/createpatient",
        data: formDataUpdated,
        headers: {
          "Content-Type": "multipart/form-data",
          //"Accept":"application/json, text/plain, */*"
        },
      });
      if (res.status === 201) {
        setComplete(true);
        setLoader(false);
        setTimeout(() => {
          setFormData(initialValue);
        }, 3000);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.status === 500) {
        setLoader(false);
      }
    }
  };

  const handleComplete = async (e) => {
    e.preventDefault();
    try {
      const req = {
        prompt: localStorage.getItem("prompt"),
        id: localStorage.getItem("PatientId"),
        policyDetails: localStorage.getItem("policyDetails"),
      };
      setLoader(true);
      const res = await axios.post(baseUrl + "/ai-decesionData", req, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      //add 2 sec delay and then show the response
      if (res.data?.success) {
        setLoader(false);
        setComplete(false)
        notification.success({ message: res.data?.message })
        toast.success("SuccessFull.");
        navigate('/')
      }

    } catch (error) {
      notification.error({ message: error.data })
    }

  };

  function handleDone() {
    setComplete(false);
    setOpenModal(false);
    window.location.href = "/";
  }

  const [cEdit1, setCEdit1] = useState(false);
  const [cEdit2, setCEdit2] = useState(false);
  const [cEdit3, setCEdit3] = useState(false);

  function handleCEdit1() {
    setCEdit1(!cEdit1);
    setCEdit2(false);
    setCEdit3(false);
  }
  function handleCEdit2() {
    setCEdit1(false);
    setCEdit2(!cEdit2);
    setCEdit3(false);
  }
  function handleCEdit3() {
    setCEdit1(false);
    setCEdit2(false);
    setCEdit3(!cEdit3);
  }

  const policyData = data;
  return (
    <>
      <AlertContainer />
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <NavBar />
          <div>
            <div className="form-bg">
              <div style={{ marginBottom: "16px" }}>
                <div className="cards">
                  <div className="carding">
                    <img src={DoneImg} alt="" />
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="active-case">Personal Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <img src={DoneImg} alt="" />
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="active-case">Case Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <img src={DoneImg} alt="" />
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="active-case">Credit Document</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <p className="step-number">4</p>
                    <h3>Step</h3>
                    <div>
                      <h4 className="active-case">Financial Information</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="bg-gray card rounded bg-white shadow-sm"
                style={{ padding: "30px" }}
              >
                <div className="aadhar-card">
                  <div className="flex justify-between items-center">
                    <h6 className="aadhar-card-title">
                      Upload Policy holder’s Canceled Cheque *
                    </h6>
                  </div>
                  <div
                    className="flex justify-between items-center"
                    style={{ gap: "20px", paddingTop: "14px" }}
                  >
                    <div className="dar " style={{ width: "100%" }}>
                      <img src={enclouse} alt="" />
                      <label htmlFor="image1" className="form-control">
                        {formData.image1 === null
                          ? "Upload"
                          : formData.image1.name.substring(0, 50)}
                        <input
                          type="file"
                          ref={imageRef1}
                          onChange={(e) => handleImage(e, "image1")}
                          id="image1"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="aadhar-detail-card">

                    {formData.cName !== '' && <div className="flex justify-between items-center">
                      <p className="aadhar-detail-text">Bank Name</p>
                      {cEdit1 ? (
                        <input
                          type="text"
                          className="card-input"
                          name="cName"
                          value={formData.cName}
                          onChange={handleChange}
                        />
                      ) : (
                        <p className="aadhar-detail-text" onClick={handleCEdit1}>{formData.cName}</p>
                      )}
                      <img src={EditIcon} onClick={handleCEdit1} alt="" />
                    </div>}
                    {/* <div className="flex justify-between items-center">
                      <p className="aadhar-detail-text">Address</p>
                      {cEdit2 ? (
                        <input
                          type="text"
                          className="card-input"
                          name="cAddress"
                          value={formData.cAddress}
                          onChange={handleChange}
                        />
                      ) : (
                        <p className="aadhar-detail-text">
                          {formData.cAddress}
                        </p>
                      )}
                      <img src={EditIcon} onClick={handleCEdit2} alt="" />
                    </div> */}
                    {formData.cCode !== '' && <div className="flex justify-between items-center">
                      <p className="aadhar-detail-text">IFSC code</p>
                      {cEdit3 ? (
                        <input
                          type="text"
                          className="card-input"
                          name="cCode"
                          maxLength={11}
                          value={formData.cCode}
                          onChange={handleChange}
                        />
                      ) : (
                        <p className="aadhar-detail-text" onClick={handleCEdit3}>{formData.cCode}</p>
                      )}
                      <img src={EditIcon} onClick={handleCEdit3} alt="" />
                    </div>}

                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Does the policy holder use same Bank for net banking as of
                    Canceled cheque?
                  </label>
                  <div className="row ms-1">
                    <div className="form-check col-md-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="yes"
                        onChange={handleChange}
                        name="netBanking"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check col-md-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="no"
                        onChange={handleChange}
                        name="netBanking"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Does the policy holder use same Bank’s debit card as of
                    canceled cheque?
                  </label>
                  <div className="row ms-1">
                    <div className="form-check col-md-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="yes"
                        onChange={handleChange}
                        name="debit"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check col-md-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="debit"
                        value="no"
                        onChange={handleChange}
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <label htmlFor="exampleInputEmail1" className="form-label mt-4">
                  Do you want to upload any other document that are relevant to
                  your case?
                </label>
                <div className="dar " style={{ width: "30%" }}>
                  <img src={enclouse} alt="" />
                  <label htmlFor="image2" className="form-control">
                    {formData.image2 === null
                      ? "Upload"
                      : formData.image2.name.substring(0, 50)}
                    <input
                      type="file"
                      ref={imageRef2}
                      onChange={(e) => handleImage(e, "image2")}
                      id="image2"
                      hidden
                      aria-describedby="emailHelp"
                    />
                  </label>
                </div>
                <div className="row save m-lg-1 ">
                  <button onClick={() => handleSubmit()}>Submit</button>
                  {/* <button onClick={() => setComplete(true)}>Submit</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {complete ? (
        <div className="clients-pop1">
          <div className="client-container1">
            <div className="form87">
              <h2>
                The patient has submitted the <br /> Declaration
              </h2>
              <form>
                <div className="btn_tr">
                  <div className="yes_brn yes_brn1">
                    <button>Resend</button>
                  </div>
                  <div className="submit_br">
                    <button style={{ margin: "0" }} onClick={handleComplete}>
                      Complete
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {openModal ? (
        <div className="clients-pop1">
          <div className="modal-container1">
            {resShow ? (
              <div className="modal-box">
                <div
                  className="flex items-center"
                  style={{ justifyContent: "center" }}
                >
                  <span className="modal-loader"></span>
                </div>
                <h6 className="modal-text">Running AI Engine</h6>
              </div>
            ) : (
              <div>
                <h6 className="modal-text-">Request submitted!</h6>
                <p>The Approval Status of your case would be updated Super Quickly and you will be notified</p>
                <div dangerouslySetInnerHTML={{ __html: policyData }} />
                <div className="submit_br">
                  <button className="mt-4" onClick={() => handleDone()}>
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {loader ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Financial;
