import React from "react";

function CompanyDetails() {
  return (
    <>
      <div className="form-bg">
        <div
          className="card rounded bg-white shadow-sm"
          style={{ padding: "30px" }}
        >
          <div className="text-center">
            <form className="row">
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Company Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Hr Mobile Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Hr Email Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Date of Joining
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Designation
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Upload Employed Id*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-4 mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Department
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="col-md-12 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Are you on a notice period
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-check  col-md-12 mt-5">
                <div className="submit">
                  <button>Save and Next</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
