import React, { useContext } from "react";
import SideBar from "../component/SideBar";
import { useNavigate } from "react-router-dom";
import leftArrow from "../images/left-arrow.svg";
import DoneImg from "../images/done.svg";
import SidebarContext from "../context/sidebar/SidebarContext";
import NavBar from "../component/NavBar";

const Credit = () => {
  const side = useContext(SidebarContext);
  const { open, toggleOpen } = side;
  const navigate = useNavigate();

  return (
    <>
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <NavBar />
          <div>
            <div className="form-bg">
              <div style={{ marginBottom: "16px" }}>
                <div className="cards">
                  <div className="carding">
                    <img src={DoneImg} alt="" />
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="active-case">Personal Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <img src={DoneImg} alt="" />
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="active-case">Case Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <p className="step-number">3</p>
                    <h3>Step</h3>
                    <div>
                      <h4 className="active-case">Credit Document</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <p className="step-number">4</p>
                    <h3>Step</h3>
                    <div>
                      <h4 className="active-case">Financial Information</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="bg-gray card rounded bg-white shadow-sm"
                style={{ padding: "30px" }}
              >
                <h3 className="admission-title pb-3">Lending Module</h3>
                <div className="row save m-lg-1 ">
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => {
                        navigate("/financial");
                      }}
                    >
                      Save and Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Credit;
