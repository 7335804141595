import React from 'react'
// import SideBar from '../component/SideBar';
// import NavBar from '../component/NavBar';
// import enclouse from '../images/enclouse.png'
// import elipi from '../images/elipi.png';
// import elipi1 from '../images/elipi1.png';
// import { useNavigate } from 'react-router-dom';

const Landing = () => {
  // const navigate=useNavigate();

  return (
    <>

    </>
  )
}

export default Landing;