import React, { useContext, useRef, useState } from "react";
import SideBar from "../component/SideBar";
import { FaUpload } from 'react-icons/fa';
import { AlertContainer } from "../component/Alerts/ToastifyContainer";
import SidebarContext from "../context/sidebar/SidebarContext";
import NavBar from "../component/NavBar";
import { baseUrl } from "../context/common/data";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

const NewAdmission = () => {
    const [loader, setLoader] = useState(false);
    const side = useContext(SidebarContext);
  const navigate = useNavigate();
    let approveId = useParams();
    let id = approveId.id;
    const { open, toggleOpen } = side;
    const initialValue = {
        'Detailed Bill': null,
        'Bill Summary': null,
        'Investigation Reports': null,
        'Investigation Reports With Films': null,
        'Progression Reports': null,
        'OT Notes': null,
        'Anesthesia Notes': null,
        'PAC Notes': null,
        'Vital Charts': null,
        'Treatment Chart': null,
        'Nursing Assessment': null,
        'Initial Assessment': null,
        'OPD Slip': null,
        'Receipts': null,
        'Discharge Summary': null,
        'Claim Form PartA': null,
        'Claim Form PartB': null,
    }
    const [formData, setFormData] = useState(initialValue);

    const fileRefs = useRef({});

    const handleChange = (e, key) => {
        const file = e.target.files[0];
        setFormData(prevFormData => ({
            ...prevFormData,
            [key]: file
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataArray = Object.entries(formData);

        const formDataNew = new FormData();
        formDataArray.forEach(([key, file]) => {
            formDataNew.append(key, file);
        });
        setLoader(true)
        try {
            const res = await axios.post(
                `${baseUrl}/hospital/uploadDischarge/${id}`,
                formDataNew,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data.success) {
                setFormData(initialValue);
                setLoader(false)
                navigate(`/patientData2/${id}`)
                notification.success({ message: res.data?.message || 'Uploaded' })
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };


    return (
        <>
            <AlertContainer />
            <div className="dashboard">
                <div
                    className="side-overlay"
                    style={{ left: open ? "0" : "100%" }}
                    onClick={toggleOpen}
                ></div>
                <SideBar />
                <div className="dashboard-content">
                    <NavBar />

                    <div className="form-bg">

                        <form onSubmit={handleSubmit}>
                            <h2 style={{ margin: '1em 0 1em 0' }}>Upload Discharge Documents</h2>
                            <div className="flex flex-wrap card-gap">
                                {Object.entries(formData).map(([key, value]) => (
                                    <div key={key} className="aadhar-card">
                                        <div className="flex justify-between items-center">
                                            <h6 className="aadhar-card-title"><p>{key}*</p></h6>
                                        </div>
                                        <label htmlFor={`${key}`} className="form-control" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {value === null ? "Upload" : value.name?.substring(0, 15)}
                                            <FaUpload />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleChange(e, key)}
                                                id={key}
                                                hidden
                                                ref={(element) => fileRefs.current[key] = element} // Assign ref to file input
                                                aria-describedby="emailHelp"
                                            />
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="row save m-lg-1">
                                <button type="submit">Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </>
    );
};

export default NewAdmission;

