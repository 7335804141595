import React from 'react';

const TogglerButton = ({ onClick }) => {
    return (
        <button className="custom-toggler" onClick={onClick}>
            <span className="custom-icon"></span>
            <span className="custom-icon"></span>
            <span className="custom-icon"></span>
        </button>
    );
};

export default TogglerButton;
