import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import Pi1 from './Pi1';
import Pi2 from './Pi2';
import Pi3 from './Pi3';
import Pi4 from './Pi4';

const PersonalInformation = () => {
    const {id}=useParams();
    const [value, setValue] = useState({
        insuranceType: '',
        patientType: '',
        phAdharCardFrontImg: '',
        phAdharCardBackImg: '',
        phPanCardFrontImg: '',
        email: '',
        number: '',

        phAdharCardData: '',

        phAadharName: '',
        phAadharDob: '',
        phAadharGender: '',
        phAadharNumber: '',
        phAadharAddress: '',

        phPanName: '',
        phPanDob: '',
        phPanNumber: '',

        phAadharName1: '',
        phAadharDob1: '',
        phAadharGender1: '',
        phAadharNumber1: '',
        phAadharAddress1: '',

        phPanName1: '',
        phPanDob1: '',
        phPanNumber1: '',
        
        adharCardFrontImg: '',
        adharCardBackImg: '',
        panCardFrontImg: '',
        name: '',
        isVisitOtherDoct: '',
        isAccident: '',
        prescriptionImg: '',
        diagnosticReportImg: '',
        firImg: '',
        otherDocImg: '',
        latestPolicyImg: '',
        policyDocImg: '',
        latestReceiptImg: '',
        isSameAccount: '',
        isSameDebitCard: '',
        canceledChequeImg: '',
        additinalDocImg: '',
    });

    const handleChange=(e)=>{
        if(e.target.files)
        {
            setValue({...value, [e.target.name]: e.target.files[0]});
        }
        else
        {
            setValue({...value, [e.target.name]: e.target.value});
        }
    }

    const handleSubmit=(e)=>{
        console.log(value);
    }

    return (
        <>
            {id==='1' ? <Pi1 value={value} handleChange={handleChange} /> : id==='2' ? <Pi2 value={value} handleChange={handleChange} /> : id==='3' ? <Pi3 value={value} handleChange={handleChange} /> : id==='4' ? <Pi4 value={value} handleChange={handleChange} /> : null}
        </>
    );
};

export default PersonalInformation;
