import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../component/SideBar";
import NavBar from "../../component/NavBar";
import axios, { all } from "axios";
import { baseUrl } from "../../context/common/data";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import SidebarContext from "../../context/sidebar/SidebarContext";
import { Button, Form, notification } from "antd";
import Input from "rc-input";
import { toast } from "react-toastify";

const PatientData = () => {
    const isLoggedin = localStorage.getItem("hospitalLogin");
    const side = useContext(SidebarContext);
    const { open, toggleOpen } = side;
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedin || isLoggedin !== "true") {
            navigate("/login");
        }
    }, [isLoggedin, navigate]);

    const [filterStatus, setFilterStatus] = useState({
        approve: false,
        reject: false,
        reassign: false,
    });

    const [items, setItems] = useState([]);
    const [originalItems, setOriginalItems] = useState([]); // This will hold the unaltered list\
    const [currentPage, setCurrentPage] = useState(1);
    const [totalFilter, setTotalFilter] = useState({
        totalApp: 0,
        approveApp: 0,
        rejectApp: 0,
        reassignApp: 0
    })
    const itemsPerPage = 10;

    function handleOnEdit(id) {
        navigate(`/patientData2/${id}`);
    }
    useEffect(() => {
        async function getData() {
            try {
                const res = await axios({
                    method: "get",
                    url: baseUrl + `/patient/getallhospitalpatient/${localStorage.getItem("hospitalId")}`,
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                });
                const respon = res?.data?.data
                console.log(respon);
                setOriginalItems(respon.filter(item => item.status == 'approve' || item.status == "Admitted")); // Store the original data

                setItems(respon);
                // handleFilter(respon)
            } catch (e) { }
        }
        getData();
    }, []);

    useEffect(() => {
        handleFilter(originalItems); // Always apply filters to the originalItems
    }, [originalItems, filterStatus]);

    const handleFilter = (data) => {
        // Start with filtering out 'completed' items first
        let filteredData = data.filter(e => e?.status?.toLowerCase() !== "completed");

        // Check if any filter is active
        const isAnyFilterActive = Object.values(filterStatus).some(status => status);

        // Apply the active filters to the already 'completed' excluded items
        if (isAnyFilterActive) {
            filteredData = filteredData.filter(e =>
                (filterStatus.approve && e?.status?.toLowerCase() === "approve") ||
                (filterStatus.reject && e?.status?.toLowerCase() === "reject") ||
                (filterStatus.reassign && e?.status?.toLowerCase() === "reassign")
            );
        }

        // Update total counts based on filtered data (excluding completed)
        setTotalFilter({
            totalApp: filteredData.length,
            approveApp: filteredData.filter(e => e?.status?.toLowerCase() === "approve").length,
            rejectApp: filteredData.filter(e => e?.status?.toLowerCase() === "reject").length,
            reassignApp: filteredData.filter(e => e?.status?.toLowerCase() === "reassign").length,
        });

        // Update the displayed items based on the filters applied above
        setItems(filteredData);
    };



    const handleCheckboxChange = (selectedStatus) => {
        // Determine if the current status is already active
        const isCurrentlyActive = filterStatus[selectedStatus];

        // Reset all filters
        setFilterStatus({
            approve: false,
            reject: false,
            reassign: false
        });

        // If the current status was not active, set it to true
        if (!isCurrentlyActive) {
            setFilterStatus(prevState => ({
                ...prevState,
                [selectedStatus]: true
            }));
        }
    };



    const totalPages = Math.ceil(items.length / itemsPerPage);

    const displayItems = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return items.slice(startIndex, endIndex);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const search = async (val) => {
        try {
            const res = await axios.post(
                `${baseUrl}/patient/getallSearchedhospitalpatient/${localStorage.getItem("hospitalId")}`,
                val
            );
            const respon = res?.data?.data
            console.log(res?.data, "CHECK")
            if (res?.data?.success) {
                setOriginalItems(respon);
                setItems(respon);
            } else {
                notification.error({ message: res?.data?.message || 'Something went wrong!' })
            }

        } catch (e) { }
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = Array.from(
        { length: totalPages },
        (_, index) => index + 1
    );
    return (
        <>
            {items === null ? (
                <></>
            ) : (
                <div className="dashboard">
                    <SideBar />
                    <div className="dashboard-content">
                        <NavBar />
                        <div className="site_data">

                            <div className="ty_table mt-4">
                                <h3>ADMITTED PATIENTS</h3>
                                <Form onFinish={search} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Item name="applicationNumber">
                                        <Input placeholder="Search By Application No." style={{ height: '30px', width: '100%', borderRadius: '5px', outline: 'none', paddingLeft: '0.5em' }} />
                                    </Form.Item>
                                    <Form.Item name="patientName">
                                        <Input placeholder="Search By Patient Name" style={{ marginLeft: '1em', height: '30px', width: '100%', borderRadius: '5px', outline: 'none', paddingLeft: '0.5em' }} />
                                    </Form.Item>
                                    <Form.Item name="patientStatus">
                                        <Input placeholder="Search By Patient Status" style={{ marginLeft: '2em', height: '30px', width: '100%', borderRadius: '5px', outline: 'none', paddingLeft: '0.5em' }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" style={{ backgroundColor: "rgb(62, 120, 155)", color: "white", marginLeft: '2.5em' }}>Search</Button>
                                    </Form.Item>
                                </Form>

                                <table className="table mt-0">

                                    <thead>
                                        <tr className="app-table-head">
                                            <th scope="col">Application Number</th>
                                            <th scope="col">Patient Name</th>
                                            <th scope="col">Submission Date</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayItems().map((element, index) => {
                                            return (
                                                <>
                                                    <tr key={index} className="app-table-body">
                                                        <td>{element?._id}</td>
                                                        <td>{element?.patient?.name || ""}</td>
                                                        <td>
                                                            {moment(element?.createdAt).format("DD/MM/YYYY")}
                                                        </td>
                                                        <td>{element?.patientType}</td>
                                                        <td
                                                            className={
                                                                (element?.status === "pending" ? "pending" : "")
                                                                + (element?.status === "reject" ? "reject" : "")
                                                                + (element?.status === "approve" ? "approved" : "")
                                                                + (element?.status === "reassign" ? "reassign" : "")
                                                            }
                                                        >
                                                            {element?.status}
                                                        </td>
                                                        <td onClick={() => handleOnEdit(element?._id)}>
                                                            <svg
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.5 18.3333H2.5C2.15833 18.3333 1.875 18.0499 1.875 17.7083C1.875 17.3666 2.15833 17.0833 2.5 17.0833H17.5C17.8417 17.0833 18.125 17.3666 18.125 17.7083C18.125 18.0499 17.8417 18.3333 17.5 18.3333Z"
                                                                    fill="#1185CB"
                                                                />
                                                                <path
                                                                    d="M15.8505 2.90127C14.2338 1.2846 12.6505 1.24294 10.9921 2.90127L9.9838 3.9096C9.90047 3.99294 9.86714 4.12627 9.90047 4.24294C10.5338 6.45127 12.3005 8.21794 14.5088 8.85125C14.5421 8.85959 14.5755 8.86792 14.6088 8.86792C14.7005 8.86792 14.7838 8.83459 14.8505 8.76792L15.8505 7.7596C16.6755 6.94294 17.0755 6.15127 17.0755 5.35127C17.0838 4.52627 16.6838 3.72627 15.8505 2.90127Z"
                                                                    fill="#1185CB"
                                                                />
                                                                <path
                                                                    d="M13.0084 9.60911C12.7668 9.49244 12.5334 9.37577 12.3084 9.24244C12.1251 9.13411 11.9501 9.01744 11.7751 8.89244C11.6334 8.80077 11.4668 8.66744 11.3084 8.53411C11.2918 8.52577 11.2334 8.47577 11.1668 8.40911C10.8918 8.17576 10.5834 7.87576 10.3084 7.54242C10.2834 7.52576 10.2418 7.46742 10.1834 7.39242C10.1001 7.29242 9.95842 7.12576 9.83342 6.93409C9.73342 6.80909 9.61675 6.62576 9.50842 6.44242C9.37509 6.21742 9.25842 5.99242 9.14175 5.75909C9.12408 5.72127 9.107 5.68362 9.0905 5.64621C8.9675 5.36844 8.60525 5.28723 8.3905 5.50203L3.61677 10.2758C3.50844 10.3841 3.40844 10.5924 3.38344 10.7341L2.93344 13.9258C2.8501 14.4924 3.00844 15.0258 3.35844 15.3841C3.65844 15.6758 4.0751 15.8341 4.5251 15.8341C4.6251 15.8341 4.7251 15.8258 4.8251 15.8091L8.0251 15.3591C8.1751 15.3341 8.38342 15.2341 8.48342 15.1258L13.2512 10.358C13.4675 10.1417 13.3859 9.77052 13.1046 9.65044C13.0729 9.63694 13.0408 9.62311 13.0084 9.60911Z"
                                                                    fill="#1185CB"
                                                                />
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                <div className="prev_mect">
                                    <div className="prev">
                                        <span onClick={handlePrevPage} disabled={currentPage === 1}>
                                            Previous
                                        </span>
                                    </div>
                                    {pageNumbers.map((number) => (
                                        <button
                                            key={number}
                                            onClick={() => handlePageChange(number)}
                                            className={currentPage === number ? "active" : ""}
                                        >
                                            {number}
                                        </button>
                                    ))}
                                    <div className="next">
                                        <span
                                            onClick={handleNextPage}
                                            disabled={currentPage === totalPages}
                                        >
                                            Next
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PatientData;
