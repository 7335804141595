import React, { useContext, useEffect, useRef } from "react";
import SideBar from "../../component/SideBar";
import leftArrow from "../../images/left-arrow.svg";
import enclouse from "../../images/enclouse.png";
import { useState } from "react";
import DoneImg from "../../images/done.svg";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../context/common/data";
import axios from "axios";
import { AlertContainer } from "../../component/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import SidebarContext from "../../context/sidebar/SidebarContext";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";

const Document = () => {
  const side = useContext(SidebarContext);
  const { currentUser, hospitalId } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    hospitalId: state.global.hospitalId,

  }));
  const dispatch = useDispatch();
  const { open, toggleOpen } = side;
  const accreditationRef = useRef();
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRefPan = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();
  const imageRef5 = useRef();
  const navigate = useNavigate();

  const initialValue = {
    accreditation: "",
    accreditationIMG: null,
    other3: "",
    hospCertificateNumber: "",
    odCertificateNumber: "",
    doj: "",
    doe: "",
    appliedDocs: null,
    imagePan: null,
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  };
  const [formData, setFormData] = useState(initialValue);
  const [expiry, setExpiry] = useState(false)
  const [showPreview, setShowPreview] = useState(null);
  const [selectedAccreditation, setSelectedAccreditation] = useState([]);
  const [accreditationOthers, setAccreditationOthers] = useState('')

  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "doe") {
      var today = new Date();
      var year = today.getFullYear();
      var month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
      var day = String(today.getDate()).padStart(2, '0');
      var formattedDate = year + '-' + month + '-' + day;
      if (value < formattedDate) {
        setExpiry(true)
      } else {
        setExpiry(false)
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleImage = (e, img) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };
  const handleChangeCheckbox = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedAccreditation([...selectedAccreditation, value]);
    } else {
      setSelectedAccreditation(selectedAccreditation.filter((item) => item !== value));
    }
  };

  const handleChangeOthers = (e) => {
    const { value } = e.target;
    setAccreditationOthers(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      accreditationIMG,
      image1,
      image2,
      imagePan,
      hospCertificateNumber,
      doj,
      doe,
      appliedDocs,
      image3,
    } = formData;

    if (hospCertificateNumber === "") {
      notification.error({ message: "Hospital Certificate Number is Required!" })
      return
    }
    if (image1 === null) {
      notification.error({ message: "Certificates image is Required!" })
      return;
    }
    if (doj === "") {
      notification.error({ message: "Date Of Joining is Required!" })
      return;
    }
    if (doe === "") {
      notification.error({ message: "Date Of Expiry is Required!" })
      return;
    }

    if (image3 === null) {
      notification.error({ message: "Images is Required!" })
      return;
    }
    if (image2 === null) {
      notification.error({ message: "Images is Required!" })
      return;
    }
    const formDataUpdated = new FormData();

    formDataUpdated.append("accreditation", selectedAccreditation.join(","));
    formDataUpdated.append("accreditationOthers", accreditationOthers);

    if (accreditationIMG !== "null") {
      formDataUpdated.append("accreditationIMG", formData.accreditationIMG);
    }
    formDataUpdated.append("hospCertificateNumber", formData.hospCertificateNumber);
    formDataUpdated.append("hospDateOfJoining", formData.doj);
    formDataUpdated.append("hospDateOfExpiry", formData.doe);
    formDataUpdated.append("hospCertificateImg", formData.image1);
    if (expiry) {
      formDataUpdated.append("hospAppliedDocsImg", formData.appliedDocs);
    }
    formDataUpdated.append("odAdharCard", formData.image2);
    formDataUpdated.append("odPANCard", formData.imagePan);
    formDataUpdated.append("img1", formData.image3);
    formDataUpdated.append("img2", formData.image4);
    formDataUpdated.append("hospitalId", hospitalId);

    // setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/signup/docupload",
        data: formDataUpdated,
        headers: {
          "content-type": "multipart/form-data",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.status === 200 || res.status === 201) {
        setLoader(false);
        toast.success("SuccessFull.");
        dispatch({ type: "SET_CURRENT_STEP", payload: "facilityAdded" });
      }
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.status === 500) {
        setLoader(false);
      }
    }
  };

  return (
    <>

      <div className="form-bg">
        <div style={{ marginBottom: "16px" }}>
          <div className="cards">
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Basic Information</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">2</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Documents upload</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">3</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Add Facilities</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">4</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Existing Insurance</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">5</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Declaration</h4>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bg-gray card rounded bg-white shadow-sm"
          style={{ padding: "30px" }}
        >
          <div className="text-center">
            <h3 className="text-dark py-2 justify-content-start d-flex">
              Document Upload
            </h3>

            <form onSubmit={handleSubmit} className="row">
              <h6 className="text-dark py-2 mb-2 justify-content-start d-flex">
                Accreditation
              </h6>
              <div style={{ display: "flex", alignItems: 'center' }}>
                <div className="form-check w-fit hos-doc">
                  <label className="checkbox-container" htmlFor="radio1">
                    <input
                      className="custom-checkbox"
                      onChange={handleChangeCheckbox}
                      value="NABH"
                      type="checkbox"
                      name="accreditation"
                      id="radio1"
                      required
                    />
                    <span className="checkmark"></span>
                    NABH
                  </label>
                </div>
                <div className="form-check w-fit hos-doc">
                  <label className="checkbox-container" htmlFor="radio2">
                    <input
                      onChange={handleChangeCheckbox}
                      value="NABL"
                      className="custom-checkbox"
                      type="checkbox"
                      name="accreditation"
                      id="radio2"
                    />
                    <span className="checkmark"></span>
                    NABL
                  </label>
                </div>

                <div className="form-check w-fit form-check111 hos-doc">
                  <label>Others</label>
                  <input onChange={handleChangeOthers} name="others" className="form-control" />
                </div>

                <div className="col-md-4 mb-3 text-start">
                  <label className="form-check-label" htmlFor="accreditationIMG">
                    Upload Accreditation Certificate
                  </label>
                  <div className="dar">
                    <img src={enclouse} alt="" />
                    <label htmlFor="accreditationIMG" className="form-control">
                      {formData.accreditationIMG === null
                        ? "Upload"
                        : formData.accreditationIMG.name?.substring(0, 50)}
                      <input
                        type="file"
                        ref={accreditationRef}
                        onChange={(e) => handleImage(e, "accreditationIMG")}
                        id="accreditationIMG"
                        hidden
                        aria-describedby="emailHelp"
                      />
                    </label>
                  </div>
                </div>

              </div>

              <div className="owner_doctor mt-4">
                <h3 className="text-dark py-2 mb-4  justify-content-start d-flex">
                  Hospital Registration
                </h3>
                <div className="row">
                  <div className="col-md-4 mb-3 text-start">
                    <label
                      htmlFor="certificate_number"
                      className="form-label"
                    >
                      <span style={{ color: 'red' }}>*</span>Certificate Number
                    </label>
                    <div className="dar">
                      <input
                        onChange={handleChange}
                        value={formData.hospCertificateNumber}
                        type="text"
                        className="form-control"
                        name="hospCertificateNumber"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    >
                      <span style={{ color: 'red' }}>*</span>Upload Registration Certificates
                    </label>
                    <div className="dar">
                      <img src={enclouse} alt="" />
                      <label htmlFor="image1" className="form-control">
                        {formData.image1 === null
                          ? "Upload"
                          : formData.image1.name.substring(0, 50)}
                        <input
                          type="file"
                          ref={imageRef1}
                          onChange={(e) => handleImage(e, "image1")}
                          id="image1"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3 text-start">
                    <label htmlFor="doj" className="form-label">
                      <span style={{ color: 'red' }}>*</span>Date of Registration
                    </label>
                    <div className="dar">
                      <input
                        onChange={handleChange}
                        value={formData.doj}
                        name="doj"
                        type="date"
                        className="form-control"
                        id="doj"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div className="col-md-4 mb-3 text-start">
                    <label htmlFor="doe" className="form-label">
                      <span style={{ color: 'red' }}>*</span>Date of Expiry
                    </label>
                    <div className="dar">
                      <input
                        onChange={handleChange}
                        value={formData.doe}
                        name="doe"
                        type="date"
                        className="form-control"
                        id="doj"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  {expiry && <div className="col-md-4 mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    >
                      <span style={{ color: 'red' }}>*</span>Upload Applied Certificates
                    </label>
                    <div className="dar">
                      <img src={enclouse} alt="" />
                      <label htmlFor="appliedDocs" className="form-control">
                        {formData.appliedDocs === null
                          ? "Upload"
                          : formData.appliedDocs.name.substring(0, 50)}
                        <input
                          type="file"
                          ref={imageRef5}
                          onChange={(e) => handleImage(e, "appliedDocs")}
                          id="appliedDocs"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                    </div>
                  </div>}
                </div>
              </div>

              <div className="owner_doctor mt-2">
                <h3 className="text-dark py-2 mb-4  justify-content-start d-flex">
                  Owner Details
                </h3>
                <div className="row">
                  <div className="col-md-4 mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    >
                      <span style={{ color: 'red' }}>*</span> Upload Adhar
                    </label>
                    <div className="dar">
                      <img src={enclouse} alt="" />
                      <label htmlFor="image2" className="form-control">
                        {formData.image2 === null
                          ? "Upload"
                          : formData.image2?.name.substring(0, 50)}
                        <input
                          type="file"
                          ref={imageRef2}
                          onChange={(e) => handleImage(e, "image2")}
                          id="image2"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    >
                      <span style={{ color: 'red' }}>*</span>Pan Card
                    </label>
                    <div className="dar">
                      <img src={enclouse} alt="" />
                      <label htmlFor="imagePan" className="form-control">
                        {formData.imagePan === null
                          ? "Upload"
                          : formData.imagePan?.name.substring(0, 50)}
                        <input
                          type="file"
                          ref={imageRefPan}
                          onChange={(e) => handleImage(e, "imagePan")}
                          id="imagePan"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                    </div>
                  </div>


                </div>
              </div>

              <div className="images mt-2">
                <h3 className="text-dark py-2 mb-4  justify-content-start d-flex">
                  Images <span>(Add minimum 6 images)</span>{" "}
                </h3>
                <div className="img_jan row">
                  <div className="img_san">
                    <div className="d-flex flex-column">
                      <label htmlFor="image3" className="bottom-label">
                        <p>+</p>
                        <input
                          type="file"
                          ref={imageRef3}
                          onChange={(e) => handleImage(e, "image3")}
                          id="image3"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {showPreview?.image3 && (
                          <img
                            alt=""
                            height="100px"
                            src={showPreview.image3}
                          />
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <label htmlFor="image4" className="bottom-label">
                        <p>+</p>
                        <input
                          type="file"
                          ref={imageRef4}
                          onChange={(e) => handleImage(e, "image4")}
                          id="image4"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {showPreview?.image4 && (
                          <img
                            alt=""
                            height="100px"
                            src={showPreview.image4}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row save m-lg-1 ">
                <button
                  onClick={handleSubmit}
                >
                  Save and Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {loader ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Document;
