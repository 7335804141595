import React, { useContext } from "react";
import elipi from "../../images/elipi.png";
import enclouse from "../../images/enclouse.png";
import SideBar from "../SideBar";
import NavBar from "../NavBar";
import { useNavigate } from "react-router-dom";
import SidebarContext from "../../context/sidebar/SidebarContext";

const Pi4 = ({ value, setValue, handleChange }) => {
  const navigate = useNavigate();
  const side = useContext(SidebarContext);
  const { open, toggleOpen } = side;

  return (
    <>
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <NavBar />

          <div className="form-bg">
            <div>
              <div className="darg my-2 py-3">
                <div className="cards">
                  <div className="carding">
                    <img src={elipi} alt="" />
                    <h3>Step</h3>
                    <div>
                      <h4>Personal Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <img src={elipi} alt="" />
                    <h3>Step</h3>
                    <div>
                      <h4>Case Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <img src={elipi} alt="" />
                    <h3>Step</h3>
                    <div>
                      <h4>Credit Document</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <img src={elipi} alt="" />
                    <h3>Step</h3>
                    <div>
                      <h4>Financial Information</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
                <div className="container text-center">
                  <div className="flex">
                    <div className="pi-box">
                      <div className="pi-box1">
                        <div className="pi-box11 flex">
                          <p>Upload Policy holder’s Canceled Cheque *</p>
                          <img
                            width={13}
                            height={13}
                            src="/images/hide.png"
                            alt=""
                          />
                        </div>

                        <div className="pi-box12 flex">
                          <div className="dar w-full mx-0">
                            <p>Upload</p>
                            <img src={enclouse} alt="" />
                            <input
                              type="file"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                            />
                          </div>
                        </div>

                        <div className="pi-box13 flex">
                          <div className="pi-box131">
                            <p>Bank Name</p>
                            <p>Address</p>
                            <p>IFSC code</p>
                          </div>
                          <div className="pi-box132">
                            <div className="pi-box132-div">
                              <p>{value?.phPanName1}</p>
                              <input
                                type="text"
                                value={value?.phPanName1}
                                handleChange={handleChange}
                                name=""
                              />
                            </div>

                            <div className="pi-box132-div">
                              <p>{value?.phPanName1}</p>
                              <input
                                type="text"
                                value={value?.phPanName1}
                                handleChange={handleChange}
                                name=""
                              />
                            </div>

                            <div className="pi-box132-div">
                              <p>{value?.phPanName1}</p>
                              <input
                                type="text"
                                value={value?.phPanName1}
                                handleChange={handleChange}
                                name=""
                              />
                            </div>
                          </div>
                          <div className="pi-box133">
                            <img src="/images/edit.png" alt="" />
                            <img src="/images/edit.png" alt="" />
                            <img src="/images/edit.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label flex"
                    >
                      Does the policy holder use same Bank for net banking as of
                      Canceled cheque?{" "}
                    </label>
                    <div className="row ms-1">
                      <div className="form-check w-fit me-10">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check w-fit">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label flex text-start"
                    >
                      Does the policy holder use same Bank’s debit card as of
                      canceled cheque?{" "}
                    </label>
                    <div className="row ms-1">
                      <div className="form-check w-fit me-10">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check w-fit">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="pi-box11 flex">
                      <p>
                        Do you want to upload any other document that are
                        relevant to your case?{" "}
                      </p>
                    </div>

                    <div className="pi-box12 pi4-box-w flex">
                      <div className="dar w-full mx-0">
                        <p>Upload</p>
                        <img src={enclouse} alt="" />
                        <input
                          type="file"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="save m-lg-1 ">
                  <button>Save and Next</button>
                </div>
              </div>
            </div>

            <div className="clients-pop">
              <div className="client-container">
                <div className="form87">
                  <h2>
                    The patient has submitted the <br /> Declaration
                  </h2>
                  <form>
                    <div className="btn_tr">
                      <div className="yes_brn">
                        <button>Yes</button>
                      </div>
                      <div className="pending_btn">
                        <button>Pending</button>
                      </div>
                    </div>

                    <div className="submit_br">
                      <button>Submit</button>
                    </div>
                  </form>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pi4;
