import React, { useEffect, useState } from 'react';
import { baseUrl } from '../context/common/data';
import axios from 'axios';
import { notification } from 'antd';

const NotificationDropdown = ({ notificationsCount }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notifications, setNotifications] = useState([]);
    console.log('notifications', notificationsCount);

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        if (notifications.length > 0) {
            setDropdownVisible(!dropdownVisible);
        }
    }

    //api call to get notifications
    var formData = new FormData();
    formData.append('id', localStorage.getItem('hospitalId'));
    const getNotifications = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: baseUrl + '/latest-notification',
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (res?.status === 200) {
                console.log('res', res.data.notifications);
                setNotifications(res?.data?.notifications);
            }
        } catch (error) {
            notification.error({message:error?.response?.data?.message})
        }


    }
    useEffect(() => {
        getNotifications();
    }
        , []);

    return (
        <div className="dropdown">
            <div
                className="text-reset me-3 dropdown-toggle hidden-arrow"
                role="button"
                onClick={toggleDropdown}
                aria-expanded={dropdownVisible}
            >
                <i className="bi bi-bell">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-bell"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    </svg>
                </i>
                {notificationsCount > 0 && (
                    <span className="badge rounded-pill badge-notification bg-danger">
                        {notificationsCount}
                    </span>
                )}
            </div>
            {dropdownVisible && (
                <ul className="dropdown-menu dropdown-menu-end" style={{ display: 'flow', right: 0, left: 'auto' }}>
                    {notifications.map((notification, index) => (
                        <li key={index}>
                            <a className="dropdown-item" href={notification.link} target="_blank" rel="noopener noreferrer">
                                <strong>{notification.title}</strong><br />
                                <span>{notification.text}</span><br />
                                <small>Received: {new Date(notification.createdAt).toLocaleString()}</small>
                                {notification.read === false ? <span style={{ color: 'red' }}> • New</span> : null}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default NotificationDropdown;
