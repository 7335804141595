import React, { useContext, useRef, useState } from "react";
import SideBar from "../component/SideBar";
import enclouse from "../images/enclouse.png";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/common/data";
import axios from "axios";
import EditIcon from "../images/edit-icon.svg";
import leftArrow from "../images/left-arrow.svg";
import { AlertContainer } from "../component/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import SidebarContext from "../context/sidebar/SidebarContext";
import NavBar from "../component/NavBar";

const NewAdmission = () => {
  const side = useContext(SidebarContext);
  const { open, toggleOpen } = side;
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();
  const imageRef5 = useRef();
  const navigate = useNavigate();

  const initialValue = {
    insuranceType: "",
    patientType: "",
    email: "",
    number: "",
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    image6: null,
  };
  const [formData, setFormData] = useState(initialValue);
  const [showPreview, setShowPreview] = useState(null);
  const [loader, setLoader] = useState(false);
  const [policyCheck, setPolicyCheck] = useState(false);
  const [phAdharCardData, setPhAdharCardData] = useState({
    name: "",
    dob: "",
    gender: "",
    address: "",
    aadharNo: "",
  });
  const [phPanCardData, setPhPanCardData] = useState({
    name: "",
    dob: "",
    panNo: "",
  });
  const [adharCardData, setAdharCardData] = useState({
    name: "",
    dob: "",
    gender: "",
    address: "",
    aadharNo: "",
  });

  // Function to process the image and return a Blob
  function processImage(file) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        adjustBrightnessContrast(ctx, canvas.width, canvas.height, 2, 100);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };

      image.onerror = reject;
    });
  }


  function adjustBrightnessContrast(ctx, width, height, brightness, contrast) {
    const imageData = ctx.getImageData(0, 0, width, height);
    const data = imageData.data;

    const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));

    for (let i = 0; i < data.length; i += 4) {
      data[i] = Math.min(255, Math.max(0, data[i] * brightness));     // Red
      data[i + 1] = Math.min(255, Math.max(0, data[i + 1] * brightness)); // Green
      data[i + 2] = Math.min(255, Math.max(0, data[i + 2] * brightness)); // Blue

      data[i] = Math.min(255, Math.max(0, factor * (data[i] - 128) + 128));     // Red
      data[i + 1] = Math.min(255, Math.max(0, factor * (data[i + 1] - 128) + 128)); // Green
      data[i + 2] = Math.min(255, Math.max(0, factor * (data[i + 2] - 128) + 128)); // Blue
    }

    ctx.putImageData(imageData, 0, 0);
  }


  const aJson = localStorage.getItem("aadharCard");
  const adharCard = JSON.parse(aJson);

  const formatDate = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const handlePDF = async (e, img, type, isNeedBrighten = false) => {
    let objectUrl = "";
    let processedBlob = null;
    if (e.target?.files[0]?.type === "application/pdf") {
      objectUrl = URL.createObjectURL(e.target?.files[0]);
    } else {
      processedBlob = await processImage(e.target?.files[0]);
      objectUrl = URL.createObjectURL(processedBlob);
    }
    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });

    var formDataUpdated1 = new FormData();
    if (isNeedBrighten && processedBlob) {
      formDataUpdated1.append("aadharCardFront", processedBlob, e.target?.files[0].name);
    } else {
      formDataUpdated1.append("aadharCardFront", e.target?.files[0]);
    }

    formDataUpdated1.append("docType", type);

    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/imageToText",
        data: formDataUpdated1,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        console.log("should be called");
        setLoader(false);
        // toast.success("SuccessFull.");
      }
      if (img === "image1") {
        localStorage.setItem("aadharCard", JSON.stringify(res?.data?.data));
        localStorage.setItem("aadharCardPolicyHolder", JSON.stringify(res?.data?.data));
        const res2 = res?.data?.data;
        // setFront(res2);
        setPhAdharCardData({
          ...phAdharCardData,
          name: res2?.name,
          dob: res2?.dob ? formatDate(res2?.dob) : "",
          gender: res2?.gender,
          aadharNo: res2?.aadharNo,
        });
      }
      if (img === "image2") {
        const res3 = res?.data?.data;
        // setBack(res3);
        setPhAdharCardData({ ...phAdharCardData, address: res3?.address });
      }
      if (img === "image3") {
        const res4 = res?.data?.data;
        setPhPanCardData({
          ...phPanCardData,
          name: res4?.name,
          dob: res4.dob ? formatDate(res4?.dob) : "",
          panNo: res4?.panNumber,
        });
        if (res.status === 200) {
          if (res?.data?.data?.name) {
            console.log("pan name exist");
            if (
              phAdharCardData?.name.toLowerCase() !==
              res?.data?.data?.name.toLowerCase() &&  phAdharCardData?.name !== ""
            ) {
              if (isNeedBrighten) toast.error("Name did not match use valid document!");
              return
            }
          } else {
            console.log("pan name didn't exist");
            if (
              res?.data?.data?.raw
                .toLowerCase()
                .includes(adharCard?.name.toLowerCase())
            ) {
              console.log("updated name from aadhar");
              // setPanData((prevData) => ({
              //   ...prevData,
              //   name: adharCard?.name,
              // }));
              setPhPanCardData((phPanCardData) => ({
                ...phPanCardData,
                name: phAdharCardData?.name,
              }));
            } else {
              if (isNeedBrighten == true) toast.error("Name did not match use valid document!!");
            }
          }
        }
        if (res.status === 200) {
          if (res?.data?.data?.dob) {
            if (adharCard?.dob !== res?.data?.data?.dob && adharCard?.dob !== "") {
              if (isNeedBrighten) toast.error("Date of Birth did not match use valid document!");
              return
            }
          } else {
            if (
              res?.data?.data?.raw
                .toLowerCase()
                .includes(adharCard?.dob.toLowerCase())
            ) {
              console.log("updated dob from aadhar");
              setPhPanCardData((phPanCardData) => ({
                ...phPanCardData,
                dob: formatDate(phAdharCardData?.dob),
              }));
            } else {
              if (isNeedBrighten) toast.error("Date of Birth did not match use valid document!");
            }
          }
        }
        if (phAdharCardData?.name.toLowerCase() !== res4?.name.toLowerCase()
          && adharCard?.dob !== res4?.dob) {
          console.log("Name and Date of Birth did not match use valid document!");
          if (!isNeedBrighten) {
            handlePDF(e, img, type, true);
            return;
          }
        } else {
          //do nothing
        }
      }
      if (img === "image4") {
        const res5 = res?.data?.data;
        localStorage.setItem("aadharCard", JSON.stringify(res?.data?.data));
        // setPFront(res?.data?.data);
        setAdharCardData({
          ...adharCardData,
          name: res5?.name,
          dob: res5.dob ? formatDate(res5?.dob) : "",
          gender: res5?.gender,
          aadharNo: res5?.aadharNo,
        });
      }
      if (img === "image5") {
        // setPBack(res?.data?.data);
        const res6 = res?.data?.data;
        setAdharCardData({
          ...adharCardData,
          address: res6?.address,
        });
      }
      // setPanData(res?.data?.data);

    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (e?.response?.status === 400) {
        setLoader(false);
      }
      if (e?.response?.status === 500) {
        setLoader(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { image1, email, number, image2, image3 } = formData;
    if (image1 === null && toast.error("Aadhar front image is Required!")) return;
    if (image2 === null && toast.error("Aadhar Back image is Required!")) return;
    if (image3 === null && toast.error("Pan Back image is Required!")) return;
    if (email === "" && toast.error("email is Required!")) return;
    if (number === "" && toast.error("mobile number is Required!")) return;
    if (number.length !== 10 && toast.error("mobile number is Invalid!")) return;
    if (formData?.insuranceType === "" && toast.error("Insurance Type is Required!")) return;
    if (formData?.patientType === "" && toast.error("Patient Type is Required!")) return;
    // if (phAdharCardData?.name === "" && toast.error("Policy Holder Name is Required!")) return;
    // if (phAdharCardData?.dob === "" && toast.error("Policy Holder Date of Birth is Required!")) return;
    // if (phAdharCardData?.aadharNo === "" && toast.error("Policy Holder Aadhar Number is Required!")) return;
    // if (phAdharCardData?.address === "" && toast.error("Policy Holder Address is Required!")) return;
    // if (phPanCardData?.gender === "" && toast.error("Policy Holder Gender is Required!")) return;
    // if (phPanCardData?.name === "" && toast.error("Policy Holder Pan Name is Required!")) return;
    // if (phPanCardData?.dob === "" && toast.error("Policy Holder Pan Date of Birth is Required!")) return;
    // if (phPanCardData?.panNo === "" && toast.error("Policy Holder Pan Number is Required!")) return;
    if (!policyCheck && adharCardData?.name === "" && toast.error("Patient Name is Required!")) return;
    if (!policyCheck && adharCardData?.dob === "" && toast.error("Patient Date of Birth is Required!")) return;
    if (!policyCheck && adharCardData?.gender === "" && toast.error("Patient Gender is Required!")) return;
    if (!policyCheck && adharCardData?.aadharNo === "" && toast.error("Patient Aadhar Number is Required!")) return;
    if (!policyCheck && adharCardData?.address === "" && toast.error("Patient Address is Required!")) return;

    var formDataUpdated = new FormData();
    formDataUpdated.append("hospitalId", localStorage.getItem("hospitalId"));
    formDataUpdated.append("insuranceType", formData?.insuranceType || "");
    formDataUpdated.append("patientType", formData?.patientType || "");
    formDataUpdated.append("phAdharCardFrontImg", formData.image1);
    formDataUpdated.append("phAdharCardBackImg", formData.image2);
    formDataUpdated.append("phPanCardFrontImg", formData.image3);
    if (policyCheck === false) {
      formDataUpdated.append("adharCardFrontImg", formData.image4);
    }
    if (policyCheck === false) {
      formDataUpdated.append("adharCardBackImg", formData.image5);
    }
    if (policyCheck === false && (formData?.image4 === null || formData?.image5 === null)) {
      return toast.error("Please Upload Patined Aadhar Card images.")
    }

    formDataUpdated.append("email", formData.email || "");
    formDataUpdated.append("number", formData.number || "");
    if (policyCheck === true) {
      formDataUpdated.append("name", phAdharCardData.name || "");
      formDataUpdated.append("adharCardData", JSON.stringify(phAdharCardData) || {})
    } else {
      formDataUpdated.append("name", adharCardData.name || "");
      formDataUpdated.append("adharCardData", JSON.stringify(adharCardData) || {})
    }
    formDataUpdated.append("phAdharCardData", JSON.stringify(phAdharCardData) || {})
    formDataUpdated.append("phPanCardData", JSON.stringify(phPanCardData) || {})

    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/patient/createpatient",
        data: formDataUpdated,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      localStorage.setItem("PatientId", res?.data?.data?._id);
      if (res?.status === 200 || res?.status === 201) {
        setLoader(false);
        toast.success("SuccessFull.");
        setTimeout(() => {
          localStorage.setItem("isCashless", formData?.insuranceType === "cashless" ? "true" : "false")
          navigate(`/case-information`);
          setFormData(initialValue);
        }, 3000);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (e?.response?.status === 500) {
        setLoader(false);
      }
    }
    // setLoader(true);
    // var raw = JSON.stringify({
    //   email: email,
    //   message: "hello everyone",
    //   subject: "hello subject",
    // });
    // try {
    //   const res = await axios({
    //     method: "post",
    //     url: baseUrl + "/sendmail",
    //     data: raw,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   if (res?.status === 200) {
    // setLoader(false);
    // // toast.success("Email Send Successfully.");
    // setTimeout(() => {
    //   localStorage.setItem("isCashless", formData?.insuranceType === "cashless" ? "true" : "false")
    //   navigate(`/case-information`);
    //   setFormData(initialValue);
    // }, 3000);
    //   }
    // } catch (e) {
    //   toast.error(e?.response?.data?.message);
    //   if (e?.response?.status === 500) {
    //     setLoader(false);
    //   }
    // }
  };

  const [aEdit1, setAEdit1] = useState(false);
  const [aEdit2, setAEdit2] = useState(false);
  const [aEdit3, setAEdit3] = useState(false);
  const [aEdit4, setAEdit4] = useState(false);
  const [aEdit5, setAEdit5] = useState(false);

  function handleAEdit1() {
    setAEdit1(!aEdit1);
    setAEdit2(false);
    setAEdit3(false);
    setAEdit4(false);
    setAEdit5(false);
  }
  function handleAEdit2() {
    setAEdit1(false);
    setAEdit2(!aEdit2);
    setAEdit3(false);
    setAEdit4(false);
    setAEdit5(false);
  }
  function handleAEdit3() {
    setAEdit1(false);
    setAEdit2(false);
    setAEdit3(!aEdit3);
    setAEdit4(false);
    setAEdit5(false);
  }
  function handleAEdit4() {
    setAEdit1(false);
    setAEdit2(false);
    setAEdit3(false);
    setAEdit4(!aEdit4);
    setAEdit5(false);
  }
  function handleAEdit5() {
    setAEdit1(false);
    setAEdit2(false);
    setAEdit3(false);
    setAEdit4(false);
    setAEdit5(!aEdit5);
  }
  const [bEdit1, setBEdit1] = useState(false);
  const [bEdit2, setBEdit2] = useState(false);
  const [bEdit3, setBEdit3] = useState(false);
  const [bEdit4, setBEdit4] = useState(false);
  const [bEdit5, setBEdit5] = useState(false);

  function handleBEdit1() {
    setBEdit1(!bEdit1);
    setBEdit2(false);
    setBEdit3(false);
    setBEdit4(false);
    setBEdit5(false);
  }
  function handleBEdit2() {
    setBEdit1(false);
    setBEdit2(!bEdit2);
    setBEdit3(false);
    setBEdit4(false);
    setBEdit5(false);
  }
  function handleBEdit3() {
    setBEdit1(false);
    setBEdit2(false);
    setBEdit3(!bEdit3);
    setBEdit4(false);
    setBEdit5(false);
  }
  function handleBEdit4() {
    setBEdit1(false);
    setBEdit2(false);
    setBEdit3(false);
    setBEdit4(!bEdit4);
    setBEdit5(false);
  }
  function handleBEdit5() {
    setBEdit1(false);
    setBEdit2(false);
    setBEdit3(false);
    setBEdit4(false);
    setBEdit5(!bEdit5);
  }

  const [pEdit1, setPEdit1] = useState(false);
  const [pEdit2, setPEdit2] = useState(false);
  const [pEdit3, setPEdit3] = useState(false);

  function handlePEdit1() {
    setPEdit1(!pEdit1);
    setPEdit2(false);
    setPEdit3(false);
  }
  function handlePEdit2() {
    setPEdit1(false);
    setPEdit2(!pEdit2);
    setPEdit3(false);
  }
  function handlePEdit3() {
    setPEdit1(false);
    setPEdit2(false);
    setPEdit3(!pEdit3);
  }

  return (
    <>
      <AlertContainer />
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <NavBar />

          <div className="form-bg">
            <div>
              <div className="cards">
                <div className="carding">
                  <p className="step-number">1</p>
                  <h3 className="step-text">Step</h3>
                  <div>
                    <h4 className="active-case">Personal Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <p className="step-number">2</p>
                  <h3 className="step-text">Step</h3>
                  <div>
                    <h4 className="inactive-case">Case Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <p className="step-number">3</p>
                  <h3 className="step-text">Step</h3>
                  <div>
                    <h4 className="inactive-case">Credit Document</h4>
                  </div>
                </div>
                <div className="carding">
                  <p className="step-number">4</p>
                  <h3 className="step-text">Step</h3>
                  <div>
                    <h4 className="inactive-case">Financial Information</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray card rounded bg-white shadow-sm my-2">
              <div style={{ padding: "30px" }}>
                <h3 className="admission-title p-0 m-0">Policy Holder's Name: <span style={{ color: '#007bff' }}>{localStorage.getItem("name")}</span></h3>
              </div>
            </div>

            <div className="bg-gray card rounded bg-white shadow-sm my-2">
              <div style={{ padding: "30px" }}>
                <form className="row">
                  <div className="col-md-5 mb-3 text-start">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Type of Insurance ?*
                    </label>
                    <div className="flex" style={{ gap: "46px" }}>
                      <div className="hos-doc2">
                        <div className="form-check w-fit p-0">
                          <label
                            className="checkbox-container m-0"
                            htmlFor="insuranceC"
                          >
                            <input
                              className="custom-checkbox"
                              onChange={handleChange}
                              value="cash"
                              type="radio"
                              name="insuranceType"
                              id="insuranceC"
                            />
                            <span className="checkmark"></span>
                            Cash
                          </label>
                        </div>
                      </div>
                      <div className="hos-doc2">
                        <div className="form-check w-fit p-0">
                          <label
                            className="checkbox-container m-0"
                            htmlFor="insurance1"
                          >
                            <input
                              className="custom-checkbox"
                              onChange={handleChange}
                              value="cashless"
                              type="radio"
                              name="insuranceType"
                              id="insurance1"
                            />
                            <span className="checkmark"></span>
                            Cashless
                          </label>
                        </div>
                      </div>
                      <div className="hos-doc2">
                        <div className="form-check w-fit p-0">
                          <label
                            className="checkbox-container m-0"
                            htmlFor="insurance2"
                          >
                            <input
                              className="custom-checkbox"
                              onChange={handleChange}
                              value="reimbursement"
                              type="radio"
                              name="insuranceType"
                              id="insurance2"
                            />
                            <span className="checkmark"></span>
                            Reimbursement
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 mb-3 text-start">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Type of Patient ?*
                    </label>
                    <div className="flex" style={{ gap: "46px" }}>
                      <div className="hos-doc2">
                        <div className="form-check w-fit p-0">
                          <label
                            className="checkbox-container m-0"
                            htmlFor="patient1"
                          >
                            <input
                              className="custom-checkbox"
                              onChange={handleChange}
                              value="individual"
                              type="radio"
                              name="patientType"
                              id="patient1"
                            />
                            <span className="checkmark"></span>
                            Individual
                          </label>
                        </div>
                      </div>
                      <div className="hos-doc2">
                        <div className="form-check w-fit p-0">
                          <label
                            className="checkbox-container m-0"
                            htmlFor="patient2"
                          >
                            <input
                              className="custom-checkbox"
                              onChange={handleChange}
                              value="corporate"
                              type="radio"
                              name="patientType"
                              id="patient2"
                            />
                            <span className="checkmark"></span>
                            Corporate
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="bg-gray card rounded bg-white shadow-sm my-2">
              <div style={{ padding: "30px" }}>
                <h3 className="admission-title pb-3">Policy Holder</h3>
                <div className="flex flex-wrap card-gap">
                  <div className="aadhar-card">
                    <div className="flex justify-between items-center">
                      <h6 className="aadhar-card-tit0le">Aadhar Card*</h6>
                      {/* <img src={show} alt="" /> */}
                    </div>
                    <div
                      className="flex justify-between items-center"
                      style={{ gap: "20px", paddingTop: "14px" }}
                    >
                      <div className="dar " style={{ width: "50%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image1" className="form-control" >
                          {formData.image1 === null
                            ? "Front"
                            : formData.image1.name.substring(0, 15)}
                          <input
                            type="file"
                            accept="image/* ,application/pdf"
                            ref={imageRef1}
                            onChange={(e) =>
                              handlePDF(e, "image1", "aadharCardFront")
                            }
                            id="image1"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                      </div>
                      <div className="dar" style={{ width: "50%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image2" className="form-control">
                          {formData.image2 === null
                            ? "Back"
                            : formData.image2.name.substring(0, 20)}
                          <input
                            type="file"
                            accept="image/* ,application/pdf"
                            ref={imageRef2}
                            onChange={(e) =>
                              handlePDF(e, "image2", "aadharCardBack")
                            }
                            id="image2"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="aadhar-detail-card">
                      {phAdharCardData?.name !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">name</p>
                        {aEdit1 ? (
                          <input
                            type="text"
                            accept="image/* ,application/pdf"
                            className="card-input"
                            name="name"
                            value={phAdharCardData?.name}
                            onChange={(e) => {
                              setPhAdharCardData({
                                ...phAdharCardData,
                                name: e.target.value,
                              });
                            }}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleAEdit1}>
                            {phAdharCardData?.name}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleAEdit1} alt="" />
                      </div>}

                      {phAdharCardData?.dob !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">DOB</p>
                        {aEdit2 ? (
                          <input
                            type="date"
                            className="card-input"
                            name="dob"
                            value={phAdharCardData?.dob}
                            onChange={(e) =>
                              setPhAdharCardData({
                                ...phAdharCardData,
                                dob: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleAEdit2}>
                            {phAdharCardData?.dob}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleAEdit2} alt="" />
                      </div>}

                      {phAdharCardData?.gender !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Gender</p>
                        {aEdit3 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="gender"
                            value={phAdharCardData?.gender}
                            onChange={(e) =>
                              setPhAdharCardData({
                                ...phAdharCardData,
                                gender: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleAEdit3}>
                            {phAdharCardData?.gender}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleAEdit3} alt="" />
                      </div>}

                      {phAdharCardData?.aadharNo !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Aadhar Number</p>
                        {aEdit4 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="aadharNo"
                            maxLength={14}
                            value={phAdharCardData?.aadharNo}
                            onChange={(e) =>
                              setPhAdharCardData({
                                ...phAdharCardData,
                                aadharNo: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleAEdit4}>
                            {phAdharCardData?.aadharNo}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleAEdit4} alt="" />
                      </div>}

                      {phAdharCardData?.address !== "" && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Address</p>
                        {aEdit5 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="aAddress"
                            value={phAdharCardData?.address}
                            onChange={(e) =>
                              setPhAdharCardData({
                                ...phAdharCardData,
                                address: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleAEdit5}>
                            {phAdharCardData?.address}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleAEdit5} alt="" />
                      </div>}

                    </div>

                  </div>

                  <div className="aadhar-card">
                    <div className="flex justify-between items-center">
                      <h6 className="aadhar-card-title">PAN Card* </h6>
                    </div>
                    {/* <div className="image-preview-container">
                        {showPreview && showPreview.image3 && (
                          <img src={showPreview.image3} height="150px" alt="Preview" className="image-preview" />
                        )}
                      </div> */}
                    <div
                      className="flex justify-between items-center"
                      style={{ gap: "20px", paddingTop: "14px" }}
                    >
                      <div className="dar " style={{ width: "50%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image3" className="form-control">
                          {formData.image3 === null
                            ? "Front"
                            : formData.image3.name.substring(0, 20)}
                          <input
                            type="file"
                            accept="image/* ,application/pdf"
                            ref={imageRef3}
                            onChange={(e) => handlePDF(e, "image3", "panCard")}
                            id="image3"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="aadhar-detail-card">

                      {((phPanCardData?.name !== "")) && <div className="flex justify-between items-center">
                          <p className="aadhar-detail-text">name</p>
                          {pEdit1 ? (
                            <input
                              type="text"
                              className="card-input"
                              name="pName"
                              value={phPanCardData?.name}
                              onChange={(e) =>
                                setPhPanCardData({
                                  ...phPanCardData,
                                  name: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <p className="aadhar-detail-text" onClick={handlePEdit1}>
                              {phPanCardData?.name}
                            </p>
                          )}
                          <img src={EditIcon} onClick={handlePEdit1} alt="" />
                        </div>}

                      {(phPanCardData?.dob !== "") && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">DOB</p>
                        {pEdit2 ? (
                          <input
                            type="date"
                            className="card-input"
                            name="pDob"
                            value={phPanCardData?.dob}
                            onChange={(e) =>
                              setPhPanCardData({
                                ...phPanCardData,
                                dob: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handlePEdit2}>
                            {phPanCardData?.dob}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handlePEdit2} alt="" />
                      </div>}

                      {phPanCardData?.panNo !== "" && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Pan Number</p>
                        {pEdit3 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="pNumber"
                            maxLength={10}
                            value={phPanCardData?.panNo}
                            onChange={(e) =>
                              setPhPanCardData({
                                ...phPanCardData,
                                panNo: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handlePEdit3}>
                            {phPanCardData?.panNo}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handlePEdit3} alt="" />
                      </div>}

                    </div>

                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4 mb-3 text-start">
                    <label htmlFor="certificate_number" className="form-label">
                      Email *
                    </label>
                    <div className="dar">
                      <input
                        onChange={handleChange}
                        value={formData.email}
                        type="text"
                        className="form-control"
                        name="email"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-3 text-start">
                    <label htmlFor="certificate_number" className="form-label">
                      Mobile Number *
                    </label>
                    <div className="dar">
                      <input
                        onChange={handleChange}
                        value={formData.number}
                        type="text"
                        pattern="[7-9]{1}[0-9]{9}"
                        className="form-control"
                        maxLength={10}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="number"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>
                <h3 className="admission-title m-0">Patient</h3>
                <div className="hos-doc2">
                  <div className="form-check w-fit p-0">
                    <label className="checkbox-container m-3" htmlFor="patient">
                      <input
                        className="custom-checkbox"
                        onChange={(e) => setPolicyCheck(e.target.checked)}
                        type="checkbox"
                        id="patient"
                      />
                      <span className="checkmark"></span>
                      Same as policy holder
                    </label>
                  </div>
                </div>
                {policyCheck ? (
                  ""
                ) : (
                  <>
                    <div className="row mt-4 m-0">
                      {/* <div className="col-md-4 mb-3 text-start">
                        <label
                          htmlFor="certificate_number"
                          className="form-label"
                        >
                          Name*
                        </label>
                        <div className="dar">
                          <input
                            onChange={handleChange}
                            value={formData.name}
                            type="text"
                            className="form-control"
                            name="name"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="flex flex-wrap card-gap">
                      <div className="aadhar-card">
                        <div className="flex justify-between items-center">
                          <h6 className="aadhar-card-title">Aadhar Card*</h6>
                          {/* <img src={show} alt="" /> */}
                        </div>
                        <div
                          className="flex justify-between items-center"
                          style={{ gap: "20px", paddingTop: "14px" }}
                        >
                          <div className="dar " style={{ width: "50%" }}>
                            <img src={enclouse} alt="" />
                            <label htmlFor="image4" className="form-control">
                              {formData.image4 === null
                                ? "Front"
                                : formData.image4.name.substring(0, 20)}
                              <input
                                type="file"
                                accept="image/* ,application/pdf"
                                ref={imageRef4}
                                onChange={(e) =>
                                  handlePDF(e, "image4", "aadharCardFront")
                                }
                                id="image4"
                                hidden
                                aria-describedby="emailHelp"
                              />
                            </label>
                          </div>
                          <div className="dar" style={{ width: "50%" }}>
                            <img src={enclouse} alt="" />
                            <label htmlFor="image5" className="form-control">
                              {formData.image5 === null
                                ? "Back"
                                : formData.image5.name.substring(0, 20)}
                              <input
                                type="file"
                                accept="image/* ,application/pdf"
                                ref={imageRef5}
                                onChange={(e) =>
                                  handlePDF(e, "image5", "aadharCardBack")
                                }
                                id="image5"
                                hidden
                                aria-describedby="emailHelp"
                              />
                            </label>
                          </div>
                        </div>
                        <div className="aadhar-detail-card">

                          {adharCardData?.name !== '' && <div className="flex justify-between items-center">
                            <p className="aadhar-detail-text">name</p>
                            {bEdit1 ? (
                              <input
                                type="text"
                                className="card-input"
                                name="bName"
                                value={adharCardData?.name}
                                onChange={(e) => {
                                  setAdharCardData({
                                    ...adharCardData,
                                    name: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <p className="aadhar-detail-text" onClick={handleBEdit1}>
                                {adharCardData?.name}
                              </p>
                            )}
                            <img src={EditIcon} onClick={handleBEdit1} alt="" />
                          </div>}

                          {adharCardData?.dob !== '' && <div className="flex justify-between items-center">
                            <p className="aadhar-detail-text">DOB</p>
                            {bEdit2 ? (
                              <input
                                type="date"
                                className="card-input"
                                name="bDOB"
                                value={adharCardData?.dob}
                                onChange={(e) => {
                                  setAdharCardData({
                                    ...adharCardData,
                                    dob: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <p className="aadhar-detail-text" onClick={handleBEdit2}>
                                {adharCardData?.dob}
                              </p>
                            )}
                            <img src={EditIcon} onClick={handleBEdit2} alt="" />
                          </div>}

                          {adharCardData?.gender !== '' && <div className="flex justify-between items-center">
                            <p className="aadhar-detail-text">Gender</p>
                            {bEdit3 ? (
                              <input
                                type="text"
                                className="card-input"
                                name="bGender"
                                value={adharCardData?.gender}
                                onChange={(e) => {
                                  setAdharCardData({
                                    ...adharCardData,
                                    gender: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <p className="aadhar-detail-text" onClick={handleBEdit3}>
                                {adharCardData?.gender}
                              </p>
                            )}
                            <img src={EditIcon} onClick={handleBEdit3} alt="" />
                          </div>}

                          {adharCardData?.aadharNo !== '' && <div className="flex justify-between items-center">
                            <p className="aadhar-detail-text">Aadhar Number</p>
                            {bEdit4 ? (
                              <input
                                type="text"
                                className="card-input"
                                name="bNumber"
                                maxLength={14}
                                value={adharCardData?.aadharNo}
                                onChange={(e) => {
                                  setAdharCardData({
                                    ...adharCardData,
                                    aadharNo: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <p className="aadhar-detail-text" onClick={handleBEdit4}>
                                {adharCardData?.aadharNo}
                              </p>
                            )}
                            <img src={EditIcon} onClick={handleBEdit4} alt="" />
                          </div>}

                          {adharCardData?.address !== '' && <div className="flex justify-between items-center">
                            <p className="aadhar-detail-text">Address</p>
                            {bEdit5 ? (
                              <input
                                type="text"
                                className="card-input"
                                name="bAddress"
                                value={adharCardData?.address}
                                onChange={(e) => {
                                  setAdharCardData({
                                    ...adharCardData,
                                    address: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <p className="aadhar-detail-text" onClick={handleBEdit5}>
                                {adharCardData?.address}
                              </p>
                            )}
                            <img src={EditIcon} onClick={handleBEdit5} alt="" />
                          </div>}

                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="row save m-lg-1 ">
                  <button onClick={() => handleSubmit()}>Save and Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default NewAdmission;

