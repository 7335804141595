import React, { useContext, useEffect } from "react";
import SideBar from "../../component/SideBar";
import Form from "../../component/Form";
import leftArrow from "../../images/left-arrow.svg";
import SidebarContext from "../../context/sidebar/SidebarContext";
import {  useNavigate } from "react-router-dom";

function CompanyPage() {
  const isLoggedin = localStorage.getItem("hospitalLogin");
  const side = useContext(SidebarContext);
  const navigate = useNavigate(); 
  const { open, toggleOpen } = side;

  useEffect(() => {
    if (!isLoggedin || isLoggedin !== "true") {
      navigate("/login");
    }
  }, [isLoggedin, navigate]);
  return (
    <>
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <button className="custom-toggler" onClick={toggleOpen}>
                <span className="custom-icon"></span>
                <span className="custom-icon"></span>
                <span className="custom-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="item-first">
                    <a className="nav-link" href="/">
                      New Admission
                    </a>
                  </li>
                  <img src={leftArrow} alt="" />
                  <li className="active-item">
                    <a className="nav-link" href="/">
                      Company Details
                    </a>
                  </li>
                </ul>
              </div>

              <div className="d-flex align-items-center">
                <a className="text-reset me-3" href="#">
                  <i className="fas fa-shopping-cart"></i>
                </a>

                <div className="dropdown">
                  <a
                    className="text-reset me-3 dropdown-toggle hidden-arrow"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-bell"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    </svg>
                    <span className="badge rounded-pill badge-notification bg-danger">
                      1
                    </span>
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Some news
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another news
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="dropdown">
                  <a
                    className="dropdown-toggle d-flex align-items-center hidden-arrow"
                    href="#"
                    id="navbarDropdownMenuAvatar"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                      className="rounded-circle"
                      height="25"
                      alt="Black and White Portrait of a Man"
                      loading="lazy"
                    />
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdownMenuAvatar"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        My profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Settings
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <Form />
        </div>
      </div>
    </>
  );
}

export default CompanyPage;
