import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../images/side-logo.svg";
import SidebarContext from "../context/sidebar/SidebarContext";
import { Modal } from 'antd';
import { useNavigate } from "react-router-dom/dist";

function SideBar({ onSave }) {
  const side = useContext(SidebarContext);
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    if (name.trim()) {
      localStorage.setItem('name', name)
      navigate('/new-admission')
      e.preventDefault();
      setVisible((prev) => !prev)
    }
  };

  const handleMenuClick = (path) => {
    navigate(`${path}`)
  }



  const { open, toggleOpen } = side;
  //clear local storage
  const clearLocalStorage = () => {
    window.location.href = "/login";
    localStorage.clear();
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <div className="sidebar-mobile" style={{ left: open ? "0" : "-300px" }}>
        <a href="/">
          <img src={Logo} alt="" className="" />
        </a>
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 m">
          <ul
            className="nav nav-pills flex-column mb-sm-auto mb-5 align-items-center align-items-sm-start"
            id="menu"
          >
            <li className="nav-item mt-5 " onClick={toggleOpen}>
              <a
                onClick={clearLocalStorage}
                className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="ms-1 d-inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    <path d="M4.5 12.5A.5.5 0 0 1 5 12h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm0-2A.5.5 0 0 1 5 10h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm1.639-3.708 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V8.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V8s1.54-1.274 1.639-1.208zM6.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z" />
                  </svg>{" "}
                  Dashboard
                </span>
              </a>
            </li>
            <li onClick={toggleOpen}>
              <NavLink
                to="/new-admission"
                className="d-flex align-items-center mt-3 text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fs-4 bi-speedometer2"></i>
                <span className="ms-1 d-inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    <path d="M4.5 12.5A.5.5 0 0 1 5 12h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm0-2A.5.5 0 0 1 5 10h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm1.639-3.708 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V8.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V8s1.54-1.274 1.639-1.208zM6.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z" />
                  </svg>{" "}
                  New Admission
                </span>
              </NavLink>
            </li>
            <li onClick={toggleOpen}>
              <NavLink
                to="/login"
                className="d-flex align-items-center mt-3 text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fs-4 bi-people"></i>{" "}
                <span className="ms-1 d-inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    <path d="M4.5 12.5A.5.5 0 0 1 5 12h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm0-2A.5.5 0 0 1 5 10h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm1.639-3.708 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V8.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V8s1.54-1.274 1.639-1.208zM6.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z" />
                  </svg>{" "}
                  Login
                </span>
              </NavLink>
            </li>
            <li onClick={toggleOpen}>
              <NavLink
                to="/personal-information/1"
                className="d-flex align-items-center mt-3 text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fs-4 bi-people"></i>{" "}
                <span className="ms-1 d-inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    <path d="M4.5 12.5A.5.5 0 0 1 5 12h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm0-2A.5.5 0 0 1 5 10h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm1.639-3.708 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V8.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V8s1.54-1.274 1.639-1.208zM6.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z" />
                  </svg>{" "}
                  Discharge
                </span>
              </NavLink>
            </li>
          </ul>
          <hr />
        </div>
        <a href="/" style={{ position: "fixed", bottom: "20px", left: "10px", zIndex: 100 }}>
          <img src={localStorage.getItem('hospitalLogo')} alt="" className="" />
        </a>
      </div>
      {/* ----------Mobile Sidebar End------------------ */}
      {/* ------------------------------------------------------------------------------------ */}
      {/* ----------Desktop Siderbar Start------------------ */}

      <div className="sidebar-desktop">
        <a href="/">
          <img src={Logo} alt="" className="" />
        </a>
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 m">
          <ul
            className="nav nav-pills flex-column mb-sm-auto mb-5 align-items-center align-items-sm-start"
            id="menu"
          >
            <li className="nav-item mt-5 ">
              <NavLink
                to="/"
                className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="ms-1 d-inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-shop-window"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5m2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5" />
                  </svg>{" "}
                  Dashboard
                </span>
              </NavLink>
            </li>
            <li onClick={showModal} style={{ cursor: 'pointer' }}>

              <i className="fs-4 bi-speedometer2"></i>
              <span className="ms-1 d-inline">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-file-earmark-richtext"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                </svg>{" "}
                New Admission
              </span>

            </li>

            <li>
              <a className="ant-dropdown-link" onClick={() => navigate('/admissionList')} style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
                <i className="fs-4 bi-speedometer2"></i>
                <span className="ms-1 d-inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                  </svg>{" "}
                  Admission List
                </span>
              </a>
            </li>
            <li>

              <a className="ant-dropdown-link" onClick={() => navigate('/dischargeList')} style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
                <i className="fs-4 bi-people"></i>{" "}
                <span className="ms-1 d-inline" >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path fillRule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>{" "}
                  Discharge List
                </span>
              </a>
            </li>

            <li>
              <a
                onClick={clearLocalStorage}
                className="d-flex align-items-center mt-3 text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fs-4 bi-people"></i>{" "}
                <span className="ms-1 d-inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
                    <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                  </svg>{" "}
                  Log out
                </span>
              </a>
            </li>
          </ul>
          <hr />
        </div>
        <a href="/" style={{ position: "fixed", bottom: "20px", left: "10px", zIndex: 100 }}>
          <img src={localStorage.getItem('hospitalLogo')} alt="" className="" style={{ maxHeight: '40px' }} />
        </a>
      </div>
      <Modal
        open={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <form onSubmit={handleSubmit} >
          <h3 style={{ marginTop: '1em' }}>Please Enter Policy Holder's Name</h3>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            autoFocus
            style={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              margin: '10px 0',
              padding: '10px',
              width: '100%'
            }}
          /> <br />
          <button type="submit" style={{
            backgroundColor: '#007bff',
            border: 'none',
            borderRadius: '5px',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '16px',
            margin: '5px 0 0',
            padding: '10px 20px',
            width: '100%'
          }}>Save</button>
        </form>

      </Modal>
    </>
  );
}

export default SideBar;
