import React, { useContext, useState, useEffect } from "react";
import SidebarContext from "../context/sidebar/SidebarContext";
import TogglerButton from './TogglerButton';
import Notifications from './Notifications';
import useSocketIo from "../hooks/useSocketIo";
import { useSelector } from "react-redux";


function NavBar() {
  const { currentUser } = useSelector((state) => ({
    currentUser : state.global.currentUser,
  }));

  const { toggleOpen } = useContext(SidebarContext);
  const [notificationsLength, setNotificationsLength] = useState(0);
  const { socket } = useSocketIo();
  const id = localStorage.getItem('hospitalId');
  const hospitalName = localStorage.getItem('hospitalName');

  useEffect(() => {
    let timer;
    socket?.on('connect', () => {
      socket.emit('setUserId', id);
      socket.emit('getNotificationsLength', id);
      socket?.on('notificationsLength', (data) => {
        setNotificationsLength(data);
      });
      timer = setTimeout(() => {
        socket.emit('getNotificationsLength', id);
      }, 10000); // run every 10 seconds
      socket?.on('disconnect', () => {});
    });

    return () => {
      socket?.off('connect');
      socket?.off('disconnect');
      socket?.off('notifications');
      clearTimeout(timer);
    };
  }, [id, socket]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <TogglerButton onClick={toggleOpen} />

          <h3>{hospitalName}</h3>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="item-first">
                <a className="nav-link" href="#">New Admission</a>
              </li>
              <img src={leftArrow} alt="Left Arrow" />
              <li className="active-item">
                <a className="nav-link" href="#">Company Details</a>
              </li> */}
            </ul>
          </div>
          
          <div className="d-flex align-items-center">
            <Notifications notificationsCount={notificationsLength} />
            {/* <UserDropdown /> */}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
