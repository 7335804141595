import React from 'react';
import enclouse from '../../images/enclouse.png'
import elipi from '../../images/elipi.png';
import elipi1 from '../../images/elipi1.png';
import SideBar from '../SideBar';
import NavBar from '../NavBar';
import { useNavigate } from 'react-router-dom';

const Pi3 = ({ value, setValue, handleChange }) => {
  const navigate=useNavigate();
  
  return (
    <>
      <div className='row'>
        <div className='col-md-2'>
          <SideBar />
        </div>
        <div className='col-md-10 bg-gray'>
          <NavBar />

          <div>
            <div className='darg my-2 py-3'>
              <div className="cards">
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Personal Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Case Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Credit Document</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Financial Information</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className='form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3'>
              <h3 className='text-dark py-2 mb-4 m-lg-3 justify-content-start d-flex'>Lending Module</h3>
              <div className="container text-center">
                <form className="row">
                  <div className="col-md-7 mb-3 text-start">

                  </div>
                  <div className='row1 '>
                    <div className="submit">
                      <button onClick={() => {
                        navigate('/personal-information/4');
                      }}>Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pi3;
