import React from 'react'
import SideBar from '../SideBar';
import NavBar from '../NavBar';
import elipi from '../../images/elipi.png'
import enclouse from '../../images/enclouse.png'
import { useNavigate } from 'react-router-dom';

const Pi1 = ({ value, setValue, handleChange }) => {
  const navigate=useNavigate();

  return (
    <>
      <div className='row'>
        <div className='col-md-2'>
          <SideBar />
        </div>
        <div className='col-md-10 bg-gray'>
          <NavBar />

          <div>
            <div className='darg my-2 py-3'>
              <div className="cards">
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Personal Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Case Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Credit Document</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Financial Information</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className='darg my-2 py-3'>
              <div className="cards pi-cards">
                <div>
                  <h6 className='text-dark py-2 mb-2 justify-content-start d-flex'>Type of Insurance ?</h6>
                  <div className="d-flex align-items-center">
                    <div className="form-check w-fit hos-doc">
                      <input className="form-check-input" type="checkbox" id="cashless" name="cashless" />
                      <label className="form-check-label" htmlFor="cashless">
                        Cashless
                      </label>
                    </div>
                    <div className="form-check w-fit hos-doc">
                      <input className="form-check-input" type="checkbox" name="reim" id="reim" />
                      <label className="form-check-label" htmlFor="reim">
                        Reimbursement
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <h6 className='text-dark py-2 mb-2 justify-content-start d-flex'>Type of Patient ?</h6>
                  <div className="d-flex align-items-center">
                    <div className="form-check w-fit hos-doc">
                      <input className="form-check-input" type="checkbox" name="individual" id="individual" />
                      <label className="form-check-label" htmlFor="individual">
                        Individual
                      </label>
                    </div>
                    <div className="form-check w-fit hos-doc">
                      <input className="form-check-input" type="checkbox" name="corporate" id="corporate" />
                      <label className="form-check-label" htmlFor="corporate">
                        Corporate
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3'>
              <h3 className='text-dark py-2 mb-4 ms-3 justify-content-start d-flex'>Policy Holder</h3>

              <div className="container text-center">
                <div className="flex">
                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Aadhar Card*</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar' onClick={() => {
                          document.getElementById('phAdharCardFrontImg').click();
                        }}>
                          <p>Front</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="phAdharCardFrontImg" name="phAdharCardFrontImg" onChange={handleChange} />
                        </div>
                        <div className='dar' onClick={() => {
                          document.getElementById('phAdharCardBackImg').click();
                        }}>
                          <p>Back</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="phAdharCardBackImg" name='phAdharCardBackImg' onChange={handleChange} />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Gender</p>
                          <p>Aadhar Number</p>
                          <p>Address</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                            <p>{value.phAadharName}</p>
                            <input type="text" value={value.phAadharName} handleChange={handleChange} name="phAadharName" />
                          </div>
                          <div className='pi-box132-div'>
                            <p>{value.phAadharDob}</p>
                            <input type="text" value={value.phAadharDob} handleChange={handleChange} name="phAadharDob" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phAadharGender}</p>
                            <input type="text" value={value.phAadharGender} handleChange={handleChange} name="phAadharGender" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phAadharNumber}</p>
                            <input type="text" value={value.phAadharNumber} handleChange={handleChange} name="phAadharNumber" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phAadharAddress}</p>
                            <input type="text" value={value.phAadharAddress} handleChange={handleChange} name="phAadharAddress" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[0].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[1].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[2].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[3].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[4].classList.toggle('pi-box132-active');
                          }} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>PAN Card*</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>
                      <div className="pi-box12 flex">
                        <div className='dar' onClick={() => {
                          document.getElementById('phPanCardFrontImg').click();
                        }}>
                          <p>Front</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="phPanCardFrontImg" name="phPanCardFrontImg" onChange={handleChange} />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Pan Number</p>
                        </div>

                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                            <p>{value.phPanName}</p>
                            <input type="text" value={value.phPanName} handleChange={handleChange} name="phPanName" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phPanDob}</p>
                            <input type="text" value={value.phPanDob} handleChange={handleChange} name="phPanDob" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phPanNumber}</p>
                            <input type="text" value={value.phPanNumber} handleChange={handleChange} name="phPanNumber" />
                          </div>
                        </div>

                        <div className="pi-box133">
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[5].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[6].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[7].classList.toggle('pi-box132-active');
                          }} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-4 mb-3 text-start">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email*</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                  <div className="col-md-4 mb-3 text-start">
                    <label htmlFor="exampleInputEmail1" className="form-label">Mobile Number*</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                </div>

                <div className="mt-4">
                  <h3 className='text-dark justify-content-start d-flex'>Patient</h3>
                  <div className="form-check w-fit hos-doc">
                    <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Same as policy holder
                    </label>
                  </div>
                  <div className="col-md-4 mb-3 mt-4 text-start">
                    <label htmlFor="exampleInputEmail1" className="form-label">Name*</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                </div>

                <div className="flex">
                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Aadhar Card*</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar' onClick={() => {
                          document.getElementById('adharCardFrontImg').click();
                        }}>
                          <p>Front</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="adharCardFrontImg" name="adharCardFrontImg" onChange={handleChange} />
                        </div>
                        <div className='dar' onClick={() => {
                          document.getElementById('adharCardFrontImg').click();
                        }}>
                          <p>Back</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="adharCardFrontImg" name='adharCardFrontImg' onChange={handleChange} />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Gender</p>
                          <p>Aadhar Number</p>
                          <p>Address</p>
                        </div>

                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                            <p>{value.phAadharName1}</p>
                            <input type="text" value={value.phAadharName1} handleChange={handleChange} name="phAadharName1" />
                          </div>
                          <div className='pi-box132-div'>
                            <p>{value.phAadharDob1}</p>
                            <input type="text" value={value.phAadharDob1} handleChange={handleChange} name="phAadharDob1" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phAadharGender1}</p>
                            <input type="text" value={value.phAadharGender1} handleChange={handleChange} name="phAadharGender1" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phAadharNumber1}</p>
                            <input type="text" value={value.phAadharNumber1} handleChange={handleChange} name="phAadharNumber1" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phAadharAddress1}</p>
                            <input type="text" value={value.phAadharAddress1} handleChange={handleChange} name="phAadharAddress1" />
                          </div>
                        </div>

                        <div className="pi-box133">
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[8].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[9].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[10].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[11].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[12].classList.toggle('pi-box132-active');
                          }} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>PAN Card*</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>
                      <div className="pi-box12 flex">
                        <div className='dar' onClick={() => {
                          document.getElementById('panCardFrontImg').click();
                        }}>
                          <p>Front</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="panCardFrontImg" name="panCardFrontImg" onChange={handleChange} />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Pan Number</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                            <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="phPanName1" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phPanDob1}</p>
                            <input type="text" value={value.phPanDob1} handleChange={handleChange} name="phPanDob1" />
                          </div>

                          <div className='pi-box132-div'>
                            <p>{value.phPanNumber1}</p>
                            <input type="text" value={value.phPanNumber1} handleChange={handleChange} name="phPanNumber1" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[13].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[14].classList.toggle('pi-box132-active');
                          }} alt="" />
                          <img src="/images/edit.png" onClick={() => {
                            document.querySelectorAll('.pi-box132-div')[15].classList.toggle('pi-box132-active');
                          }} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="save m-lg-1 ">
                  <button onClick={()=>{
                    navigate('/personal-information/2');
                  }}>Save and Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pi1;
