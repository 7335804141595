import React, { useContext } from "react";
import SideBar from "../../component/SideBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../context/common/data";
import { toast } from "react-toastify";
import { AlertContainer } from "../../component/Alerts/ToastifyContainer";
import SidebarContext from "../../context/sidebar/SidebarContext";
import enclouse from "../../images/enclouse.png";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Row, notification } from "antd";
import Document from "./Document";
import Facility from "./Facility";
import Insurance from "../Insurance";
import Declaration from "../Declaration";

const Signup = () => {

  const dispatch = useDispatch();

  const { currentStep } = useSelector((state) => ({
    currentStep: state.global.currentStep,
  }));
  const [register, setRegister] = useState(false);
  const [verify, setVerify] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const side = useContext(SidebarContext);
  const { open, toggleOpen } = side;
  const navigate = useNavigate();

  const initialValue = {
    hospital_name: "",
    hospital_mobile: "",
    website_add: "",
    address1: "",
    address2: "",
    address3: "",
    name: "",
    designation: "",
    mobile: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [loader, setLoader] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({})
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      console.log("found file", e.target.files[0]);
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const passRule = [{ min: 8, max: 16, message: 'Password should be between 8-16 characters ' }, { required: true, message: '*Please input password!' }]
  const createSignup = async (e) => {
    // e.preventDefault();
    const {
      hospital_name,
      hospital_mobile,
      website_add,
      address1,
      address2,
      address3,
      name,
      mobile,
      designation,
      logo,
    } = personalInfo;
    const { email, password } = e
    // console.log(cred, "CREDENTIAL")
    let formDataUpdated = new FormData();
    formDataUpdated.append("hospitalName", hospital_name);
    formDataUpdated.append("number", hospital_mobile);
    formDataUpdated.append("website", website_add);
    formDataUpdated.append("addressLine1", address1);
    formDataUpdated.append("addressLine2", address2);
    formDataUpdated.append("addressLine3", address3);
    formDataUpdated.append("odName", name);
    formDataUpdated.append("odDesignation", designation);
    formDataUpdated.append("odNumber", mobile);
    formDataUpdated.append("odEmail", email);
    formDataUpdated.append("logo", logo);
    formDataUpdated.append("password", password);

    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/signup/basicinfo",
        data: formDataUpdated,
        headers: {
          "Content-Type": "multipart/form-data",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data?.success) {
        setLoader(false);
        toast.success(res.data?.message);

        // if(res.data.data.)
        const obj = res?.data?.data
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && !obj[key]) {
            dispatch({ type: "SET_CURRENT_STEP", payload: key });
            break;
          }
        }
        dispatch({ type: "SET_HOSPITAL_ID", payload: res?.data?.data.hospitalId })


      } else {
        notification.error({
          message: res.data.message || "Something went wrong!"
        })
      }
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.status === 500) {

        setLoader(false);
      }
    }
  };
  const submitInfo = (val) => {
    setPersonalInfo(val)
    setVerify(true)
  };

  const verifyEmailOTP = async (val) => {
    if (val.emailVOTP) {
      const checkOTP = await axios.post(
        `${baseUrl}/verifyEmail/checkOTP`,
        { email: val?.email, otp: val?.emailVOTP }
      )
      if (checkOTP.data.emailVerified) {
        createSignup({ email: val?.email, password: val?.password })
        toast.success(checkOTP.data?.message)
      } else {
        toast.error(checkOTP.data.message)
      }
    } else {
      const sendOTP = await axios.post(
        `${baseUrl}/verifyEmail/sendOTP`,
        { email: val?.email }
      )

      if (sendOTP.data.success) {
        setOtpSent(true)
        if (sendOTP.data.emailVerified) {
          setOtpSent(false)
          createSignup({ email: val?.email, password: val?.password })
          toast.success(sendOTP.data?.message)
        }
        else {
          toast.error(sendOTP.data.message)
        }
      }
    }
  }
  return (

    <>

      {currentStep == null && <div className="form-bg">
        <div style={{ marginBottom: "16px" }}>
          <div className="cards">
            <div className="carding">
              <p className="step-number">1</p>

              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Basic Information</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">2</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Documents upload</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">3</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Add Facilities</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">4</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Existing Insurance</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">5</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Declaration</h4>
              </div>
            </div>
          </div>
        </div>


        <div
          className="bg-gray card rounded bg-white shadow-sm"
          style={{ padding: "30px" }}
        > <Form
          layout="vertical"
          onFinish={submitInfo}
          initialValues={formData}
        >
            <h3 className="text-dark py-2 mb-4 justify-content-start d-flex">Hospital Details</h3>
            <Row gutter={16}>
              <Col span={12}> <Form.Item
                label="Hospital Name*"
                name="hospital_name"

                rules={[{ required: true, message: 'Please input hospital name!' }]}
              >
                <Input placeholder="Apollo" style={{ height: '40px' }} />
              </Form.Item></Col>
              <Col span={12}><Form.Item
                label="Mobile*"
                name="hospital_mobile"

                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  { required: true, message: 'Please input mobile number!' },
                  { pattern: /^[0-9]+$/, message: 'Please enter a valid mobile number!' },
                ]}
              >
                <Input maxLength={10} style={{ height: '40px' }} />
              </Form.Item></Col>

            </Row>



            <Form.Item label="Address line 1" name="address1" rules={[
              { required: true, message: 'Please input address1!' },
            ]}>
              <Input style={{ height: '40px' }} />
            </Form.Item>




            <Row gutter={14}>
              <Col span={12}>
                <Form.Item label="Address line 2" name="address2" >
                  <Input style={{ height: '40px' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Address line 3" name="address3">
                  <Input style={{ height: '40px' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>   <Form.Item label="Website Link" name="website_add" >
                <Input style={{ height: '40px' }} />
              </Form.Item></Col>
              <Col span={12}> <Form.Item label="Hospital Logo" name="logo">
                <Input type="file" style={{ width: '100%', height: '40px' }} />
              </Form.Item></Col>

            </Row>



            <h3 className="text-dark py-2 mb-4 justify-content-start d-flex">Owner Details</h3>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Name" name="name" rules={[
                  { required: true, message: 'Please input name!' }
                ]}>
                  <Input style={{ height: '40px' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Designation" name="designation" rules={[
                  { required: true, message: 'Please input designation!' }
                ]}>
                  <Input style={{ height: '40px' }} />
                </Form.Item>
              </Col>
              <Col span={8}>  <Form.Item
                label="Mobile"
                name="mobile"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  { required: true, message: 'Please input mobile number!' },
                  { pattern: /^[0-9]+$/, message: 'Please enter a valid mobile number!' },
                ]}

              >
                <Input maxLength={10} style={{ height: '40px' }} />
              </Form.Item></Col>
            </Row>



            {!verify && <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Form.Item >
                <Button type="primary" htmlType="submit">
                  Save and Next
                </Button>
              </Form.Item>
              <Form.Item>
                <div onClick={() => navigate('/login')} style={{ color: 'rgb(170, 178, 188', backgroundColor: 'transparent', border: 'none', textDecoration: 'none', cursor: 'pointer', marginLeft: '1em' }}>Already a registered user?</div>
              </Form.Item>
            </div>}
          </Form>

          {verify && <Form layout="vertical" onFinish={verifyEmailOTP}
            style={{ width: '80%', marginTop: '1.5em' }} >
            <Form.Item label="Email" name="email"
              rules={[{ required: true, message: 'Please input email address!' }]}
            >
              <Input type="email" style={{ height: '40px' }} />
            </Form.Item>

            <Form.Item label="Password" name="password"
              rules={passRule}
            >
              <Input type="password" style={{ height: '40px' }} />
            </Form.Item>

            {otpSent && <Form.Item label="Enter OTP" name='emailVOTP' >
              <Input style={{ height: '40px' }} />
            </Form.Item>

            }
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <Form.Item >
                {otpSent ? <Button type="primary" htmlType="submit">
                  Submit
                </Button> : <Button type="primary" htmlType="submit">
                  Verify
                </Button>
                }
              </Form.Item>
              <Form.Item>
                <div onClick={() => setRegister(false)} style={{ color: 'rgb(170, 178, 188', backgroundColor: 'transparent', border: 'none', textDecoration: 'none', cursor: 'pointer' }}>Already a registered user?</div>
              </Form.Item>
            </div>
          </Form>}</div>


      </div>

      }


      {currentStep === "docUploaded" && <div style={{ backgroundColor: '#e9ecf259', borderRadius: '10px' }}>

        <Document />
      </div>}


      {currentStep === "facilityAdded" && <div style={{ backgroundColor: '#e9ecf259', borderRadius: '10px' }}>

        <Facility />
      </div>}

      {currentStep === "insuranceAdded" && <div style={{ backgroundColor: '#e9ecf259', borderRadius: '10px' }}>

        <Insurance />
      </div>}

      {currentStep === "declarationStatus" && <div style={{ backgroundColor: '#e9ecf259', borderRadius: '10px' }}>
        <Declaration />
      </div>}
    </>
  );
};

export default Signup;
