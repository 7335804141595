import React from "react";

const RejectModal = () => {
  return (
    <>
      <div className="clients-pop1">
        <div className="client-container1">
          <div className="form87">
            <h2>
            If Rejected Than it will Revert to <br /> Hospital
            </h2>
            <form>
              <div className="submit_br">
                <button>Done</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectModal;
