import React from "react";

function Form() {
  return (
    <>
      <div className="form-bg">
        <div
          className="card rounded bg-white shadow-sm "
          style={{ padding: "30px" }}
        >
          <div className="text-center">
            <form className="row">
              <div className="col-md-4 mb-3 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Does the patient drink alcohol?*
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-6 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Does the patient have hypertension? *
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  How many times a week? *
                </label>
                {/* <div className='row col-md-5 ms-1'>
                                    <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Once a week
                                    </button>
                                    <div className="form-check col-md-3">
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item " href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Action two</a></li>
                                            <li><a className="dropdown-item" href="#">Action three</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                <div className="mb-3 col-md-6">
                  <select className="form-select" id="dropdownInput">
                    <option value="option1">Once a week</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    <option value="option4">Option 4</option>
                  </select>
                </div>
              </div>

              <div className="col-md-7 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  When did it occur to you first ?{" "}
                </label>
                <div className="d-flex grid gap-3 rounded ms-1">
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Month
                  </button>
                  <ul className="dropdown-menu"></ul>
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Year
                  </button>
                  <ul className="dropdown-menu"></ul>
                </div>
              </div>

              <div className="col-md-4 mb-3 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Does the patient Smoke? *{" "}
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Are you taking medicine for the same ?{" "}
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-4 mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3 text-start mt-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  How many a Day?{" "}
                </label>
                <div className="mb-3 col-md-6">
                  <select className="form-select" id="dropdownInput">
                    <option value="option1">1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    <option value="option4">Option 4</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 text-start mt-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Do You Have any pre-existing disease? *
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-4 mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Does the patient have diabetes? *
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-4 mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-7 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Mention the name of the disease *
                </label>
                <div className="mb-3 col-md-4 mb-3">
                  <select className="form-select" id="dropdownInput">
                    <option value="option1">cancer</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    <option value="option4">Option 4</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 mb-3 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  When did it occur to you first ?{" "}
                </label>
                <div className="d-flex grid gap-3 rounded ms-1">
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Month
                  </button>
                  <ul className="dropdown-menu "></ul>
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Year
                  </button>
                  <ul className="dropdown-menu"></ul>
                </div>
              </div>
              <div className="col-md-7 text-start mt-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  When did it occur to you first ?{" "}
                </label>
                <div className="d-flex grid gap-3 rounded ms-1">
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Month
                  </button>
                  <ul className="dropdown-menu"></ul>
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Year
                  </button>
                  <ul className="dropdown-menu"></ul>
                </div>
              </div>
              <div className="col-md-4 text-start mt-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Are you taking medicine for the same ?{" "}
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-7 text-start mt-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Are you taking medicine for the same ?{" "}
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-6 text-start mt-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Have you declared these details with the insurer at the time
                  of purchasing the policy? *
                </label>
                <div className="row ms-1">
                  <div className="form-check col-md-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-check  col-md-12 mt-5">
                <div className="submit">
                  <button>Save and Next</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
