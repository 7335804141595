import React from "react";
import SideBar from "../component/SideBar";
import NavBar from "../component/NavBar";
// import chart from "../images/chart.png";
import elio from "../images/Ellipse 2.png";
import hj from "../images/hj.png";
// import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  // const navigate = useNavigate();

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <SideBar />
        </div>
        <div className="col-md-10 bg-gray">
          <NavBar />
          <div className="">
            <div className="row">
              <div className="col-md-7">
                <div className="chart">
                  <img src={hj} alt="" />
                </div>
              </div>
              <div className="col-md-5">
                <div className="chart_card">
                  <div className="chart_all">
                    <h3>Pending Application</h3>
                    <p>See all</p>
                  </div>

                  <div className="card_admi">
                    <div className="box_admin">
                      <div className="bix_img">
                        <img src={elio} alt="" />
                      </div>
                      <div className="bix_head">
                        <h4>Chetan Goyal</h4>
                        <p>Male,45 Today 14:30 PM </p>
                      </div>
                    </div>
                    <div className="box_admin">
                      <div className="bix_img">
                        <img src={elio} alt="" />
                      </div>
                      <div className="bix_head">
                        <h4>Chetan Goyal</h4>
                        <p>Male,45 Today 14:30 PM </p>
                      </div>
                    </div>
                    <div className="box_admin">
                      <div className="bix_img">
                        <img src={elio} alt="" />
                      </div>
                      <div className="bix_head">
                        <h4>Chetan Goyal</h4>
                        <p>Male,45 Today 14:30 PM </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-4">
              <div className="col-md-7">
                <div className="dischared">
                  <h3>Succesful Discharged</h3>
                  <div className="tables_head">
                    <table className="table">
                      <thead className="thead">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Patient Name</th>
                          <th scope="col">Assigned Doctor</th>
                          <th scope="col">Date</th>
                          <th>Diseases</th>
                        </tr>
                      </thead>
                      <tbody className="tbody">
                        <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td className="docty">Otto</td>
                          <td>@mdo</td>
                          <td>
                            <button>Fever</button>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Jacob</td>
                          <td>Thornton</td>
                          <td>@fat</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td colSpan={2}>Larry the Bird</td>
                          <td>@twitter</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="chart_card">
                  <div className="chart_all">
                    <h3>Team Member</h3>
                    {/* <p>See all</p> */}
                  </div>

                  <div className="card_admi">
                    <div className="box_admis">
                      <div className="box_admi">
                        <div className="bix_img">
                          <img src={elio} alt="" />
                        </div>
                        <div className="bix_head">
                          <h4>Chetan Goyal</h4>
                          <p>Male,45 Today 14:30 PM </p>
                        </div>
                      </div>
                      <div>
                        <svg
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.70721 6.29294C7.89468 6.48046 8 6.73477 8 6.99994C8 7.2651 7.89468 7.51941 7.70721 7.70694L2.05021 13.3639C1.86161 13.5461 1.60901 13.6469 1.34681 13.6446C1.08462 13.6423 0.833802 13.5372 0.648394 13.3518C0.462986 13.1663 0.357817 12.9155 0.355539 12.6533C0.35326 12.3911 0.454055 12.1385 0.636213 11.9499L5.58621 6.99994L0.636213 2.04994C0.454055 1.86133 0.353261 1.60873 0.355539 1.34653C0.357818 1.08434 0.462986 0.833525 0.648395 0.648117C0.833803 0.462709 1.08462 0.357541 1.34681 0.355262C1.60901 0.352984 1.86161 0.453778 2.05021 0.635936L7.70721 6.29294Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className="box_admis">
                      <div className="box_admi">
                        <div className="bix_img">
                          <img src={elio} alt="" />
                        </div>
                        <div className="bix_head">
                          <h4>Chetan Goyal</h4>
                          <p>Male,45 Today 14:30 PM </p>
                        </div>
                      </div>
                      <div>
                        <svg
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.70721 6.29294C7.89468 6.48046 8 6.73477 8 6.99994C8 7.2651 7.89468 7.51941 7.70721 7.70694L2.05021 13.3639C1.86161 13.5461 1.60901 13.6469 1.34681 13.6446C1.08462 13.6423 0.833802 13.5372 0.648394 13.3518C0.462986 13.1663 0.357817 12.9155 0.355539 12.6533C0.35326 12.3911 0.454055 12.1385 0.636213 11.9499L5.58621 6.99994L0.636213 2.04994C0.454055 1.86133 0.353261 1.60873 0.355539 1.34653C0.357818 1.08434 0.462986 0.833525 0.648395 0.648117C0.833803 0.462709 1.08462 0.357541 1.34681 0.355262C1.60901 0.352984 1.86161 0.453778 2.05021 0.635936L7.70721 6.29294Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="chart_card">
                  <div className="chart_all">
                    <h3>Susccesfully Admited</h3>
                    <p>See all</p>
                  </div>

                  <div className="card_admi">
                    <div className="box_admis">
                      <div className="box_admi">
                        <div className="bix_img">
                          <img src={elio} alt="" />
                        </div>
                        <div className="bix_head">
                          <h4>Chetan Goyal</h4>
                          <p>Male,45 Today 14:30 PM </p>
                        </div>
                      </div>
                      <div>
                        <div className="df">
                          <svg
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.00039 11.2001L1.80039 7.0001L0.400391 8.4001L6.00039 14.0001L18.0004 2.0001L16.6004 0.600098L6.00039 11.2001Z"
                              fill="#4BB948"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="box_admis">
                      <div className="box_admi">
                        <div className="bix_img">
                          <img src={elio} alt="" />
                        </div>
                        <div className="bix_head">
                          <h4>Chetan Goyal</h4>
                          <p>Male,45 Today 14:30 PM </p>
                        </div>
                      </div>
                      <div>
                        <div className="df">
                          <svg
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.00039 11.2001L1.80039 7.0001L0.400391 8.4001L6.00039 14.0001L18.0004 2.0001L16.6004 0.600098L6.00039 11.2001Z"
                              fill="#4BB948"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
