import React, { useContext, useEffect, useRef, useState } from "react";
import SideBar from "../../component/SideBar";
import NavBar from "../../component/NavBar";
import elipi from "../../images/elipi.png";
import eye from "../../images/eye.png";
// import enclouse from '../../images/enclouse.png'
import Tesseract from 'tesseract.js';
import upward from "../../images/Upward Arrow.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../context/common/data";
import SidebarContext from "../../context/sidebar/SidebarContext";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import view from '../../images/search.png'
import { Modal, Upload, Input, Radio, Button, Table, Space, message, notification } from 'antd';
import { BellOutlined, UploadOutlined, SendOutlined } from '@ant-design/icons';
import Popup from "../../component/PopupPreview";
import moment from "moment";
import { FaUpload } from "react-icons/fa";

const PatientData2 = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [contentUrl, setContentUrl] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [ocrResult, setOCRResult] = useState('');
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [contentType, setContentType] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [boxes, setBoxes] = useState([]);
  const [admitModal, setAdmitModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const pdfRef = useRef(null);
  const side = useContext(SidebarContext);
  const { open, toggleOpen } = side;
  const [approvalDataL2, setApprovalDataL2] = useState([])
  const [l2Query, setL2Query] = useState([])
  const [responseModal, setResponseModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [sureModal, setSureModal] = useState(false)
  const navigate = useNavigate();
  let approveId = useParams();
  let id = approveId.id;
  const [imageData, setImageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialValue = {
    insuranceType: "",
    patientType: "",
    policy: "",
    status: "",
    medical: "",
    financial: "",
    patient: "",
    policyHolder: "",
    medicalInfo: "",
    insurancePolicyInfo: "",
    financialInfo: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [openModal, setOpenModal] = useState(false);
  const [OCRModal, setOCRModal] = useState(false);
  const [links, setLinks] = useState({});
  const [OCRLink, setOCRLink] = useState({ IMGLink: "", key: "" });
  const [notifications, setNotifications] = useState({});
  const [queries, setQueries] = useState({});
  const [userData, setUserData] = useState(null);
  const fileInputRef = useRef(null);
  const [admitFile, setAdmitFile] = useState(null)
  const [disDocs, setDisDocs] = useState(false)
  const [admissionDateTime, setAdmissionDateTime] = useState(null);

  const handleChangeAdmissionDateTime = (e) => {
    setAdmissionDateTime(e.target.value);
  };

  const handleChangeAdmit = (e) => {
    const selectedFile = fileInputRef.current.files[0];
    setAdmitFile(selectedFile)
    console.log(selectedFile);
  };
  const handleClick = (url, key) => {
    setOCRLink({ IMGLink: url, key: key })
    setOCRModal(true)
    // const image = new Image();
    // image.src = url;
    // image.onload = async () => {
    //   // Set image source for preview
    //   setImageSrc(image.src);

    //   // Perform OCR
    //   const { data: { text, textRegions } } = await Tesseract.recognize(
    //     image,
    //     'eng',
    //     { logger: m => console.log(m,"HELLO") }
    //   );

    //   setOCRResult(text);
    //   setBoundingBoxes(textRegions);
    // };
    // window.open(url, '_blank');
  };

  const apiFunction = async (id) => {
    try {
      const res = await axios.post(
        `${baseUrl}/hospital/fetchDischarge/${id}`
      );
      if (res.data.success && res.data?.data) {
        setLinks(res.data?.data)
        setDisDocs(true)
      } else {
        setDisDocs(false)
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  }

  const openLink = (x) => {
    if (x == undefined || x == null) {
      notification.error({ message: 'Pan Card Not Uploaded' })
      return
    }
    window.open(x, '_blank')
  }

  const getQuery = async (id) => {
    try {
      const res = await axios.post(
        `${baseUrl}/patient/getNotification/${id}`
      );
      if (res.data) {
        setQueries(res.data.notifications)
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  }

  useEffect(() => {
    if (id) {
      apiFunction(id)
      getQuery(id)
    }
  }, [id])


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e, index) => {
    const files = e.target.files;
    const newImageData = [...imageData];
    newImageData[index].file = files[0];
    setImageData(newImageData);
  };

  const handleTitleChange = (e, index) => {
    const title = e.target.value;
    const newImageData = [...imageData];
    newImageData[index].title = title;
    setImageData(newImageData);
  };

  const addImageField = () => {
    setImageData([...imageData, { file: null, title: "" }]);
  };

  const removeImage = (index) => {
    const newImageData = imageData.filter((_, i) => i !== index);
    setImageData(newImageData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //status and query can't be empty
    if (!formData.query) {
      alert('Query is required!')
      return;
    }

    setIsLoading(true);

    const formDataNew = new FormData();
    imageData.forEach((data, index) => {
      formDataNew.append(`images[${index}]`, data.file);
      formDataNew.append(`imageTitles[${index}]`, data.title);
    });
    // Append other form fields
    formDataNew.append('query', formData.query);
    formDataNew.append('query_id', modalData?._id)

    let url = baseUrl + `/patient/hospitalresponse/${id}`;

    try {
      const res = await axios.post(url, formDataNew);
      if (res.data.success) {
        notification.success({ message: res?.data?.message || "Query Response Sent!" })
        setFormData(initialValue);
        setResponseModal(false)
        setModalData({})
      } else {
        notification.error({ message: res?.data?.message || "Response Not Sent!" })
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      alert("An error occurred");
    }
  };

  function handleGoDash() {
    setOpenModal(false);
    window.location.href = "/";
  }

  const submitAdmit = async () => {
    const fileInput = fileInputRef.current;
    if (admissionDateTime == null) {
      notification.error({ message: 'Admission Date and Time Required!' })
      return
    }
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData()
      formData.append('admissionDocs', file)
      formData.append('admissionDateTime', admissionDateTime)
      try {
        const res = await axios.post(
          `${baseUrl}/admitPatient/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (res.data?.success) {
          notification.success({ message: res.data?.message || 'Document Uploaded' })
          setAdmitModal(false)
          fileInput.value = '';
          setAdmitFile(null)
          getUser()
        }
      } catch (err) {
        console.log(err)
      }

    } else {
      notification.error({ message: 'Please Upload Admission Data!' })
      return
    }
  };


  async function handlePreview(fileUrl) {
    if (fileUrl == undefined || fileUrl == null) {
      notification.error({ message: 'No Report Found' })
      return
    }
    setContentUrl(fileUrl);
    setContentType(fileUrl.includes('.pdf') ? 'application/pdf' : 'image/*');
    setIsPopupOpen(true);
  };

  const handleNotificationClick = (notification) => {
    notification.open({
      message: notification.title,
      description: notification.text,
    });
  };

  // console.log(formData);
  async function getUser() {
    try {
      const res = await axios({
        method: "get",
        url: baseUrl + "/patient/getsinglepatient/" + id,
      });
      setUserData(res.data.data);
      setApprovalDataL2(res.data?.data?.approvalDataL2.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
      setL2Query(res.data.data?.xpertQueryL2.filter(items => items?.status !== 'approve' || items?.status != 'Admitted').sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.slice(0, 5))
    } catch (e) { }
  }

  const redrawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = imageRef.current;

    // Clear the canvas and redraw the image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, image.width, image.height);

    // Redraw each bounding box and its label
    boxes.forEach(box => {
      ctx.beginPath();
      ctx.rect(box.x, box.y, box.width, box.height);
      ctx.strokeStyle = 'red';
      ctx.stroke();
      ctx.fillStyle = 'white';
      ctx.fillText(box.label || '', box.x, box.y - 10);
    });
  };
  useEffect(() => {
    if (imageUrl) {
      redrawCanvas();
    }
  }, [boxes, imageUrl]); // Redraw when boxes or imageUrl changes

  useEffect(() => {
    getUser();

  }, []);

  const dischargePatient = async () => {
    try {
      const res = await axios.post(
        `${baseUrl}/dischargePatient/${id}`,
        { status: 'Completed' }
      )
      if (res.data?.success) {
        notification.success({ message: res.data.message || 'Status changed.' })
        navigate('/dischargeList')
      } else {
        notification.error({ message: res.data.message || 'Please try again.' })
      }
    } catch (err) {
      console.log(err)
    }
  }
  if (userData === null) {
    return <></>;
  }

  const handleImageLoad = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let image = imageRef.current

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0, image.width, image.height);
    boxes.forEach(box => {
      ctx.beginPath();
      ctx.rect(box.x, box.y, box.width, box.height);
      ctx.strokeStyle = 'red';
      ctx.stroke();
      ctx.fillStyle = 'white';
      ctx.fillText(box.label || '', box.x, box.y - 10);
    });
  };

  const handleMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    setStartPos({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setIsDrawing(true);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const currentPos = { x: e.clientX - rect.left, y: e.clientY - rect.top };

    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
    handleImageLoad(); // Redraw the image and existing boxes

    // Draw the current rectangle
    ctx.beginPath();
    ctx.rect(startPos.x, startPos.y, currentPos.x - startPos.x, currentPos.y - startPos.y);
    ctx.strokeStyle = 'red';
    ctx.stroke();
  };

  const handleMouseUp = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const endPos = { x: e.clientX - rect.left, y: e.clientY - rect.top };
    const width = endPos.x - startPos.x;
    const height = endPos.y - startPos.y;

    // Add new box (you might want to add checks for width and height)
    if (width !== 0 && height !== 0) {
      const label = prompt("Enter label for the box:", "");
      const newBox = { x: startPos.x, y: startPos.y, width, height, label };
      setBoxes(boxes => [...boxes, newBox]);
    }

    setIsDrawing(false);
  };
  const handleClose = () => {
    setOCRLink({ IMGLink: '', key: '' })
    setImageUrl('')
    setOCRModal(false)
  }

  const queryResponse = async (val) => {
    setModalData(val)
    setResponseModal(true)
  }

  const columnsL2 = [
    {
      title: 'Remark',
      dataIndex: 'query',
      key: 'query',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Raised On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        const formattedDate = moment(createdAt).format('MMMM Do YYYY, h:mm:ss a');
        return formattedDate;
      },
    },
    {
      title: 'Send Response',
      key: 'action',
      render: (value, record) => (
        <Space size="middle">
          <SendOutlined style={{ color: 'blue' }} onClick={() => queryResponse(value)} title='Send Response' />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <NavBar />
          <div className="form-bg">
            {(userData.status === "approve" || userData.status === "Admitted") && (
              <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
                <h3 className="py-2 mb-2 ms-3 justify-content-start d-flex" style={{ color: 'rgb(32, 195, 32)' }}>
                  Approval Data
                </h3>
                <div style={{ margin: '1em' }}>
                  <div>Hospital Name:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.hospitalname}</span></div>
                  <div>Patient Name:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.patientName}</span></div>
                  <div>Policy Holder:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.policyHolder}</span></div>
                  <div>Insurance:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.insurance}</span></div>
                  <div>TPA:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.tpa}</span></div>
                  <div>Policy Date:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.policyDate}</span></div>
                  <div>End Date:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.endDate}</span></div>
                  <div>Sum Insured:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.sumInsured}</span></div>
                  <div>Room Rent:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.roomRent}</span></div>
                  <div>Copay:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.copay}</span></div>
                  <div>Policy Type:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.policyType}</span></div>
                  <div>Complaint:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.complaint}</span></div>
                  <div>Treatment:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.treatment}</span></div>
                  <div>Hospital Black Listing:- <span style={{ color: '#1185CB' }}>{approvalDataL2[0]?.hospitalBL}</span></div>
                </div>
              </div>)}
            {(userData?.xpertQueryL2.length != 0) && (
              <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
                <div className=" d-flex align-items-center justify-content-between">
                  <h3 className="py-2 mb-2 ms-3 justify-content-start d-flex" style={{ color: 'red' }}>
                    Remark For Hospital
                  </h3>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="su-box col-md-12">
                      <Table
                        dataSource={l2Query}
                        columns={columnsL2}
                        style={{ borderRadius: '10px' }}
                        className="blue-header"
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* {(userData.l2status === "approve" || userData.l2status === "reject") && (
              <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="py-2 mb-2 ms-3 justify-content-start d-flex" style={{ color: userData.l2status === "approve" ? 'green' : 'red' }}>
                    Case {userData.l2status} {userData.l2status === "approve" ? '✅' : '❌'}
                  </h3>


                </div>
                <div className="container">
                  <div className="row">
                    <div className="su-box col-md-12">
                      <div className="d-flex align-items-center justify-content-between su-box1">
                        <p className="col-md-2">Remarks</p>
                        <p className="col-md-8 su-ans">{userData?.l2remark?.query}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            )}*/}


            <div className="darg my-2 py-3">
              <div className="cards pi-cards">
                <div className="row">
                  <div className="col-sm-12 col-xl-6">
                    <h6 className="text-dark py-2 mb-2 justify-content-start d-flex">
                      Type of Insurance ?
                    </h6>
                    <div className="d-flex align-items-center">
                      <div className="w-fit hos-doc">
                        <label
                          className="checkbox-container m-0"
                          htmlFor="insuranceC"
                        >
                          <input
                            className="custom-checkbox"
                            onChange={handleChange}
                            value="cash"
                            type="radio"
                            name="insuranceType"
                            id="insuranceC"
                            checked={
                              userData?.insuranceType == "cash"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                          Cash
                        </label>
                      </div>
                      <div className="w-fit hos-doc">
                        <label
                          className="checkbox-container m-0"
                          htmlFor="insurance1"
                        >
                          <input
                            className="custom-checkbox"
                            onChange={handleChange}
                            value="cashless "
                            type="radio"
                            name="insuranceType"
                            id="insurance1"
                            checked={
                              userData?.insuranceType == "cashless"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                          Cashless
                        </label>
                      </div>
                      <div className="w-fit hos-doc">
                        <label
                          className="checkbox-container m-0"
                          htmlFor="insurance2"
                        >
                          <input
                            className="custom-checkbox"
                            onChange={handleChange}
                            value="reimbursement "
                            type="radio"
                            name="insuranceType"
                            id="insurance2"
                            checked={
                              userData?.insuranceType == "reimbursement"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                          Reimbursement
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-xl-6">
                    <h6 style={{ marginLeft: '1.3em' }} className="text-dark py-2 mb-2 justify-content-start d-flex">
                      Type of Patient ?
                    </h6>
                    <div className="d-flex align-items-center">
                      <div className="form-check w-fit hos-doc">
                        <label
                          className="checkbox-container m-0"
                          htmlFor="insurance3"
                        >
                          <input
                            className="custom-checkbox"
                            onChange={handleChange}
                            value="individual "
                            type="radio"
                            name="patientType"
                            id="insurance3"
                            checked={
                              userData?.patientType == "individual"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                          Individual
                        </label>
                      </div>
                      <div className="form-check w-fit hos-doc">
                        <label
                          className="checkbox-container m-0"
                          htmlFor="insurance4"
                        >
                          <input
                            className="custom-checkbox"
                            onChange={handleChange}
                            value="corporate "
                            type="radio"
                            name="patientType"
                            id="insurance4"
                            checked={
                              userData?.patientType == "corporate"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                          Corporate
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
              <div className=" d-flex align-items-center justify-content-between">
                <h3 className="text-dark py-2 mb-2 ms-3 justify-content-start d-flex">
                  Personal Information
                </h3>
                <div className=" d-flex">
                  {/* <div className="form-check col-md-3">
                    <label className="checkbox-container m-0" htmlFor="info1">
                      <input
                        className="custom-checkbox"
                        value="yes"
                        type="radio"
                        onChange={handleChange}
                        name="policy"
                        id="info1"
                      />
                      <span className="checkmark"></span>
                      Yes
                    </label>
                  </div>

                  <div className="form-check w-fit ms-1">
                    <label className="checkbox-container m-0" htmlFor="info2">
                      <input
                        className="custom-checkbox"
                        value="Not Ok"
                        type="radio"
                        onChange={handleChange}
                        name="policy"
                        id="info2"
                      />
                      <span className="checkmark"></span>
                      Not Ok
                    </label>
                  </div> */}
                </div>
              </div>
              <h5 className="text-dark py-2 mb-4 ms-3 justify-content-start d-flex">
                Policy Holder
              </h5>

              <div className="container text-center">
                <div className="row mb-5">
                  <div className="su-box col-md-3">
                    <div className="su-box1">
                      <div className="su-box11 flex mb-2">
                        <b>Email</b>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p className="su-ans">
                            {userData?.policyHolder?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="su-box col-md-3">
                    <div className="su-box1">
                      <div className="su-box11 flex mb-2">
                        <b>Mobile Number</b>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p className="su-ans">
                            {userData?.policyHolder?.number}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="su-box col-md-3">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Aadhar Card*</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Gender</p>
                          <p>Aadhar Number</p>
                          <p>Address</p>
                        </div>
                        <div className="su-box132">
                          <p className="su-ans">
                            {
                              userData?.policyHolder?.phAdharCard
                                ?.phAdharCardData?.name
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.policyHolder?.phAdharCard
                                ?.phAdharCardData?.dob
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.policyHolder?.phAdharCard
                                ?.phAdharCardData?.gender
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.policyHolder?.phAdharCard
                                ?.phAdharCardData?.aadharNo
                            }
                          </p>
                          <p className="su-ans">
                            {" "}
                            {
                              userData?.policyHolder?.phAdharCard
                                ?.phAdharCardData?.address
                            }
                          </p>
                        </div>
                      </div>
                      <div className="su-box13 flex">
                        <div className="bhy">
                          <button onClick={() => handlePreview(userData?.policyHolder.phAdharCard.phAdharCardFrontImg)}>
                            <span>Front</span> <img src={eye} alt="" />
                          </button>
                        </div>
                        <div className="bhy">
                          <button onClick={() => handlePreview(userData?.policyHolder.phAdharCard.phAdharCardBackImg)}>
                            <span>Back</span> <img src={eye} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="su-box col-md-3">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Pan Card*</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Pan Number</p>
                        </div>
                        <div className="su-box132">
                          <p className="su-ans">
                            {
                              userData?.policyHolder?.phPanCard?.phPanCardData
                                ?.name
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.policyHolder?.phPanCard?.phPanCardData
                                ?.dob
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.policyHolder?.phPanCard?.phPanCardData
                                ?.panNo
                            }
                          </p>
                        </div>
                      </div>
                      <div className="bhy">
                        <button onClick={() => handlePreview(userData?.policyHolder.phPanCard.phPanCardFrontImg)}>
                          <span>View</span> <img src={eye} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className="text-dark py-2 mb-4 justify-content-start d-flex">
                  Patient
                </h5>
                <div className="row">
                  <div className="su-box col-md-3">
                    <div className="su-box1">
                      <div className="su-box11 flex mb-2">
                        <b>Name</b>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p className="col-md-8 su-ans">{userData?.patient?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="su-box col-md-3">
                    <div className="su-box1"></div>
                  </div>
                  <div className="su-box col-md-3">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Aadhar Card*</p>
                      </div>
                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Gender</p>
                          <p>Aadhar Number</p>
                          <p>Address</p>
                        </div>
                        <div className="su-box132">
                          <p className="su-ans">
                            {userData?.patient?.adharCard?.adharCardData?.name}
                          </p>
                          <p className="su-ans">
                            {userData?.patient?.adharCard?.adharCardData?.dob}
                          </p>
                          <p className="su-ans">
                            {
                              userData?.patient?.adharCard?.adharCardData
                                ?.gender
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.patient?.adharCard?.adharCardData
                                ?.aadharNo
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.patient?.adharCard?.adharCardData
                                ?.address
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="su-box13 flex">
                      <div className="bhy">
                        <button onClick={() => handlePreview(userData?.patient.adharCard.adharCardFrontImg)}>
                          <span>Front</span> <img src={eye} alt="" />
                        </button>
                      </div>
                      <div className="bhy">
                        <button onClick={() => handlePreview(userData?.patient.adharCard.adharCardBackImg)}>
                          <span>Back</span> <img src={eye} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="su-box col-md-3">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Aadhar Card*</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p>Name</p>
                          <p>DOB</p>
                          <p>Pan Number</p>
                        </div>
                        <div className="su-box132">
                          <p className="su-ans">Rahul Sharma</p>
                          <p className="su-ans">27/05/1995</p>
                          <p className="su-ans">Male</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            {/* ===============2nd================ */}
            <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
              <div className=" d-flex align-items-center justify-content-between">
                <h3 className="text-dark py-2 mb-2 ms-3 justify-content-start d-flex">
                  Medical Info
                </h3>
              </div>

              <div className="container text-center">
                <div className="row mb-5">
                  <div className="su-box col-md-4">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Prescription</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p>Doctors Name</p>
                          <p>Diagnosis</p>
                          <p>
                            Admission Prescribed <br /> For disease/condition
                          </p>
                          <p>Date</p>
                          {/* <p>Address</p> */}
                        </div>
                        <div className="su-box132">
                          <p className="su-ans">
                            {
                              userData?.medicalInfo?.prescription
                                ?.prescriptionData?.doctorName
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.medicalInfo?.prescription
                                ?.prescriptionData?.diagnosis
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.medicalInfo?.prescription
                                ?.prescriptionData?.admissionpresc
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.medicalInfo?.prescription
                                ?.prescriptionData?.date
                            }
                          </p>
                          {/* <p className='su-ans'>45,vijay Nagar Indore</p> */}
                        </div>
                      </div>
                      <div className="su-box13 flex">
                        <div className="bhy">
                          <button onClick={() => handlePreview(userData?.medicalInfo?.prescription?.prescriptionImg)}>
                            <span>View</span> <img src={eye} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="su-box col-md-4">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Diagnostic Report</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p>Diagnosed condition</p>
                          <p>Date</p>
                          {/* <p>Pan Number</p> */}
                        </div>
                        <div className="su-box132">
                          <p className="su-ans">
                            {
                              userData?.medicalInfo?.diagnosticReport
                                ?.diagnosticReportData?.doctorName
                            }
                          </p>
                          <p className="su-ans">
                            {
                              userData?.medicalInfo?.diagnosticReport
                                ?.diagnosticReportData?.date
                            }
                          </p>
                          {/* <p className='su-ans'>Male</p> */}
                        </div>
                      </div>
                      <div className="su-box13 flex">
                        <div className="bhy">
                          <button onClick={() => handlePreview(userData?.medicalInfo.firImg)}>
                            <span>View</span> <img src={eye} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="su-box col-md-4">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Is the cause of admission any accident</p>
                      </div>

                      <div className="su-box13 ms-4 flex">
                        <div className="su-box131">
                          <div className=" d-flex">
                            <div className="form-check w-fit hos-doc">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="nabh"
                                checked={
                                  userData?.medicalInfo?.isAccident == true
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="nabh"
                              >
                                Yes
                              </label>
                            </div>

                            <div className="form-check w-fit hos-doc">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="nabh"
                                checked={
                                  userData?.medicalInfo?.isAccident == false
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="nabh"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="su-box1 mt-3">
                      <div className="su-box11 flex">
                        <p>Copy of FIR / MLC</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="bhy">
                          <button onClick={() => handlePreview(userData?.medicalInfo.firImg1)}>
                            <span>View</span> <img src={eye} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <h5 className="text-dark py-2 mb-4 justify-content-start d-flex">
                    Before coming to this hospital, did you visit any other doctor?
                  </h5>
                  <div className="row ms-4">
                    <div className="col-2">
                      <div className="form-check  w-fit hos-doc">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nabh"
                          id="nabh"
                          checked={
                            userData?.medicalInfo?.isVisitOtherDoct == true
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label" htmlFor="nabh">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="form-check w-fit hos-doc">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nabh"
                          id="nabh"
                          checked={
                            userData?.medicalInfo?.isVisitOtherDoct == false
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label" htmlFor="nabh">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="su-box1 mt-3">
                    <div className="su-box11 flex ">
                      <p>Prescription and other documents</p>
                    </div>

                    <div className="su-box13 flex">
                      <div className="su-box131">
                        <p>Doctors Name</p>
                        <p>Diagnosis</p>
                        <p>Admission Prescribed For disease/condition</p>
                        <p>Date</p>
                      </div>
                      <div className="su-box132">
                        <p className="su-ans">
                          {
                            userData?.medicalInfo?.otherDoc?.otherDocData
                              ?.doctorName
                          }
                        </p>
                        <p className="su-ans">
                          {
                            userData?.medicalInfo?.otherDoc?.otherDocData
                              ?.diagnosis
                          }
                        </p>
                        <p className="su-ans">
                          {
                            userData?.medicalInfo?.otherDoc?.otherDocData
                              ?.admissionpresc
                          }
                        </p>
                        <p className="su-ans">
                          {userData?.medicalInfo?.otherDoc?.otherDocData?.date}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <h3 className="text-dark py-2 mb-4  justify-content-start d-flex">
                      Insurance Policy Info
                    </h3>
                    <div className="row mb-5">
                      <div className="su-box col-md-4">
                        <div className="su-box1">
                          <div className="su-box11 flex">
                            <p>Policy holder’s latest insurance policy </p>
                          </div>

                          <div className="su-box13 flex">
                            <div className="su-box131">
                              <p>Name</p>
                              <p>Policy Date</p>
                              <p>Policy end Date</p>
                              <p>Sum Insured</p>
                              {/* <p>Address</p> */}
                            </div>
                            <div className="su-box132">
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.latestPolicy
                                    ?.latestPolicyData?.name
                                }
                              </p>
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.latestPolicy
                                    ?.latestPolicyData?.startDate
                                }
                              </p>
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.latestPolicy
                                    ?.latestPolicyData?.endDate
                                }
                              </p>
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.latestPolicy
                                    ?.latestPolicyData?.suminsured
                                }
                              </p>
                              {/* <p className='su-ans'>45,vijay Nagar Indore</p> */}
                            </div>
                          </div>
                        </div>
                        <div className="bhy">
                          <button onClick={() => handlePreview(userData?.insurancePolicyInfo.latestPolicy.latestPolicyImg)}>
                            <span>View</span> <img src={eye} alt="" />
                          </button>
                        </div>
                      </div>
                      <div className="su-box col-md-4">
                        <div className="su-box1">
                          <div className="su-box11 flex">
                            <p>Policy documents for previous 2 years </p>
                          </div>

                          <div className="su-box13 flex">
                            <div className="su-box131">
                              <p>Policy Inception Date</p>
                              <p>Currently Active Policy</p>
                              <p>Sum Insured</p>
                              {/* <p>Room Rent</p> */}
                            </div>
                            <div className="su-box132">
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.policyDoc
                                    ?.policyDocData?.date
                                }
                              </p>
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.policyDoc
                                    ?.policyDocData?.activePolicy
                                }
                              </p>
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.policyDoc
                                    ?.policyDocData?.suminsured
                                }
                              </p>
                              {/* <p className="su-ans">2,000</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="su-box col-md-4">
                        <div className="su-box1">
                          <div className="su-box11 flex">
                            <p>Policy Wording</p>
                          </div>

                          <div className="su-box13 flex">
                            <div className="su-box131">
                              <p>Policy Name</p>
                              <p>UIN No.</p>
                            </div>
                            <div className="su-box132">
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.latestReceipt
                                    ?.latestReceiptData?.policyName
                                }
                              </p>
                              <p className="su-ans">
                                {
                                  userData?.insurancePolicyInfo?.latestReceipt
                                    ?.latestReceiptData?.policyUin
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* =======================3rd=============== */}

            <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
              <div className=" d-flex align-items-center justify-content-between">
                <h3 className="text-dark py-2 mb-2 ms-3 justify-content-start d-flex">
                  Financial Information
                </h3>
                <div className=" d-flex">
                  {/* <div className="form-check w-fit me-5">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleChange}
                      value="Ok"
                      name="financial"
                      id="nabh"
                    />
                    <label className="form-check-label" htmlFor="nabh">
                      Ok
                    </label>
                  </div> */}

                  {/* <div className="form-check w-fit ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleChange}
                      name="financial"
                      value="Not Ok"
                      id="nabh"
                    />
                    <label className="form-check-label" htmlFor="nabh">
                      Not Ok
                    </label>
                  </div> */}
                </div>
              </div>
              {/* <h5 className='text-dark py-2 mb-4 ms-3 justify-content-start d-flex'>Policy Holder</h5> */}

              <div className="container text-center">
                <div className="row mb-5">
                  <div className="su-box col-md-5">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>Upload Policy holder’s Canceled Cheque</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <p>Bank Name</p>
                          {/* <p>Address</p> */}
                          <p>IFSC code</p>
                        </div>
                        <div className="su-box132">
                          <p className="su-ans">
                            {
                              userData?.financialInfo?.canceledCheque
                                ?.canceledChequeData?.bankName
                            }
                          </p>
                          {/* <p className="su-ans"></p> */}
                          <p className="su-ans">
                            {
                              userData?.financialInfo?.canceledCheque
                                ?.canceledChequeData?.ifscCode
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bhy">
                      <button onClick={() => handlePreview(userData?.financialInfo.canceledCheque.canceledChequeImg)}>
                        <span>View</span> <img src={eye} alt="" />
                      </button>
                    </div>
                  </div>

                  <div className="su-box col-md-7">
                    <div className="su-box1">
                      <div className="su-box11 flex">
                        <p>
                          Does the policy holder use same Bank for net banking
                          as of Canceled cheque{" "}
                        </p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <div className="d-flex ms-4">
                            <div className="form-check w-fit hos-doc">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="nabh"
                                id="nabh"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="nabh"
                              >
                                Yes
                              </label>
                            </div>

                            <div className="form-check w-fit hos-doc">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="nabh"
                                id="nabh"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="nabh"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="su-box1 mt-3">
                      <div className="su-box11 flex">
                        <p>Copy of FIR / MLC</p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="bhy">
                          <button> <span>Download</span> <img src={upward} alt="" /> </button>
                        </div>
                      </div>
                    </div> */}

                    <div className="su-box1 mt-4">
                      <div className="su-box11 flex">
                        <p>
                          Does the policy holder use same Bank’s debit card as
                          of canceled cheque
                        </p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="su-box131">
                          <div className=" d-flex ms-4">
                            <div className="form-check w-fit hos-doc">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="nabh"
                                id="nabh"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="nabh"
                              >
                                Yes
                              </label>
                            </div>

                            <div className="form-check w-fit hos-doc">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="nabh"
                                id="nabh"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="nabh"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="su-box1 mt-3">
                      <div className="su-box11 flex">
                        <p>Other document </p>
                      </div>

                      <div className="su-box13 flex">
                        <div className="bhy">
                          <button>
                            {" "}
                            <span>Download</span> <img src={upward} alt="" />{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hospital-responses">
              {userData.hospitalResponses.map((response, index) => (
                <div key={index} className="form1 bg-gray card rounded bg-white shadow-sm px-4 my-2 py-3">
                  <div className="response-number">
                    <h5>Response #{index + 1}</h5>
                  </div>
                  <p>Status: {response.status}</p>
                  <p>Query: {response.query}</p>
                  {response.imageUrls && response.imageUrls.map((url, urlIndex) => (
                    <img key={urlIndex} src={url} alt={`Response ${urlIndex}`} />
                  ))}
                  {response.images && Object.entries(response.images).map(([key, value], imgIndex) => (
                    <div key={imgIndex}>
                      <p>{key}</p>
                      <img src={value} alt={`Response ${key}`} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {(userData?.status == 'approve' || userData?.status == "Admitted") && <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
              <h3 className="text-dark py-2 mb-2 ms-3 justify-content-start d-flex" style={{ color: userData?.admissionData?.status ? 'green' : 'red' }}>
                {(userData?.admissionData?.status && userData?.status == 'approve') && 'Patient Admission Request Sent ✅'}
                {(userData?.admissionData?.status && userData?.status == 'Admitted') && 'Patient Admitted ✅'}
              </h3>

              {userData?.admissionData?.status && <center><Button
                onClick={() => openLink(userData?.admissionData?.admissionDocs)}
                style={{
                  backgroundColor: '#3c8dc3',
                  color: 'white',
                  borderRadius: '5px',
                  padding: '0.5em 1em',
                  cursor: 'pointer',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  width: '33%'
                }}
              >Admission Docs</Button><div>Admitted At:- {userData?.admissionData?.admissionDateTime}</div>
                {userData?.status == 'approve' && < span style={{ color: 'red' }}>(Waiting for patient's terms and condition approval.)</span>}</center>
              }
            </div>}
            {((userData?.status == 'approve' || userData?.status == 'Admitted') && disDocs) && <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
              <h3 className="text-dark py-2 mb-2 ms-3 justify-content-start d-flex">
                Uploaded Discharge Documents
              </h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {Object.keys(links).map((key, index) => (
                  <div key={index} style={{ margin: '1em 0 1em 1em', alignItems: 'center', flexBasis: '33.33%' }}>
                    <Button
                      onClick={() => handleClick(links[key], key)}
                      style={{
                        backgroundColor: '#3c8dc3',
                        color: 'white',
                        borderRadius: '5px',
                        padding: '0.5em 1em',
                        cursor: 'pointer',
                        border: 'none',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        width: '100%'
                      }}
                    >
                      <img src={view} alt="view" style={{ height: '19px', marginRight: '0.5em' }} />
                      <span>{key}</span>
                    </Button>
                  </div>
                ))}
              </div>
              {userData?.dischargeStatus === 'true' && <center><h3 style={{ color: 'red', marginLeft: '1em', marginTop: '2em' }}>*You can now discharge this patient.</h3></center>}
            </div>}

            {/* =======================4th=============== */}

            <div
              className="flex me-2"
              style={{ justifyContent: "end", gap: "12px" }}
            >
              {(userData?.status == 'Admitted' && userData?.admissionData?.status && !userData?.dischargeStatus) && <button
                className="approve-btn"
                onClick={() => navigate(`/discharge/${id}`)}
              >
                <UploadOutlined /> Discharge Documents
              </button>}
              {userData?.dischargeStatus && <Button
                style={{ backgroundColor: 'rgb(32, 195, 32)', color: 'white', height: '45px' }}
                onClick={() => setSureModal(true)}
              >Discharge Patient
              </Button>}
              {(!userData?.admissionData?.status && userData?.status == 'approve') && <Button style={{ backgroundColor: '#1185CB', color: 'white', height: '45px' }} onClick={() => setAdmitModal(true)}>Admit Patient</Button>}
              <button
                className="approve-btn"
                onClick={() => handleGoDash()}
              >
                Go to Dashboard
              </button>
            </div>

          </div>
        </div>
      </div >


      {/*=========VIEW DOC MODAL===========================================================*/}
      < Modal
        open={isPopupOpen}
        onCancel={() => setIsPopupOpen(false)}
        closable={false}
        footer={null}
        width={500} >
        {contentType === 'application/pdf' ? (
          <iframe title='Preview' src={contentUrl} style={{ width: '100%', height: '500px' }}></iframe>
        ) : (
          <img src={contentUrl} alt="Content" style={{ maxWidth: '100%' }} />
        )}
        <div className="submit_br">
          <button className="submit_br" onClick={() => setIsPopupOpen(false)}>Close</button>
        </div>
      </Modal >

      {/*============BOUNDING BOX MODAL=====================================================*/}
      < Modal
        open={OCRModal}
        onCancel={() => setOCRModal(false)}
        closable={false}
        footer={null}
        width={1200}
      >
        <Button onClick={handleClose} style={{ backgroundColor: 'red', float: 'right', color: 'white' }}>X</Button>
        <div style={{ marginTop: '2em' }}>
          <h2>{OCRLink.key}</h2>
          <input
            id='bb-input'
            type="text"
            value={OCRLink.IMGLink}
            placeholder="Enter image URL"
          />
          {OCRLink.IMGLink.endsWith('.pdf') ? (
            <embed src={OCRLink.IMGLink} ref={pdfRef} onLoad={handleImageLoad} type="application/pdf" width="100%" height="600px" />
          ) : (
            <img
              src={OCRLink.IMGLink}
              alt="Bounding Box Target"
              ref={imageRef}
              onLoad={handleImageLoad}
              style={{ display: 'none' }}
            />
          )}

          <canvas
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          ></canvas>
          { /* <div>
            <h6>Current Bounding Boxes</h6>
            <ul>
              {boxes.map((box, i) => (
                <li key={i}>
                  {box.label || 'No label'}: {box.x}, {box.y}, {box.width}, {box.height}
                </li>
              ))}
            </ul>
              </div>
          <div style={{ width: '50%', padding: '0 20px', overflowY: 'scroll' }}>
          <h2>OCR Result:</h2>
          <p>{ocrResult}</p>
        </div>
     
          <button id="bb-button" onClick={() => console.log(boxes)}>Save</button> */}

        </div>

      </Modal >

      <Modal
        open={responseModal}
        onCancel={() => setResponseModal(false)}
        footer={null}
        width={600}
      >
        <h3>You are responding to the query:- <span style={{ color: 'red' }}>'{modalData?.query}'</span></h3>
        <div>
          <form className="tryyr">
            <div className="image-upload-section mb-3 float">
              <h6>Upload Images</h6>
              {imageData.map((data, index) => (
                <div key={index} className="image-upload-block">
                  <Upload
                    accept="image/*"
                    onChange={e => handleImageChange(e, index)}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <Input
                    placeholder="Image Title"
                    value={data.title}
                    onChange={e => handleTitleChange(e, index)}
                  />
                  <Button
                    className="bg-danger small"
                    onClick={() => removeImage(index)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={addImageField}>Add Images</Button>
            </div>
            <div className="form-floating">
              <Input.TextArea
                style={{ height: "80px" }}
                className="form-control mt-1"
                placeholder="Leave your remarks here"
                id="remarks"
                name="query"
                value={formData?.remarks}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="remarks">Remarks</label>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="submit_br">
                <Button onClick={(e) => handleSubmit(e)} disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Submit'}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        open={sureModal}
        onCancel={() => setSureModal(false)}
        footer={null}
        width={600}
      >
        Are you sure, you want to discharge this patient?
        <Button style={{ backgroundColor: 'rgb(32, 195, 32)', color: 'white', marginLeft: '1em' }} onClick={dischargePatient}>YES</Button>
        <Button style={{ backgroundColor: 'red', color: 'white', marginLeft: '1em' }} onClick={() => setSureModal(false)}>No</Button>
      </Modal>

      <Modal
        open={admitModal}
        onCancel={() => setAdmitModal(false)}
        footer={null}
        width={600}
      >
        <center> <h3 style={{ color: 'green', marginTop: '1em' }}>Admit Patient</h3></center>
        <label htmlFor="admitDocs" className="form-control" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {admitFile === null ? "Upload Admission Documents" : admitFile.name?.substring(0, 20)}
          <FaUpload />
          <input
            type="file"
            accept="image/*,.pdf"
            onChange={handleChangeAdmit}
            ref={fileInputRef}
            id='admitDocs'
            style={{ display: 'none' }} // Hide the input element
            aria-describedby="emailHelp"
          />
        </label>
        <div style={{ marginTop: '1em' }}>
          <label htmlFor="admissionDateTime" className="form-control" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Admission Date & Time:
            <input
              type="datetime-local"
              id="admissionDateTime"
              value={admissionDateTime}
              style={{ borderTop: 'none' }}
              onChange={handleChangeAdmissionDateTime}
            />
          </label>
        </div>
        <center><Button style={{ backgroundColor: ' #1185CB', color: 'white', marginTop: '1em' }} onClick={submitAdmit}>Admit Patient</Button></center>

      </Modal>
    </>
  );
};

export default PatientData2;
