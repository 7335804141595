import React from 'react';
import elipi from '../../images/elipi.png'
import enclouse from '../../images/enclouse.png'
import SideBar from '../SideBar';
import NavBar from '../NavBar';
import { useNavigate } from 'react-router-dom';

const Pi2 = ({ value, setValue, handleChange }) => {
  const navigate=useNavigate();

  return (
    <>
      <div className='row'>
        <div className='col-md-2'>
          <SideBar />
        </div>
        <div className='col-md-10 bg-gray'>
          <NavBar />

          <div>
            <div className='darg my-2 py-3'>
              <div className="cards">
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Personal Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Case Information</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Credit Document</h4>
                  </div>
                </div>
                <div className="carding">
                  <img src={elipi} alt="" />
                  <h3>Step</h3>
                  <div>
                    <h4>Financial Information</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className='form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3'>
              <h3 className='text-dark py-2 mb-4 ms-3 justify-content-start d-flex'>Medical Info</h3>

              <div className="container text-center">
                <div className="flex">
                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Upload Prescription *</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar w-full mx-0'>
                          <p>Upload</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Doctors Name</p>
                          <p>Diagnosis</p>
                          <p>Admission Prescribed For disease/condition</p>
                          <p>Date</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='mb-3'>
                      <label htmlFor="exampleInputEmail1" className="form-label flex">Is the cause of admission any accident? *</label>
                      <div className='row ms-1'>
                        <div className="form-check w-fit me-10">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check w-fit">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='mb-4'>
                      <div className="pi-box11 flex">
                        <p>Please Upload a copy of FIR / MLC *</p>
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar w-full mx-0'>
                          <p>Upload</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="exampleInputEmail1" className="form-label flex text-start">Before coming to this hospital, did you visit any other doctor? *</label>
                      <div className='row ms-1'>
                        <div className="form-check w-fit me-10">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check w-fit">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Upload Diagnostic reports *</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar w-full mx-0'>
                          <p>Upload</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Diagnosed condition</p>
                          <p>Date</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Upload Prescription and other documents *</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar w-full mx-0'>
                          <p>Upload</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Doctors Name</p>
                          <p>Diagnosis</p>
                          <p>Admission Prescribed For disease/condition</p>
                          <p>Date</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className='text-dark py-2 mb-4 ms-3 justify-content-start d-flex'>Insurance Policy Info</h3>

                <div className="flex mt-4">
                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Policy holder’s latest insurance policy *</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar w-full mx-0'>
                          <p>Upload</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Name</p>
                          <p>Policy Date</p>
                          <p>Policy end Date</p>
                          <p>Sum Insured</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Policy documents for previous 2 years *</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar w-full mx-0'>
                          <p>Upload</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Policy Inception Date</p>
                          <p>Currently Active Policy</p>
                          <p>Sum Insured</p>
                          <p>Room Rent</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="pi-box">
                    <div className="pi-box1">
                      <div className="pi-box11 flex">
                        <p>Last Insurance paid receipt *</p>
                        <img width={13} height={13} src="/images/hide.png" alt="" />
                      </div>

                      <div className="pi-box12 flex">
                        <div className='dar w-full mx-0'>
                          <p>Upload</p>
                          <img src={enclouse} alt="" />
                          <input type="file" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                      </div>

                      <div className="pi-box13 flex">
                        <div className="pi-box131">
                          <p>Date</p>
                          <p>Amount</p>
                        </div>
                        <div className="pi-box132">
                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>

                          <div className='pi-box132-div'>
                          <p>{value.phPanName1}</p>
                            <input type="text" value={value.phPanName1} handleChange={handleChange} name="" />
                          </div>
                        </div>
                        <div className="pi-box133">
                          <img src="/images/edit.png" alt="" />
                          <img src="/images/edit.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="save m-lg-1 ">
                  <button onClick={()=>{
                    navigate('/personal-information/3');
                  }}>Save and Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pi2;
