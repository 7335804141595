const initialState = {
  currentStep: null,
  currentUser: null,
  hospitalId: null,
  policyDetails: null,
  prompt: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload,
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "SET_HOSPITAL_ID":
      return {
        ...state,
        hospitalId: action.payload,
      };
    case "SET_POLICY_DETAILS":
      return {
        ...state,
        policyDetails: action.payload,
      };
    case "SET_PROMPT":
      return {
        ...state,
        prompt: action.payload,
      };
    default:
      return state;
  }
}

export default rootReducer