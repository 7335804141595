import React from "react";
// import { useNavigate } from 'react-router-dom';

const SuperPop = () => {
  // const navigate=useNavigate();

  return (
    <>
    
    </>
  );
};

export default SuperPop;
