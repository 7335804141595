import React, { useContext, useEffect, useRef, useState } from "react";
import SideBar from "../component/SideBar";
import leftArrow from "../images/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/common/data";
import DoneImg from "../images/done.svg";
import axios from "axios";
import { AlertContainer } from "../component/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import SidebarContext from "../context/sidebar/SidebarContext";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";

const Declaration = () => {
  const side = useContext(SidebarContext);
  const dispatch = useDispatch();
  const { currentUser, hospitalId } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    hospitalId: state.global.hospitalId,

  }));
  const { open, toggleOpen } = side;
  const navigate = useNavigate();
  const imageRef = useRef();
  const [modal, setModal] = useState(false)
  const [data, setData] = useState({})
  const [disclaimer, setDisclaimer] = useState(false)
  const initialValue = {
    image: null,
  };
  const [ipAddress, setIpAddress] = useState('');
  const [formData, setFormData] = useState(initialValue);
  const [showPreview, setShowPreview] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    var formDataUpdated = new FormData();
    formDataUpdated.append("hospitalId", hospitalId);
    formDataUpdated.append("ipAddress", ipAddress);
    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/signup/declaration",
        data: formDataUpdated,
        headers: {
          "content-type": "multipart/form-data",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.status === 201) {
        setLoader(false);
        if (res.data?.data?.loginStatus) {
          setTimeout(() => {
            navigate("/dashboard");
            setFormData(initialValue);
          }, 3000);
          dispatch({ type: "SET_CURRENT_STEP", payload: null });
        } else {
          navigate("/login");
          window.localStorage.clear()
          dispatch({ type: "SET_CURRENT_STEP", payload: null });
          dispatch({ type: "SET_HOSPITAL_ID", payload: null });
        }
      }
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.status === 500) {
        setLoader(false);
      }
    }
  };
  const getData = async () => {
    try {
      let form = new FormData();
      form.append("hospitalId", hospitalId);

      const res = await axios({
        method: "post",
        url: baseUrl + "/signup/getDecData",
        data: form,
        headers: {
          "content-type": "multipart/form-data",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data?.success) {
        setModal(true)
        setData(res.data?.data)
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const close = async () => {

    setModal(false)

  }

  const handleChangeOT = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setDisclaimer(true)
    } else {
      setDisclaimer(false)
    }
  };
  return (
    <>
      <div className="form-bg">
        <div style={{ marginBottom: "16px" }}>
          <div className="cards">
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Basic Information</h4>
              </div>
            </div>
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Documents upload</h4>
              </div>
            </div>
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Add Facilities</h4>
              </div>
            </div>
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Existing Insurance</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">5</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Declaration</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="form1 bg-gray card rounded bg-white shadow-sm px-2 my-2 py-3">
          <h3 className="text-dark py-2 mb-4 m-lg-3 justify-content-start d-flex">
            Declaration
          </h3>
          <div className="row">
            <div className="col-md-8 m-lg-3">
              <div className="para">
                <p>
                  <span style={{ color: 'red' }}>Congratulations!</span> you are just one checkbox away to complete your Signup Process.
                </p>
                <p><Button style={{ border: 'none', color: 'blue' }} onClick={getData}>Review</Button> and Accept the declaration and you are all set.</p>
              </div>
            </div>
          </div>
          {disclaimer && <div
            className="save flex flex-wrap align-items-center ms-3"
            style={{ justifyContent: "unset" }}
          >
            <div className="submit">
              <button onClick={handleSubmit}>Submit</button>
            </div>

          </div>}
        </div>
      </div>

      <Modal open={modal} footer={false} onCancel={close} width={800}>



        <div className="mou-container">

          <center> <h2>Memorandum of Understanding (MOU)
          </h2>
            <h4> <p>Between VPV Medicure Pvt. Ltd. & {data?.hospitalName}</p></h4>
          </center>

          <div className="mou-content">

            <p>The undersigned hospital/nursing home (Hereinafter called hospital or Hospital) and VPV Medicure Pvt. Ltd., having their registered office at <h4 style={{ backgroundColor: '#00b1ff30' }}>B-2/32-A, Ground Floor Yamuna Vihar Delhi North East DL 110053</h4>(hereinafter called Xpert Cure) are entering into this agreement.</p>
            <p>The Hospital has expressed interest in engaging the services of Xpert Cure as per terms of engagement captured in this MOU. Xpert Cure shall play the role of facilitating insurance (cashless, reimbursement etc.), financing (medical loans etc.), and non-medical patient care services for patients at the Hospital. Xpert Cure shall provide these services based on the patient information, documents and such provided by the Hospital. The Hospital is liable to ensure genuineness, authenticity and patient consent of such information/documents provided to Xpert Cure. Xpert Cure shall in no way be responsible for medical/surgical opinion, treatment, pre or post medical services to the patients and hence shall not be liable towards any such consequences. Xpert Cure shall honor all insurance company policies, tpa arrangements and panels wherever valid reimbursement/cashless payment methods are allowed as the case maybe. We endeavor to cover all such insurance/govt panels however there may be some exclusions based on hospital/patient/regulator mandates from time to time.</p>
            <h3>Xpert Cure’s Obligations:</h3>
            <ol>
              <li>Provide opinion on the eligibility and applicability of patient’s insurance based on the information/documents provided by the Hospital.</li>
              <li>Advise the Hospital on preparing the right documentation for insurance processing and help the patient and Hospital submit the documents to Insurance Companies, their intermediaries etc. as per defined process and with high ethical standards.</li>
              <li>Respond to queries, resolution requests and other such procedures that are raised by the Insurance companies towards the patients handled for the Hospital by Xpert Cure.</li>
              <li>Collection of payments and endeavor to minimize rejections or loss of payment to the Hospital.</li>
              <li>Use Xpert Cure technology platform to make the Hospital’s processes more efficient and reduce errors that may lead to loss of revenue and reputation with the insurance ecosystem.</li>
              <li>Assist the Hospital in reaching out to their patient base and informing them of cashless services availability at the Hospital.</li>
            </ol>
            <h3>Hospital’s Obligations:</h3>
            <ol>
              <li>Follow the process set by Xpert Cure for patient document submission, approvals and insurance process.</li>
              <li>Hospital shall mandatorily intimate Xpert Cure before the patient's admission to the hospital.</li>
              <li>Hospital shall provide the required documents of the patient, of the treatment, insurance documents, and any other documents as needed by Xpert Cure Insuretech platform, Insurance companies & their intermediaries for the purpose of fulfilling services provided under this agreement.</li>
              <li>Hospital shall transparently inform Xpert Cure of all billing and collections (at any point in time before or after admission and discharge) done towards the patient whether it is made under one bill or multiple bills, including any and all tests, medicines, implants, investigations etc.</li>
              <li>Hospital shall take an approval from Xpert Cure before patient discharge, so that all necessary work has been completed by Xpert Cure to manage insurance operations and to smoothly clear insurance submissions in time.</li>
              <li>Hospital shall be liable for the authenticity of documents submitted by them to Xpert Cure. In case of any misrepresentation, fraud or mal-intention by the patient or Hospital, Xpert Cure shall not be liable for any payment towards the case or any penalty/charges arising out of such transaction.</li>
              <li>Hospital shall provide support to Xpert Cure in every way for recovering the amount from the patient and insurance company as and when asked for.</li>
              <li>Ensure the Patient provides consent for checking his financial credit score and signs lending e-Agreement and e-NACH apart from sharing Postdated cheque (PDC) as required by law.</li>
            </ol>
          </div>
          <div>
            <h2>Commercials:</h2>
            <p>For patients with valid private insurance cases that are approved by Xpert Cure:</p>
            <ol>
              <li>
                The Hospital shall be paid their share of the collected bill amount, after deducting Xpert Cure charges as above.
              </li>
              <li>
                Xpert Cure shall deduct 15% plus GST of the total collected bill amount. Taxes as applicable.
              </li>
              <li>
                On receiving payment intimation from the insurance company, Xpert Cure shall endeavor to make collection from the patients.
              </li>
            </ol>
            <h3>Settlement:</h3>
            <ol>
              <li>
                Based on the approval provided by Xpert Cure, payment of approved bill amount to the Hospital shall be made within 5-7 working days of receiving the payment from patient/insurance into Xpert Cure account.
              </li>
              <li>
                Xpert Cure receiving the amount in its bank account after insurance settlement.
              </li>
            </ol>
          </div>
          <div>
            <h2>Terms of Engagement:</h2>
            <ol>
              <li>Xpert Cure shall handle insurance, patient financing (optional) through RBI approved process and other such paperwork from the patient side in coordination with the Hospital team.</li>
              <li>Insurance financing would be provided to credit worthy patients as per RBI/ bureau mandates basis an interest free loan.</li>
              <li>Xpert Cure may provide consulting on the billing details to the Hospital team, who would invoice the patient.</li>
              <li>Both parties shall settle each other’s dues in a timely manner as per the settlement terms agreed in agreement.</li>
              <li>In case the amount due towards insurance cases handled as above is rejected/not recovered due to lack of providing any document/information asked by Xpert Cure, due to the patient not making the payment or any such cause, the amount shall not be payable to the Hospital, and any amount paid in advance shall be adjusted in further transactions.</li>
              <li>The Hospital confirms that after signing this MoU, all insurance cases managed at the Hospital shall be managed by Xpert Cure only exclusively and not through any other party.</li>
              <li>If needed, Xpert Cure shall be allowed to place an executive at the Hospital to facilitate the services to the Hospital under this MoU. The Hospital shall provide an active desk for the same at their cost.</li>
              <li>The Hospital shall keep the terms of this MoU confidential.</li>
              <li>Both parties are liable to fulfill their statutory and regulatory obligations as advised by the authorities from time to time, and none or the parties shall have any liability towards the other for the same.</li>
              <li>In case the patient or the Hospital team is unable to comply with the process set herewith and evolved with time due to which the final bill approval is rejected by Xpert Cure, the hospital shall collect the payment directly from the patient. Xpert Cure will make all efforts to train/inform the Hospital on following the due regulatory and Xpert Cure processes.</li>
              <li>Xpert Cure shall not be obliged for or liable towards any other services than the ones mentioned in this agreement. Under this arrangement, Xpert Cure is not responsible for medical services to patients, which remains solely the Hospital’s responsibility.</li>
              <li>Xpert Cure shall not be liable for any insurance claim rejection / short payment by Insurance company for any cause whether it is attributable to the hospital or patient or any other cause, like but not limited to fault, omission, misinformation, noncompliance, over pricing etc. on part of the Hospital or patient. Hospital shall be liable for all such amounts and will reimburse it to Xpert Cure as per the case within seven days of such information as provided by Xpert Cure to the Hospital.</li>
              <li>Xpert Cure shall be allowed to place its posters/signboard/branding materials etc. in the Hospital premises at no extra cost.</li>
              <li>This agreement may be terminated for convenience by either party by giving one month’s notice in writing.</li>
              <li>This Agreement and the other documents shall be governed by and construed in accordance with the laws of India. The Courts in Delhi shall have the exclusive jurisdiction for all disputes arising out of this Agreement.</li>
              <li>Any dispute relating to the loan hereunder, or in respect of any rights, liabilities and/or obligations arising out of this Agreement shall be resolved by arbitration by a sole arbitrator appointed by both the disputed parties. The arbitration proceedings shall be carried out in accordance with the provisions laid down by the Arbitration & Conciliation Act, 1996, and the place of arbitration shall be in Delhi. The arbitration proceedings shall be conducted in the English language.</li>
              <li>Anything that is not covered by this MoU may not be deemed to be agreed by Xpert Cure, unless agreed in writing.</li>
            </ol>
          </div>
          <div style={{ border: "2px solid black", display: "flex" }}>
            <div style={{ width: "100%", padding: "1em" }}>
              <p style={{ margin: "0" }}>For<strong> VPV Medicure Pvt Ltd </strong></p>
              <p style={{ margin: "0" }}>Director & Authorized Signatory Name</p>
              <p style={{ marginTop: "9em", marginBottom: "0" }}>(Signature and Stamp)</p>
            </div>
            <div style={{ width: '100%', padding: '1em' }}>
              <p style={{ margin: '0' }}>For:- <strong>{data?.hospitalName}</strong></p>
              <p style={{ margin: '0' }}>Director Authorized Signatory (If authorized signatory is different from director, attach an authorization letter from the same) Name:- <strong>{data?.ownerName}</strong></p>
              <p style={{ marginTop: '5.65em', marginBottom: '0' }}>(Signature and Stamp)</p>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <div>
              <label
                className="checkbox-container m-0"
                htmlFor="disclaimerAccepted"
              >
                <input
                  className="custom-checkbox"
                  onChange={handleChangeOT}
                  value="disclaimerAccepted"
                  type="checkbox"
                  name="disclaimerAccepted"
                  id="disclaimerAccepted"
                />
                <span className="checkmark"></span>
                Accept
              </label>
            </div>
          </div>
        </div>
      </Modal >

      {
        loader ? (
          <div className="loader-container" >
            <span className="loader"></span>
          </div >
        ) : (
          ""
        )}


    </>
  );
};

export default Declaration;
