import React from 'react'

const Popup4 = () => {
    return (
        <>
            <div className='clients-pop3'>

                <div className="client-container3">
                    <div className="form87">
                        <h2>Declaration and T&C
                        </h2>
                        <form>
                          <div className="para_pop1">
                              <p>The patient declares that the above-mentioned is correct as of the time and date of submission and he/she is not hiding anything.</p>
                          </div>

                          <div className="para_pop2">
                             <p>If your application is rejected for any reason, then the Xpertcure team is not liable and is responsible for it.</p>
                          </div>

                            <div className="pending_btn pending_btn1">
                                <button className='pend'>ok</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Popup4