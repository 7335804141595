import React from "react";

const popup2 = () => {
  return (
    <>
      {/* <div className="clients-pop1">
        <div className="client-container1">
          <div className="form87">
            <h2>
              The patient has submitted the <br /> Declaration
            </h2>
            <form>
              <div className="btn_tr">
                <div className="yes_brn yes_brn1">
                  <button>Yes</button>
                </div>
                <div className="pending_btn pending_btn1">
                  <button>Pending</button>
                </div>
              </div>

              <div className="para_o">
                <h3>
                  Informe The patient to submit the Declaration on the link
                  shared by us
                </h3>
              </div>

              <div className="submit_br">
                <button>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default popup2;
