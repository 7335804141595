import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CompanyPage from './pages/company/Company';
import MedicalPage from './pages/medical/Medical';
import Form from './pages/form/Form ';
import Insurance from './pages/Insurance';
import Declaration from './pages/Declaration';
import Landing from './pages/Landing';
import Signup from './pages/Hospital/Signup';
import Document from './pages/Hospital/Document';
import Facility from './pages/Hospital/Facility';
import PersonalInformation from './component/PersonalInformation/PersonalInformation';
import Pi4 from './component/PersonalInformation/Pi4';
import Popup2 from './component/PersonalInformation/popup2';
import Popup3 from './component/PersonalInformation/Popup3';
import Popup4 from './component/PersonalInformation/Popup4';
import Popup5 from './component/PersonalInformation/Popup5';
import Dashboard from './pages/Dashboard';
import Login1 from './pages/SuperAdmin/newLogin1';
import PatientData from './pages/SuperAdmin/PatientData';
import SuperPop from './pages/SuperAdmin/SuperPop';
import PatientData1 from './pages/SuperAdmin/PatientData1';
import PatientData2 from './pages/SuperAdmin/PatientData2';
import NewAdmission from './pages/NewAdmission';
import Discharge from './pages/Discharge';
import DischargeList from './pages/DischargeList';
import Case from './pages/Case';
import Credit from './pages/Credit';
import Financial from './pages/Financial';
import Approve from './pages/SuperAdmin/Approve';
import ApproveModal from './pages/SuperAdmin/ApproveModal';
import RejectModal from './pages/SuperAdmin/RejectModal';
import RaiseModal from './pages/SuperAdmin/RaiseModal';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import AdmissionList from './pages/SuperAdmin/admissionList';
// import BasicLayout from './layout/BasicLayout';
// import AuthLayout from './layout/AuthLayout';

function App() {

  const notify = (type, message) => {
    if (type === 'success') {
      toast.success(message);
    }
    else {
      toast.error(message);
    }
  };
  // let currenUser = true
  return (
    <>

     { <Routes>
        <Route path='/login' element={<Login1 />} notify={notify} />
        <Route path="/" element={<PatientData />} notify={notify} />
        <Route path="/company" element={<CompanyPage />} notify={notify} />
        <Route path="/medical" element={<MedicalPage />} notify={notify} />
        <Route path='/insurance' element={<Insurance />} notify={notify} />
        <Route path='/new-admission' element={<NewAdmission />} notify={notify} />
        <Route path='/admissionList' element={<AdmissionList />} notify={notify} />
        <Route path='/case-information' element={<Case />} notify={notify} />
        <Route path="/credit" element={<Credit />} notify={notify} />
        <Route path="/financial" element={<Financial />} notify={notify} />
        <Route path='/dec' element={<Declaration />} notify={notify} />
        <Route path='/landing' element={<Landing />} notify={notify} />
        <Route path='/hospitalSignup' element={<Signup />} notify={notify} />
        <Route path='/document' element={<Document />} notify={notify} />
        <Route path='/facility' element={<Facility />} notify={notify} />
        <Route path='/personal-information/:id' element={<PersonalInformation />} notify={notify} />
        <Route path='/pi4' element={<Pi4 />} notify={notify} />
        <Route path='/popup2' element={<Popup2 />} notify={notify} />
        <Route path='/popup3' element={<Popup3 />} notify={notify} />
        <Route path='/popup4' element={<Popup4 />} notify={notify} />
        <Route path='/popup5' element={<Popup5 />} notify={notify} />
        <Route path='/dashboard' element={<Dashboard />} notify={notify} />
        <Route path='/discharge/:id' element={<Discharge />} notify={notify} />
        <Route path={'/dischargeList'} element={<DischargeList />} notify={notify} />

        <Route path='/patientData' element={<PatientData />} notify={notify} />
        <Route path='/patientData2/:id' element={<PatientData2 />} notify={notify} />
        <Route path='/superAdmin/popup' element={<SuperPop />} notify={notify} />
        <Route path='/superAdmin/approve' element={<Approve />} notify={notify} />
        <Route path='/superAdmin/approve/modal' element={<ApproveModal />} notify={notify} />
        <Route path='/superAdmin/approve/reject' element={<RejectModal />} notify={notify} />
        <Route path='/superAdmin/approve/raise' element={<RaiseModal />} notify={notify} />
      </Routes>}

      {/*{currenUser?<BasicLayout/>:<AuthLayout/>}*/}
    </>
  );
};

export default App;
