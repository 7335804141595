import React, { useContext, useRef, useState } from "react";
import SideBar from "../component/SideBar";
import enclouse from "../images/enclouse.png";
import DoneImg from "../images/done.svg";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/common/data";
import axios from "axios";
import EditIcon from "../images/edit-icon.svg";
import { AlertContainer } from "../component/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SidebarContext from "../context/sidebar/SidebarContext";
import Select from 'react-select';
import NavBar from "../component/NavBar";
import ConfirmationDialog from "../component/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

const Case = () => {
  const side = useContext(SidebarContext);
  const dispatch = useDispatch();
  const { policyDetails } = useSelector((state) => ({
    policyDetails: state.global.policyDetails,
  }));
  const { open, toggleOpen } = side;
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();
  const imageRef5 = useRef();
  const imageRef6 = useRef();
  const imageRef7 = useRef();
  const navigate = useNavigate();
  const toastId = React.useRef(null);

  const diagnoses = [
    'Heart Failure',
    'Pneumonia',
    'Myocardial Infarction (Heart Attack)',
    'Chronic Obstructive Pulmonary Disease (COPD)',
    'Stroke',
    'Diabetes Complications',
    'Kidney Failure',
    'Sepsis',
    'Cancer',
    'Gastrointestinal Bleeding',
    'Trauma and Injuries',
    'Chest Pain',
    'Asthma Exacerbation',
    'Liver Failure',
    'Hypertensive Crisis',
    'Acute Kidney Injury',
    'Seizures',
    'Cellulitis',
    'Pancreatitis',
    'Dehydration',
    'Syncope (Fainting)',
    'Cirrhosis',
    'Gallstones',
    'Meningitis',
    'Pelvic Inflammatory Disease (PID)',
    'Fractures',
    'Appendicitis',
    'Mental Health Emergencies',
    'Hemorrhagic Stroke',
    'Atrial Fibrillation',
    'Hyperglycemic Crisis (e.g., Diabetic Ketoacidosis)',
    'Pulmonary Embolism',
    'Peripheral Vascular Disease',
    'Deep Vein Thrombosis (DVT)',
    'Urinary Tract Infections (UTIs)',
    'Respiratory Failure',
    'Acute Coronary Syndrome',
    'Epileptic Seizures',
    'Rheumatoid Arthritis Flare',
    'Diverticulitis',
    'Multiple Organ Failure',
    'Osteomyelitis',
    'Acute Respiratory Distress Syndrome (ARDS)',
    'Thyroid Storm',
    'Hepatitis',
    'Peptic Ulcer Disease',
    'Cerebral Edema',
    'Hypovolemic Shock',
    'Bradyarrhythmias',
    'Hemorrhoids Complications',
    'Hypoglycemia',
    'Systemic Lupus Erythematosus (SLE) Flare',
    'Hyperkalemia',
    'Anaphylaxis',
    'Thrombocytopenia',
    'Alcohol Withdrawal',
    'Pleurisy',
    'Ectopic Pregnancy',
    'Hypothyroidism Crisis',
    'Celiac Crisis',
    'Migraine with Aura',
    'Neuroleptic Malignant Syndrome',
    'Pulmonary Hypertension',
    'Inflammatory Bowel Disease (IBD) Flare',
    'Hematuria',
    'Pericarditis',
    'Acute Glomerulonephritis',
    'Wound Infections',
    'Hyperthermia',
    'Sickle Cell Crisis',
    'Hypercalcemia',
    'Addisonian Crisis',
    'Cardiogenic Shock',
    'Renal Colic (Kidney Stones)',
    'Malignant Hyperthermia',
    'Postoperative Complications',
    'Toxic Megacolon',
    'Paralytic Ileus',
    'Hemophagocytic Lymphohistiocytosis (HLH)',
    'Hyponatremia',
    'Acute Cholecystitis',
    'Guillain-Barré Syndrome',
    'Hypopituitarism Crisis',
    'Hepatic Encephalopathy',
    'Hyperemesis Gravidarum',
    'Aortic Aneurysm',
    'Hyperlipidemia Complications',
    'Rhabdomyolysis',
    'Acute Intermittent Porphyria',
    'Tetanus',
    'Hantavirus Pulmonary Syndrome',
    'Necrotizing Fasciitis',
    'Cystic Fibrosis Exacerbation',
    'Lyme Disease Complications',
    'Reye Syndrome',
    'Toxic Epidermal Necrolysis (TEN)',
    'Acute Mountain Sickness',
    'Carbon Monoxide Poisoning',
    'Hypertensive Encephalopathy',
    'Waterhouse-Friderichsen Syndrome',
    'Stevens-Johnson Syndrome',
    'Superior Mesenteric Artery Syndrome',
    'Thyroiditis',
    'Wegener\'s Granulomatosis',
    'Thrombotic Thrombocytopenic Purpura (TTP)',
    'Myasthenia Gravis Crisis',
    'Acute Flaccid Myelitis (AFM)',
    'Autoimmune Hemolytic Anemia',
    'Polyarteritis Nodosa',
    'Hyperviscosity Syndrome',
    'Fungal Infections (systemic)',
    'Pulmonary Fibrosis',
    'Acute Liver Failure',
    'Typhoid Fever',
    'Variegate Porphyria',
    'Takotsubo Cardiomyopathy',
    'Huntington\'s Disease Complications',
    'Neurocysticercosis',
    'Lung Abscess',
    'Idiopathic Thrombocytopenic Purpura (ITP)',
    'Autoimmune Hepatitis',
    'Myelodysplastic Syndromes',
    'Recurrent Pregnancy Loss (RPL)',
    'Adrenal Crisis',
    'Brucellosis',
    'Leptospirosis',
    'Vasculitis',
    'Carcinoid Crisis',
    'Pulmonary Alveolar Proteinosis',
    'Ovarian Hyperstimulation Syndrome (OHSS)',
    'Hypersensitivity Pneumonitis',
    'Superior Vena Cava Syndrome',
    'Amniotic Fluid Embolism',
    'Peripartum Cardiomyopathy',
    'Kawasaki Disease Complications',
    'Tuberculosis',
    'HIV/AIDS Complications',
    'Chronic Pancreatitis',
    'Hereditary Angioedema',
    'Hemolytic Uremic Syndrome (HUS)',
    'Hemophagocytic Syndrome',
    'Cerebral Venous Sinus Thrombosis',
    'Lead Poisoning',
    'Eosinophilic Granulomatosis with Polyangiitis (EGPA)',
    'Parathyroid Crisis',
    'Mycoplasma Pneumonia',
    'Churg-Strauss Syndrome',
    'Whipple\'s Disease',
    'Ehrlichiosis',
    'Necrotizing Enterocolitis (NEC)',
    'Pulmonary Hypersensitivity Reaction',
    'Tricyclic Antidepressant Overdose',
    'Snake Envenomation',
    'Corticosteroid Withdrawal Syndrome',
    'Botulism',
    'Drug-induced Liver Injury',
    'Hemodialysis Complications',
    'Phaeochromocytoma Crisis',
    'Lupus Nephritis',
    'Nephrotic Syndrome',
    'Peritonitis',
    'Superior Mesenteric Vein Thrombosis',
    'HELLP Syndrome',
    'Osteonecrosis',
    'Thrombophlebitis',
    'Ependymoma',
    'Neuroblastoma',
    'Retinoblastoma',
    'Wilms Tumor',
    'Nasopharyngeal Carcinoma',
    'Chorioamnionitis',
    'Benign Prostatic Hyperplasia (BPH) Complications',
    'Testicular Torsion',
    'Torsades de Pointes',
    'Carcinomatous Meningitis',
    'Cholecystitis',
    'Cervical Spondylosis Complications',
    'Renal Artery Stenosis',
    'Pulmonary Arterial Hypertension',
    'Pleural Effusion',
    'Brugada Syndrome',
    'Hypercoagulable States',
    'Intracranial Hemorrhage',
    'Constrictive Pericarditis',
    'Pericardial Effusion',
    'Polycystic Ovary Syndrome (PCOS) Complications',
    'Dysrhythmias',
    'Chronic Migraine with Status Migrainosus',
    'Recurrent Corneal Erosion',
    'Vitamin B12 Deficiency Complications',
    'Gamma-Hydroxybutyrate (GHB) Overdose',
    'Opioid Overdose',
    'Organophosphate Poisoning',
    'Bites and Stings (e.g., Snakebites, Spider Bites)',
    'Barotrauma',
    'Mallory-Weiss Tear',
    'Acute Angle-Closure Glaucoma',
    'Iritis',
    'Foreign Body Aspiration',
    'Sudden Sensorineural Hearing Loss',
    'Mastoiditis',
    'Bacterial Meningitis',
    'Spinal Cord Injury',
    'Aortic Dissection',
    'Thoracic Aortic Aneurysm',
    'Pulmonary Sarcoidosis',
    'Cystitis',
    'Urethritis',
    'Ovarian Torsion',
    'Orbital Cellulitis',
    'Hypertensive Urgency',
    'Central Retinal Artery Occlusion (CRAO)',
    'Hemorrhagic Ovarian Cyst',
    'Pulmonary Sequestration',
    'Osteosarcoma',
    'Rhabdoid Tumor',
    'Meningioma',
    'Thymoma',
    'Pleuropulmonary Blastoma',
    'Mesothelioma',
    'Chondrosarcoma',
    'Oligodendroglioma',
    'Hodgkin\'s Lymphoma',
    'Non-Hodgkin Lymphoma',
    'Mycosis Fungoides',
    'Acute Lymphoblastic Leukemia (ALL)',
    'Chronic Lymphocytic Leukemia (CLL)',
    'Hairy Cell Leukemia',
    'Multiple Myeloma',
    'Amyloidosis',
    'Fibromyalgia',
    'Chronic Fatigue Syndrome',
    'Complex Regional Pain Syndrome (CRPS)',
    'Hemangioma',
    'Dermatomyositis',
    'Polymyositis',
    'Morphea',
    'Cutaneous T-Cell Lymphoma',
    'Castleman Disease',
    'Familial Mediterranean Fever',
    'Hemochromatosis',
    'Wilson\'s Disease',
    'Porphyria Cutanea Tarda',
    'Gaucher Disease',
    'Fabry Disease',
    'Scleroderma',
    'Systemic Sclerosis',
    'Mixed Connective Tissue Disease (MCTD)',
    'Granulomatosis with Polyangiitis (GPA)',
    'Microscopic Polyangiitis (MPA)',
    'Eosinophilic Esophagitis',
    'Gastroenteritis',
    'Peptic Ulcer Perforation',
    'Reactive Arthritis',
    'Postherpetic Neuralgia',
    'Herpes Zoster Ophthalmicus',
    'Aseptic Meningitis',
    'Tetralogy of Fallot',
    'Coarctation of the Aorta',
    'Ebstein Anomaly',
    'Truncus Arteriosus',
    'Transposition of the Great Arteries',
    'Persistent Pulmonary Hypertension of the Newborn (PPHN)',
    'Meconium Aspiration Syndrome',
    'Neonatal Sepsis',
    'Neonatal Jaundice',
    'Respiratory Distress Syndrome (RDS)',
    'Necrotizing Enterocolitis (NEC) in Neonates',
    'Neonatal Hypoglycemia',
    'Congenital Diaphragmatic Hernia (CDH)',
    'Esophageal Atresia',
    'Tracheoesophageal Fistula (TEF)',
    'Gastroschisis',
    'Omphalocele',
    'Hirschsprung Disease',
    'Intestinal Malrotation',
    'Neural Tube Defects (e.g., Spina Bifida)',
    'Congenital Heart Defects',
    'Down Syndrome Complications',
    'Turner Syndrome Complications',
    'Klinefelter Syndrome Complications',
    'Marfan Syndrome Complications',
    'Ehlers-Danlos Syndrome Complications',
    'Williams Syndrome Complications',
    'Prader-Willi Syndrome Complications',
    'Angelman Syndrome Complications',
    'Cri-du-Chat Syndrome Complications',
    'Fragile X Syndrome Complications',
    'Tourette Syndrome Complications',
    'Rett Syndrome Complications',
    'Autism Spectrum Disorder Complications',
    'Attention-Deficit/Hyperactivity Disorder (ADHD) Complications',
    'Oppositional Defiant Disorder (ODD) Complications',
    'Conduct Disorder Complications',
    'Intellectual Disability',
    'Cerebral Palsy Complications',
    'Muscular Dystrophy Complications',
    'Spinal Muscular Atrophy (SMA) Complications',
    'Huntington\'s Disease Complications',
    'Friedreich\'s Ataxia Complications',
    'Myasthenia Gravis Complications',
    'Amyotrophic Lateral Sclerosis (ALS) Complications',
    'Parkinson\'s Disease Complications',
    'Alzheimer\'s Disease Complications',
    'Vascular Dementia Complications',
    'Frontotemporal Dementia Complications',
    'Lewy Body Dementia Complications',
    'Creutzfeldt-Jakob Disease Complications',
    'Guillain-Barré Syndrome Complications',
    'Chronic Inflammatory Demyelinating Polyneuropathy (CIDP) Complications',
    'Peripheral Neuropathy Complications',
    'Epilepsy Complications',
    'Migraine Complications',
    'Cluster Headache Complications',
    'Trigeminal Neuralgia Complications',
    'Tension-Type Headache Complications',
    'Chronic Kidney Disease (CKD) Complications',
    'Hemodialysis Complications',
    'Peritoneal Dialysis Complications',
    'Renal Transplant Complications',
    'Chronic Obstructive Pulmonary Disease (COPD) Complications',
    'Pulmonary Hypertension Complications',
    'Asthma Complications',
    'Interstitial Lung Disease Complications',
    'Cystic Fibrosis Complications',
    'Bronchiectasis Complications',
    'Gastroesophageal Reflux Disease (GERD) Complications',
    'Peptic Ulcer Disease Complications',
    'Inflammatory Bowel Disease (IBD) Complications',
    'Irritable Bowel Syndrome (IBS) Complications',
    'Celiac Disease Complications',
    'Diverticulitis Complications',
    'Cholelithiasis Complications',
    'Pancreatitis Complications',
    'Diabetes Complications',
    'Hypoglycemia Complications',
    'Hyperglycemia Complications',
    'Diabetic Ketoacidosis (DKA) Complications',
    'Hyperosmolar Hyperglycemic State (HHS) Complications',
    'Thyroid Disorders (e.g., Hypothyroidism, Hyperthyroidism) Complications',
    'Adrenal Insufficiency Complications',
    'Polycystic Ovary Syndrome (PCOS) Complications',
    'Endometriosis Complications',
    'Pelvic Inflammatory Disease (PID) Complications',
    'Uterine Fibroids Complications',
    'Ovarian Cysts Complications',
    'Sexually Transmitted Infections (STIs) Complications',
    'Human Immunodeficiency Virus (HIV) Complications',
    'Acquired Immunodeficiency Syndrome (AIDS) Complications',
    'Tuberculosis (TB) Complications',
    'Hepatitis Complications',
    'Cirrhosis Complications',
    'Pancreatic Cancer Complications',
    'Gastric Cancer Complications',
    'Colorectal Cancer Complications',
    'Esophageal Cancer Complications',
    'Liver Cancer Complications',
    'Lung Cancer Complications',
    'Breast Cancer Complications',
    'Ovarian Cancer Complications',
    'Cervical Cancer Complications',
    'Prostate Cancer Complications',
    'Bladder Cancer Complications',
    'Renal Cell Carcinoma Complications',
    'Testicular Cancer Complications',
    'Skin Cancer Complications',
    'Leukemia Complications',
    'Lymphoma Complications',
    'Multiple Myeloma Complications',
    'Sickle Cell Disease Complications',
    'Thalassemia Complications',
    'Hemophilia Complications',
    'Von Willebrand Disease Complications',
    'Aplastic Anemia Complications',
    'Iron-Deficiency Anemia Complications',
    'Vitamin B12 Deficiency Complications',
    'Folate Deficiency Complications',
    'Hemochromatosis Complications',
    'Wilson Disease Complications',
    'Thrombocytopenia Complications',
    'Deep Vein Thrombosis (DVT) Complications',
    'Pulmonary Embolism Complications',
    'Hypertension Complications',
    'Atherosclerosis Complications',
    'Coronary Artery Disease (CAD) Complications',
    'Myocardial Infarction Complications',
    'Heart Failure Complications',
    'Arrhythmias (e.g., Atrial Fibrillation, Ventricular Tachycardia) Complications',
    'Cardiomyopathy Complications',
    'Valvular Heart Disease Complications',
    'Aortic Aneurysm Complications',
    'Peripheral Arterial Disease (PAD) Complications',
    'Venous Insufficiency Complications',
    'Varicose Veins Complications',
    'Pulmonary Hypertension Complications',
    'Rheumatoid Arthritis Complications',
    'Systemic Lupus Erythematosus (SLE) Complications',
    'Sjögren\'s Syndrome Complications',
    'Ankylosing Spondylitis Complications',
    'Psoriatic Arthritis Complications',
    'Gout Complications',
    'Osteoarthritis Complications',
    'Fibromyalgia Complications',
    'Sarcoidosis Complications',
    'Amyloidosis Complications',
    'Hemochromatosis Complications',
    'Paget\'s Disease of Bone Complications',
    'Osteoporosis Complications',
    'Bone Tumors (e.g., Osteosarcoma) Complications',
    'Carpal Tunnel Syndrome Complications',
    'Rheumatic Fever Complications',
    'Herniated Disc Complications',
    'Spinal Stenosis Complications',
    'Sciatica Complications',
    'Vertebral Compression Fracture Complications',
    'Cauda Equina Syndrome Complications',
    'Epilepsy Complications',
    'Migraine Complications',
    'Cluster Headache Complications',
    'Tension-Type Headache Complications',
    'Chronic Kidney Disease (CKD) Complications',
    'Hemodialysis Complications',
    'Peritoneal Dialysis Complications',
    'Renal Transplant Complications',
    'Chronic Obstructive Pulmonary Disease (COPD) Complications',
    'Pulmonary Hypertension Complications',
    'Asthma Complications',
    'Interstitial Lung Disease Complications',
    'Cystic Fibrosis Complications',
    'Bronchiectasis Complications',
    'Gastroesophageal Reflux Disease (GERD) Complications',
    'Peptic Ulcer Disease Complications',
    'Inflammatory Bowel Disease (IBD) Complications',
    'Irritable Bowel Syndrome (IBS) Complications',
    'Celiac Disease Complications',
    'Diverticulitis Complications',
    'Cholelithiasis Complications',
    'Pancreatitis Complications',
    'Diabetes Complications',
    'Hypoglycemia Complications',
    'Hyperglycemia Complications',
    'Diabetic Ketoacidosis (DKA) Complications',
    'Hyperosmolar Hyperglycemic State (HHS) Complications',
    'Thyroid Disorders (e.g., Hypothyroidism, Hyperthyroidism) Complications',
    'Adrenal Insufficiency Complications',
    'Polycystic Ovary Syndrome (PCOS) Complications',
    'Endometriosis Complications',
    'Pelvic Inflammatory Disease (PID) Complications',
    'Uterine Fibroids Complications',
    'Ovarian Cysts Complications',
    'Sexually Transmitted Infections (STIs) Complications',
    'Human Immunodeficiency Virus (HIV) Complications',
    'Acquired Immunodeficiency Syndrome (AIDS) Complications',
    'Tuberculosis (TB) Complications',
    'Hepatitis Complications',
    'Cirrhosis Complications',
    'Pancreatic Cancer Complications',
    'Gastric Cancer Complications',
    'Colorectal Cancer Complications',
    'Esophageal Cancer Complications',
    'Liver Cancer Complications',
    'Lung Cancer Complications',
    'Breast Cancer Complications',
    'Ovarian Cancer Complications',
    'Cervical Cancer Complications',
    'Prostate Cancer Complications',
    'Bladder Cancer Complications',
    'Renal Cell Carcinoma Complications',
    'Testicular Cancer Complications',
    'Skin Cancer Complications',
    'Leukemia Complications',
    'Lymphoma Complications',
    'Multiple Myeloma Complications',
    'Sickle Cell Disease Complications',
    'Thalassemia Complications',
    'Hemophilia Complications',
    'Von Willebrand Disease Complications',
    'Aplastic Anemia Complications',
    'Iron-Deficiency Anemia Complications',
    'Vitamin B12 Deficiency Complications',
    'Folate Deficiency Complications',
    'Hemochromatosis Complications',
    'Wilson Disease Complications',
    'Thrombocytopenia Complications',
    'Deep Vein Thrombosis (DVT) Complications',
    'Pulmonary Embolism Complications',
    'Hypertension Complications',
    'Atherosclerosis Complications',
    'Coronary Artery Disease (CAD) Complications',
    'Myocardial Infarction Complications',
    'Heart Failure Complications',
    'Arrhythmias (e.g., Atrial Fibrillation, Ventricular Tachycardia) Complications',
    'Cardiomyopathy Complications',
    'Valvular Heart Disease Complications',
    'Aortic Aneurysm Complications',
    'Peripheral Arterial Disease (PAD) Complications',
    'Venous Insufficiency Complications',
    'Varicose Veins Complications',
    'Pulmonary Hypertension Complications',
    'Rheumatoid Arthritis Complications',
    'Systemic Lupus Erythematosus (SLE) Complications',
    'Sjögren\'s Syndrome Complications',
    'Ankylosing Spondylitis Complications',
    'Psoriatic Arthritis Complications',
    'Gout Complications',
    'Osteoarthritis Complications',
    'Fibromyalgia Complications',
    'Sarcoidosis Complications',
    'Amyloidosis Complications',
    'Paget\'s Disease of Bone Complications',
    'Osteoporosis Complications',
    'Bone Tumors (e.g., Osteosarcoma) Complications',
    'Carpal Tunnel Syndrome Complications',
    'Rheumatic Fever Complications',
    'Herniated Disc Complications',
    'Spinal Stenosis Complications',
    'Sciatica Complications',
    'Vertebral Compression Fracture Complications',
    'Cauda Equina Syndrome Complications',
    'Epilepsy Complications',
    'Migraine Complications',
    'Cluster Headache Complications',
    'Tension-Type Headache Complications',
    'Chronic Kidney Disease (CKD) Complications',
    'Hemodialysis Complications',
    'Peritoneal Dialysis Complications',
    'Renal Transplant Complications',
    'Chronic Obstructive Pulmonary Disease (COPD) Complications',
    'Pulmonary Hypertension Complications',
    'Asthma Complications',
    'Interstitial Lung Disease Complications',
    'Cystic Fibrosis Complications',
    'Bronchiectasis Complications',
    'Gastroesophageal Reflux Disease (GERD) Complications',
    'Peptic Ulcer Disease Complications',
    'Inflammatory Bowel Disease (IBD) Complications',
    'Irritable Bowel Syndrome (IBS) Complications',
    'Celiac Disease Complications',
    'Diverticulitis Complications',
    'Cholelithiasis Complications',
    'Pancreatitis Complications',
    'Diabetes Complications',
    'Hypoglycemia Complications',
    'Hyperglycemia Complications',
    'Diabetic Ketoacidosis (DKA) Complications',
    'Hyperosmolar Hyperglycemic State (HHS) Complications',
    'Thyroid Disorders (e.g., Hypothyroidism, Hyperthyroidism) Complications',
    'Adrenal Insufficiency Complications',
    'Polycystic Ovary Syndrome (PCOS) Complications',
    'Endometriosis Complications',
    'Pelvic Inflammatory Disease (PID) Complications',
    'Uterine Fibroids Complications',
    'Ovarian Cysts Complications',
    'Sexually Transmitted Infections (STIs) Complications',
    'Human Immunodeficiency Virus (HIV) Complications',
    'Acquired Immunodeficiency Syndrome (AIDS) Complications',
    'Tuberculosis (TB) Complications',
    'Hepatitis Complications',
    'Cirrhosis Complications',
    'Pancreatic Cancer Complications',
    'Gastric Cancer Complications',
    'Colorectal Cancer Complications',
    'Esophageal Cancer Complications',
    'Liver Cancer Complications',
    'Lung Cancer Complications',
    'Breast Cancer Complications',
    'Ovarian Cancer Complications',
    'Cervical Cancer Complications',
    'Prostate Cancer Complications',
    'Bladder Cancer Complications',
    'Renal Cell Carcinoma Complications',
    'Testicular Cancer Complications',
    'Skin Cancer Complications',
    'Leukemia Complications',
    'Lymphoma Complications',
    'Multiple Myeloma Complications',
    'Sickle Cell Disease Complications',
    'Thalassemia Complications',
    'Hemophilia Complications',
    'Von Willebrand Disease Complications',
    'Aplastic Anemia Complications',
    'Iron-Deficiency Anemia Complications',
    'Vitamin B12 Deficiency Complications',
    'Folate Deficiency Complications',
    'Hemochromatosis Complications',
    'Wilson Disease Complications',
    'Thrombocytopenia Complications',
    'Deep Vein Thrombosis (DVT) Complications',
    'Pulmonary Embolism Complications',
    'Hypertension Complications',
    'Atherosclerosis Complications',
    'Coronary Artery Disease (CAD) Complications',
    'Myocardial Infarction Complications',
    'Heart Failure Complications',
    'Arrhythmias (e.g., Atrial Fibrillation, Ventricular Tachycardia) Complications',
    'Cardiomyopathy Complications',
    'Valvular Heart Disease Complications',
    'Aortic Aneurysm Complications',
    'Peripheral Arterial Disease (PAD) Complications',
    'Venous Insufficiency Complications',
    'Varicose Veins Complications',
    'Pulmonary Hypertension Complications',
    'Rheumatoid Arthritis Complications',
    'Systemic Lupus Erythematosus (SLE) Complications',
    'Sjögren\'s Syndrome Complications',
    'Ankylosing Spondylitis Complications',
    'Psoriatic Arthritis Complications',
    'Gout Complications',
    'Osteoarthritis Complications',
    'Fibromyalgia Complications',
    'Sarcoidosis Complications',
    'Amyloidosis Complications',
    'Paget\'s Disease of Bone Complications',
    'Osteoporosis Complications',
    'Bone Tumors (e.g., Osteosarcoma) Complications',
    'Carpal Tunnel Syndrome Complications',
    'Rheumatic Fever Complications',
    'Herniated Disc Complications',
    'Spinal Stenosis Complications',
    'Sciatica Complications',
    'Vertebral Compression Fracture Complications',
    'Cauda Equina Syndrome Complications',
    'Epilepsy Complications',
    'Migraine Complications',
    'Cluster Headache Complications',
    'Tension-Type Headache Complications',
    'Chronic Kidney Disease (CKD) Complications',
    'Hemodialysis Complications',
    'Peritoneal Dialysis Complications',
    'Renal Transplant Complications',
    'Chronic Obstructive Pulmonary Disease (COPD) Complications',
    'Pulmonary Hypertension Complications',
    'Asthma Complications',
    'Interstitial Lung Disease Complications',
    'Cystic Fibrosis Complications',
    'Bronchiectasis Complications',
    'Gastroesophageal Reflux Disease (GERD) Complications',
    'Peptic Ulcer Disease Complications',
    'Inflammatory Bowel Disease (IBD) Complications',
    'Irritable Bowel Syndrome (IBS) Complications',
    'Celiac Disease Complications',
    'Diverticulitis Complications',
    'Cholelithiasis Complications',
    'Pancreatitis Complications',
    'Diabetes Complications',
    'Hypoglycemia Complications',
    'Hyperglycemia Complications',
    'Diabetic Ketoacidosis (DKA) Complications',
    'Hyperosmolar Hyperglycemic State (HHS) Complications',
    'Thyroid Disorders (e.g., Hypothyroidism, Hyperthyroidism) Complications',
    'Adrenal Insufficiency Complications',
    'Polycystic Ovary Syndrome (PCOS) Complications',
    'Endometriosis Complications',
    'Pelvic Inflammatory Disease (PID) Complications',
    'Uterine Fibroids Complications',
    'Ovarian Cysts Complications',
    'Sexually Transmitted Infections (STIs) Complications',
    'Human Immunodeficiency Virus (HIV) Complications',
    'Acquired Immunodeficiency Syndrome (AIDS) Complications',
    'Tuberculosis (TB) Complications',
    'Hepatitis Complications',
    'Cirrhosis Complications',
    'Pancreatic Cancer Complications',
    'Gastric Cancer Complications',
    'Colorectal Cancer Complications',
    'Esophageal Cancer Complications',
    'Liver Cancer Complications',
    'Lung Cancer Complications',
    'Breast Cancer Complications',
    'Ovarian Cancer Complications',
    'Cervical Cancer Complications',
    'Prostate Cancer Complications',
    'Bladder Cancer Complications',
    'Renal Cell Carcinoma Complications',
    'Testicular Cancer Complications',
    'Skin Cancer Complications',
    'Leukemia Complications',
    'Lymphoma Complications',
    'Multiple Myeloma Complications',
    'Sickle Cell Disease Complications',
    'Thalassemia Complications',
    'Hemophilia Complications',
    'Von Willebrand Disease Complications',
    'Aplastic Anemia Complications',
    'Iron-Deficiency Anemia Complications',
    'Vitamin B12 Deficiency Complications',
    'Folate Deficiency Complications',
    'Hemochromatosis Complications',
    'Wilson Disease Complications',
    'Thrombocytopenia Complications',
    'Deep Vein Thrombosis (DVT) Complications',
    'Pulmonary Embolism Complications',
    'Hypertension Complications',
    'Atherosclerosis Complications',
    'Coronary Artery Disease (CAD) Complications',
    'Myocardial Infarction Complications',
    'Heart Failure Complications',
    'Arrhythmias (e.g., Atrial Fibrillation, Ventricular Tachycardia) Complications',
    'Cardiomyopathy Complications',
    'Valvular Heart Disease Complications',
    'Aortic Aneurysm Complications',
    'Peripheral Arterial Disease (PAD) Complications',
    'Venous Insufficiency Complications',
    'Varicose Veins Complications',
    'Pulmonary Hypertension Complications',
    'Rheumatoid Arthritis Complications',
    'Systemic Lupus Erythematosus (SLE) Complications',
    'Sjögren\'s Syndrome Complications',
    'Ankylosing Spondylitis Complications',
    'Psoriatic Arthritis Complications',
    'Gout Complications',
    'Osteoarthritis Complications',
    'Fibromyalgia Complications',
    'Sarcoidosis Complications',
    'Amyloidosis Complications',
    'Paget\'s Disease of Bone Complications',
    'Osteoporosis Complications',
    'Bone Tumors (e.g., Osteosarcoma) Complications',
    'Carpal Tunnel Syndrome Complications',
    'Rheumatic Fever Complications',
    'Herniated Disc Complications',
    'Spinal Stenosis Complications',
    'Sciatica Complications',
    'Vertebral Compression Fracture Complications',
    'Cauda Equina Syndrome Complications',
    'Epilepsy Complications',
    'Migraine Complications',
    'Cluster Headache Complications',
    'Tension-Type Headache Complications',
    'Chronic Kidney Disease (CKD) Complications',
    'Hemodialysis Complications',
    'Peritoneal Dialysis Complications',
    'Renal Transplant Complications',
    'Chronic Obstructive Pulmonary Disease (COPD) Complications',
    'Pulmonary Hypertension Complications',
    'Asthma Complications',
    'Interstitial Lung Disease Complications',
    'Cystic Fibrosis Complications',
    'Bronchiectasis Complications',
    'Gastroesophageal Reflux Disease (GERD) Complications',
    'Peptic Ulcer Disease Complications',
    'Inflammatory Bowel Disease (IBD) Complications',
    'Irritable Bowel Syndrome (IBS) Complications',
    'Celiac Disease Complications',
    'Diverticulitis Complications',
    'Cholelithiasis Complications',
    'Pancreatitis Complications',
    'Diabetes Complications',
    'Hypoglycemia Complications',
    'Hyperglycemia Complications',
    'Diabetic Ketoacidosis (DKA) Complications',
    'Hyperosmolar Hyperglycemic State (HHS) Complications',
    'Thyroid Disorders (e.g., Hypothyroidism, Hyperthyroidism) Complications',
    'Adrenal Insufficiency Complications',
    'Polycystic Ovary Syndrome (PCOS) Complications',
    'Endometriosis Complications',
    'Pelvic Inflammatory Disease (PID) Complications',
    'Uterine Fibroids Complications',
    'Ovarian Cysts Complications',
    'Sexually Transmitted Infections (STIs) Complications',
    'Human Immunodeficiency Virus (HIV) Complications',
    'Acquired Immunodeficiency Syndrome (AIDS) Complications',
    'Tuberculosis (TB) Complications',
    'Hepatitis Complications',
    'Cirrhosis Complications',
    'Pancreatic Cancer Complications',
    'Gastric Cancer Complications',
    'Colorectal Cancer Complications',
    'Esophageal Cancer Complications',
    'Liver Cancer Complications',
    'Lung Cancer Complications',
    'Breast Cancer Complications',
    'Ovarian Cancer Complications',
    'Cervical Cancer Complications',
    'Prostate Cancer Complications',
    'Bladder Cancer Complications',
    'Renal Cell Carcinoma Complications',
    'Testicular Cancer Complications',
    'Skin Cancer Complications',
    'Leukemia Complications',
    'Lymphoma Complications',
    'Multiple Myeloma Complications',
    'Sickle Cell Disease Complications',
    'Thalassemia Complications',
    'Hemophilia Complications',
    'Von Willebrand Disease Complications',
    'Aplastic Anemia Complications',
    'Iron-Deficiency Anemia Complications',
    'Vitamin B12 Deficiency Complications',
    'Folate Deficiency Complications',
    'Hemochromatosis Complications',
    'Wilson Disease Complications',
    'Thrombocytopenia Complications',
    'Deep Vein Thrombosis (DVT) Complications',
    'Pulmonary Embolism Complications',
    'Hypertension Complications',
    'Atherosclerosis Complications',
    'Coronary Artery Disease (CAD) Complications',
    'Myocardial Infarction Complications',
    'Heart Failure Complications'
  ];

  const options = diagnoses.map(diagnosis => {
    const value = diagnosis.toLowerCase().replace(/\s/g, '_');
    return { value, label: diagnosis };
  });

  const initialValue = {
    accident: "",
    doctor: "",
    dc_name: "",
    diagnosis: "",
    admission: "",
    date: "",
    r_dc_name: "",
    r_date: "",
    d_name: "",
    d_diagnosis: "",
    d_admission: "",
    d_date: "",
    p_name: "",
    p_insured: "",
    p_sDate: "",
    p_eDate: "",
    y_date: "",
    y_policy: "",
    y_sum: "",
    y_rent: "",
    i_policy_name: "",
    i_policy_uin: "",
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    image6: null,
  };
  const [formData, setFormData] = useState(initialValue);
  const [showPreview, setShowPreview] = useState(null);
  const [loader, setLoader] = useState(false);
  const [policyDetails2, setPolicyDetails2] = useState({ policyUin: '', policyName: '' });
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const aadharCard = JSON.parse(localStorage.getItem("aadharCard"));

  const handleImage = async (e, img, type) => {
    const objectUrl = URL.createObjectURL(e.target?.files[0]);

    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });

    var formDataUpdated = new FormData();
    formDataUpdated.append("aadharCardFront", e.target?.files[0]);

    formDataUpdated.append("docType", type);

    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/imageToText",
        data: formDataUpdated,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        console.log("should be called");
        setLoader(false);
      }
      const resDate = res.data.data;
      const raw = resDate.raw;
      let errorInFinding = "";
      if (img === "image1") {
        if (resDate.doctorName) {
          formData.dc_name = resDate.doctorName;
        } else {
          errorInFinding += "Doctor Name, ";
        }
        if (resDate.diagnosis) {
          formData.diagnosis = resDate.diagnosis;
        } else {
          errorInFinding += "Diagnosis, ";
        }
        if (resDate.treatment) {
          formData.admission = resDate.treatment;
        } else {
          errorInFinding += "Treatment, ";
        }
        if (resDate.date) {
          formData.date = resDate.date;
        } else {
          errorInFinding += "Date, ";
        }

        if (
          raw.toLowerCase().includes("adv") ||
          raw.toLowerCase().includes("admission") ||
          raw.toLowerCase().includes("admit") ||
          raw.toLowerCase().includes("admissive") ||
          raw.toLowerCase().includes("admissions") ||
          raw.toLowerCase().includes("emergency") ||
          raw.toLowerCase().includes("plan")
        ) {
          if (errorInFinding) {
            toast.warn(
              "Admission confirmed but unable to identify " + errorInFinding + "please fill these details manually",
              {
                autoClose: 3000
              });
          }
        } else {
          toast.error(
            "Unable to confirm if admission advised also unable to find " + errorInFinding + "is advised please fill details manually and it will reviewd later!"
          );
        }
      }
      if (img === "image3") {
        if (resDate.doctorName) {
          formData.r_dc_name = resDate.doctorName;
        } else {
          errorInFinding += "Doctor Name, ";
        }

        if (resDate.date) {
          formData.r_date = resDate.date;
        } else {
          errorInFinding += "Date, ";
        }

        if (errorInFinding) {
          toast.warn(
            "Unable to identify " + errorInFinding + "please fill these details manually",
            {
              autoClose: 3000
            });
        }
      }

    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (e?.response?.status === 400) {
        setLoader(false);
      }
      if (e?.response?.status === 500) {
        setLoader(false);
      }
    }
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };

  const handlePDF = async (e, img, type) => {
    toastId.current = toast.loading("Uploading");
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    console.log(objectUrl);
    var formDataUpdated = new FormData();
    formDataUpdated.append("update pdf", e.target.files[0]);

    // Log the value of the formDataUpdated object
    console.log(formDataUpdated);

    // try {
    const res = await axios({
      method: "post",
      url: baseUrl + "/pdfToText",
      data: formDataUpdated,
      headers: {
        "Content-Type": "multipart/form-data",
        //"Accept":"application/json, text/plain, */*"
      },
    });

    // toast.dismiss(toastId.current)
    // Log the value of the res object
    console.log(res);

    if (res.status === 200) {
      const pdfText = res.data.data2;
      localStorage.setItem(type, pdfText);
      if (type === "cover") {
        console.log(aadharCard);
        if (pdfText?.toLowerCase().includes(aadharCard?.name?.toLowerCase())) {
          console.log("name exist in policy");
          const req = {
            prompt: `${pdfText}
                from this text I want 6 thing as json
                1. Age of ${aadharCard.name} (key: age)
                2. Sum Insured (key: sumInsured)
                3. Policy Inception Date for ${aadharCard.name} (key: policyInceptionDate) (format: DD/MM/YYY)
                4. Policy End Date for ${aadharCard.name} (key: policyEndDate)
                5. Policy UIN or Unique ID (key: policyUin)
                6. Policy Name (key: policyName)
    
                Don't want any explanation or anything extra than json response and use the key mentioned for json object and date format should be DD/MM/YYYY`
          }
          const res2 = await axios({
            method: "post",
            url: baseUrl + "/ai",
            data: req,
            headers: {
              "Content-Type": "multipart/form-data",
              //"Accept":"application/json, text/plain, */*"
            },
          });
          toast.dismiss(toastId.current);
          console.log(res2.data);

          if (typeof res2.data === 'object') {
            setPolicyDetails(res2.data)
            dispatch({ type: 'SET_POLICY_DETAILS', payload: JSON.stringify(res2.data) })
            localStorage.setItem("policyDetails", JSON.stringify(res2.data));
            console.log(localStorage.getItem("policyDetails"));

          } else {
            try {
              const jsonResponse = JSON.parse(res2.data);

              localStorage.setItem("policyDetails", JSON.stringify(jsonResponse));
              console.log(localStorage.getItem("policyDetails"));
              dispatch({ type: 'SET_POLICY_DETAILS', payload: JSON.stringify(jsonResponse) })

              setPolicyDetails(jsonResponse)
              console.log(jsonResponse);
            } catch (error) {
              console.log('Error in parsing', error);
              const regexMatch = res2.data.match(/\{[\s\S]*\}/);
              console.log(regexMatch);
              if (regexMatch && regexMatch[0]) {
                const extractedJson = regexMatch[0];
                try {
                  const jsonResponse = JSON.parse(extractedJson);
                  console.log(jsonResponse);
                  localStorage.setItem("policyDetails", JSON.stringify(jsonResponse));
                  console.log(localStorage.getItem("policyDetails"));
                  setPolicyDetails(jsonResponse)
                } catch (jsonError) {
                  console.error("Error parsing extracted JSON:", jsonError);
                }
              } else {
                console.error("Unable to extract valid JSON from the response");
              }
            }
          }
        } else {
          toast.dismiss(toastId.current);
          toast.error("Policy didn't matched with Aadhar Name check again!")
        }
      }
      if (type === "policy") {
        toast.dismiss(toastId.current);
        if (pdfText.toLowerCase().includes(policyDetails2.policyUin.toLocaleLowerCase())) {
          console.log(`policy matched with UIN ${policyDetails2.policyUin} ${policyDetails2.policyName}--`); //PolicyUIN and policy name is null here
          formData.i_policy_uin = policyDetails2.policyUin
          formData.i_policy_name = policyDetails2.policyName
          console.log(formData);
          // formData.i_policy_uin = policyUin
          // formData.i_policy_name = policyName
        } else {
          toast.warn("Policy Wording UIN not matched with Policy Document please check again!")
        }
      }
    }

    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };

  function setPolicyDetails(details) {
    formData.p_name = aadharCard.name
    formData.p_sDate = details.policyInceptionDate;
    formData.p_eDate = details.policyEndDate;
    formData.p_insured = details.sumInsured;
    policyDetails2.policyUin = details.policyUin;
    policyDetails2.policyName = details.policyName;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    const {
      image1,
      image2,
      accident,
      doctor,
      image3,
      image4,
      image5,
      image6,
    } = formData;
    if (image1 === null && toast.error("Prescription  image is Required!"))
      return;
    if (
      accident === "" &&
      toast.error("Is the cause of admission any accident? is Required!")
    )
      return;
    if (formData.accident === "yes") {
      if (image2 === null && toast.error("FIR / MLC image is Required!"))
        return;
    }
    if (
      doctor === "" &&
      toast.error("Before coming for this hospital is Required!")
    )
      return;
    if (image3 === null && toast.error("Diagnostic reports image is Required!"))
      return;
    if (formData.accident === "yes") {
      if (image4 === null && toast.error("other documents image is Required!"))
        return;
    }
    if (
      image5 === null &&
      toast.error("Policy holder’s latest insurance policy image is Required!")
    )
      return;
    if (formData.accident === "yes") {
      if (
        image6 === null &&
        toast.error("Policy documents for previous 2 years image is Required!")
      )
        return;
    }

    // if (formData.r_date === "" && toast.error("Dignostic Date is Required!")) return;
    // if (formData.r_dc_name === "" && toast.error("Dignostic Doctors Name is Required!")) return;
    // if (formData.date === "" && toast.error("Date is Required!")) return;
    // if (formData.diagnosis === "" && toast.error("Diagnosis is Required!")) return;
    // if (formData.dc_name === "" && toast.error("Doctors Name is Required!")) return;
    // // if (formData.admission === "" && toast.error("Admission Prescribed For disease/condition is Required!")) return;
    // if (formData.accident === "" && toast.error("Is the cause of admission any accident? is Required!")) return;
    // if (formData.p_name === "" && toast.error("Policy holder’s name is Required!")) return;
    // if (formData.p_insured === "" && toast.error("Sum Insured is Required!")) return;
    // if (formData.p_sDate === "" && toast.error("Policy Inception Date is Required!")) return;
    // if (formData.p_eDate === "" && toast.error("Policy End Date is Required!")) return;
    // if (formData.policyUin === "" && toast.error("Policy UIN is Required!")) return;
    // if (formData.policyName === "" && toast.error("Policy Name is Required!")) return;

    var formDataUpdated = new FormData();
    formDataUpdated.append("_id", localStorage.getItem("PatientId"));
    formDataUpdated.append("prescriptionImg", formData.image1);
    if (formData.accident === "yes") {
      formDataUpdated.append("diagnosticReportImg", formData.image2);
    }
    formDataUpdated.append("isAccident", formData.accident || "");
    formDataUpdated.append("firImg", formData.image3);
    formDataUpdated.append("isVisitOtherDoct", formData.doctor || "");
    if (formData.accident === "yes") {
      formDataUpdated.append("otherDocImg", formData.image4);
    }
    formDataUpdated.append("latestPolicyImg", formData.image5);
    if (formData.accident === "yes") {
      formDataUpdated.append("policyDocImg", formData.image6);
    }
    formDataUpdated.append("prescriptionData", JSON.stringify({ doctorName: formData?.dc_name, diagnosis: formData?.diagnosis, admissionpresc: formData?.admission, date: formData?.date }) || {})
    formDataUpdated.append("diagnosticReportData", JSON.stringify({ doctorName: formData?.r_dc_name, date: formData?.r_date }) || {})
    formDataUpdated.append("otherDocData", JSON.stringify({ doctorName: formData?.d_name, diagnosis: formData?.d_diagnosis, admissionpresc: formData?.d_admission, date: formData?.d_date }) || {})
    formDataUpdated.append("latestPolicyData", JSON.stringify({ name: formData?.p_name, suminsured: formData?.p_insured, startDate: formData?.p_sDate, endDate: formData?.p_eDate }) || {})
    formDataUpdated.append("policyDocData", JSON.stringify({ date: formData?.y_date, activePolicy: formData?.y_policy, suminsured: formData?.y_sum }) || {})
    formDataUpdated.append("latestReceiptData", JSON.stringify({ policyName: formData?.i_policy_name, policyUin: formData?.i_policy_uin }) || {})
    setLoader(true);
    dispatch({ type: 'SET_PROMPT', payload: formData.admission })
    localStorage.setItem("prompt", formData.admission);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/patient/createpatient",
        data: formDataUpdated,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 201) {
        setLoader(false);
        toast.success("SuccessFull.");
        setTimeout(() => {
          if (localStorage.getItem("isCashless")) {
            navigate("/financial");
          } else {
            navigate("/credit");
          }
          setFormData(initialValue);
        }, 3000);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.status === 500) {
        setLoader(false);
      }
    }
  };

  const [edit1, setEdit1] = useState(false);
  const [edit2, setEdit2] = useState(false);
  const [edit3, setEdit3] = useState(false);
  const [edit4, setEdit4] = useState(false);

  function handleEdit1() {
    setEdit1(!edit1);
    setEdit2(false);
    setEdit3(false);
    setEdit4(false);
  }
  function handleEdit2() {
    setEdit1(false);
    setEdit2(!edit2);
    setEdit3(false);
    setEdit4(false);
  }
  function handleEdit3() {
    setEdit1(false);
    setEdit2(false);
    setEdit3(!edit3);
    setEdit4(false);
  }
  function handleEdit4() {
    setEdit1(false);
    setEdit2(false);
    setEdit3(false);
    setEdit4(!edit4);
  }

  const [rEdit1, setREdit1] = useState(false);
  const [rEdit2, setREdit2] = useState(false);

  function handleREdit1() {
    setREdit1(!rEdit1);
    setREdit2(false);
  }
  function handleREdit2() {
    setREdit1(false);
    setREdit2(!rEdit2);
  }


  const [pEdit1, setPEdit1] = useState(false);
  const [pEdit2, setPEdit2] = useState(false);
  const [pEdit3, setPEdit3] = useState(false);
  const [pEdit4, setPEdit4] = useState(false);

  function handlePEdit1() {
    setPEdit1(!pEdit1);
    setPEdit2(false);
    setPEdit3(false);
    setPEdit4(false);
  }
  function handlePEdit2() {
    setPEdit1(false);
    setPEdit2(!pEdit2);
    setPEdit3(false);
    setPEdit4(false);
  }
  function handlePEdit3() {
    setPEdit1(false);
    setPEdit2(false);
    setPEdit3(!pEdit3);
    setPEdit4(false);
  }
  function handlePEdit4() {
    setPEdit1(false);
    setPEdit2(false);
    setPEdit3(false);
    setPEdit4(!pEdit4);
  }
  const [yEdit1, setYEdit1] = useState(false);
  const [yEdit2, setYEdit2] = useState(false);
  const [yEdit3, setYEdit3] = useState(false);
  const [yEdit4, setYEdit4] = useState(false);

  function handleYEdit1() {
    setYEdit1(!yEdit1);
    setYEdit2(false);
    setYEdit3(false);
    setYEdit4(false);
  }
  function handleYEdit2() {
    setYEdit1(false);
    setYEdit2(!yEdit2);
    setYEdit3(false);
    setYEdit4(false);
  }
  function handleYEdit3() {
    setYEdit1(false);
    setYEdit2(false);
    setYEdit3(!yEdit3);
    setYEdit4(false);
  }

  const [iEdit1, setIEdit1] = useState(false);
  const [iEdit2, setIEdit2] = useState(false);

  function handleIEdit1() {
    setIEdit1(!iEdit1);
    setIEdit2(false);
  }
  function handleIEdit2() {
    setIEdit1(false);
    setIEdit2(!iEdit2);
  }

  const onConfirm = () => {
    setIsConfirmationDialogOpen(false);
    handleSubmit();
  }
  return (
    <>
      <AlertContainer />
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <NavBar />
          <div>
            <div className="form-bg">
              <div style={{ marginBottom: "16px" }}>
                <div className="cards">
                  <div className="carding">
                    <img src={DoneImg} alt="" />
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="active-case">Personal Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <p className="step-number">2</p>
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="active-case">Case Information</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <p className="step-number">3</p>
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="inactive-case">Credit Document</h4>
                    </div>
                  </div>
                  <div className="carding">
                    <p className="step-number">4</p>
                    <h3 className="step-text">Step</h3>
                    <div>
                      <h4 className="inactive-case">Financial Information</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="bg-gray card rounded bg-white shadow-sm"
                style={{ padding: "30px" }}
              >
                <h3 className="admission-title pb-3">Medical Info</h3>
                {/* <div className="col-lg-4 col-sm-12 mb-3">
                  <input type="text" className="form-control" />
                </div> */}
                <div className="flex flex-wrap card-gap">
                  <div className="aadhar-card">

                    <div className="flex justify-between items-center">
                      <h6 className="aadhar-card-title">
                        Upload Prescription *
                      </h6>
                      {/* <img src={show} alt="" /> */}
                    </div>
                    <div
                      className="flex justify-between items-center"
                      style={{ gap: "20px", paddingTop: "14px" }}
                    >
                      <div className="dar " style={{ width: "100%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image1" className="form-control">
                          {formData.image1 === null
                            ? "Upload"
                            : formData.image1.name.substring(0, 50)}
                          <input
                            type="file"
                            ref={imageRef1}
                            accept="image/* , application/pdf"
                            onChange={(e) => handleImage(e, "image1", "prescription")}
                            id="image1"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="aadhar-detail-card">

                      {formData.dc_name !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Doctors Name</p>
                        {edit1 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="dc_name"
                            value={formData.dc_name}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleEdit1}>
                            {formData.dc_name}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleEdit1} alt="" />
                      </div>}

                      {formData.diagnosis !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Diagnosis</p>
                        {edit2 ? (
                          <Select
                            type="text"
                            className="card-input"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                boxShadow: "none",
                                border: "none",
                                padding: "0px",
                                background: "transparent",

                              }),
                            }}
                            name="diagnosis"
                            value={options.find(option => option.value === formData.diagnosis)}
                            onChange={option => handleChange({ target: { name: 'diagnosis', value: option.value } })}
                            options={options}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleEdit2}>
                            {formData.diagnosis}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleEdit2} alt="" />
                      </div>}

                      {formData.admission !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">
                          Admission Prescribed For disease/condition
                        </p>
                        {edit3 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="admission"
                            value={formData.admission}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleEdit3}>
                            {formData.admission}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleEdit3} alt="" />
                      </div>}

                      {formData.date !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Date</p>
                        {edit4 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleEdit4}>{formData.date}</p>
                        )}
                        <img src={EditIcon} onClick={handleEdit4} alt="" />
                      </div>}

                    </div>
                  </div>

                  <div>
                    <div className="col-md-12 text-start mt-2">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Is the cause of admission any accident? *
                      </label>
                      <div className="row ms-1">
                        <div className="form-check col-md-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={handleChange}
                            name="accident"
                            id="flexRadioDefault1"
                            value="yes"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check col-md-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="no"
                            name="accident"
                            onChange={handleChange}
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <label className="form-label pt-3">
                        Please Upload a copy of FIR / MLC
                      </label>
                      <div className="dar " style={{ width: "100%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image2" className="form-control">
                          {formData.accident === "no"
                            ? formData.image2 === null
                              ? "Not Required"
                              : formData.image2.name.substring(0, 50)
                            : formData.image2 === null
                              ? "Upload"
                              : formData.image2.name.substring(0, 50)}
                          <input
                            type="file"
                            accept="image/*"
                            ref={imageRef2}
                            onChange={(e) => handleImage(e, "image2")}
                            id="image2"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                      </div>
                      <label className="form-label pt-4">
                        Before coming to this hospital, did you visit any other
                        doctor? *
                      </label>
                      <div className="row ms-1">
                        <div className="form-check col-md-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="doctor"
                            value="yes"
                            onChange={handleChange}
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check col-md-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="doctor"
                            value="no"
                            onChange={handleChange}
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-wrap card-gap"
                  style={{ paddingTop: "30px" }}
                >
                  <div className="aadhar-card">
                    <div className="flex justify-between items-center">
                      <h6 className="aadhar-card-title">
                        Upload Diagnostic reports *
                      </h6>
                      {/* <img src={show} alt="" /> */}
                    </div>
                    <div
                      className="flex justify-between items-center"
                      style={{ gap: "20px", paddingTop: "14px" }}
                    >
                      <div className="dar " style={{ width: "100%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image3" className="form-control">
                          {formData.image3 === null
                            ? "Upload"
                            : formData.image3.name.substring(0, 50)}
                          <input
                            type="file"
                            ref={imageRef3}
                            accept="image/*, application/pdf"
                            onChange={(e) => handleImage(e, "image3", "testReport")}
                            id="image3"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                        <p
                          className="mb-1"
                          style={{
                            paddingTop: "10px",
                            color: "#1185CB",
                            fontFamily: "Inter",
                            fontSize: "9px",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          The diagnostic report should not be more than 3 months
                          old.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="aadhar-detail-card">

                      {formData?.r_dc_name !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Doctors Name</p>
                        {rEdit1 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="r_dc_name"
                            value={formData?.r_dc_name}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleREdit1}>
                            {formData.r_dc_name}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleREdit1} alt="" />
                      </div>}

                      {formData?.r_date !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Date</p>
                        {rEdit2 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="r_date"
                            value={formData.r_date}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handleREdit2}>
                            {formData.r_date}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handleREdit2} alt="" />
                      </div>}
                    </div>
                  </div>
                  <div className="aadhar-card" style={{ height: 'fit-content' }}>
                    <div className="flex justify-between items-center">
                      <h6 className="aadhar-card-title">
                        Upload Prescription and other documents
                      </h6>
                      {/* <img src={show} alt="" /> */}
                    </div>
                    <div
                      className="flex justify-between items-center"
                      style={{ gap: "20px", paddingTop: "14px" }}
                    >
                      <div className="dar " style={{ width: "100%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image4" className="form-control">
                          {formData.accident === "no"
                            ? formData.image4 === null
                              ? "Not Required"
                              : formData.image4.name.substring(0, 50)
                            : formData.image4 === null
                              ? "Upload"
                              : formData.image4.name.substring(0, 50)}
                          <input
                            type="file"
                            ref={imageRef4}
                            onChange={(e) => handleImage(e, "image4")}
                            id="image4"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                        <p
                          className="mb-1"
                          style={{
                            paddingTop: "10px",
                            color: "#1185CB",
                            fontFamily: "Inter",
                            fontSize: "9px",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          Not Required if don't have any other documents
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="admission-title m-0 py-4">
                  Insurance Policy Info
                </h3>
                <div className="flex flex-wrap card-gap" >
                  <div className="aadhar-card">
                    <div className="flex justify-between items-center">
                      <h6 className="aadhar-card-title">
                        Policy Document*
                      </h6>
                      {/* <img src={show} alt="" /> */}
                    </div>
                    <div
                      className="flex justify-between items-center"
                      style={{ gap: "20px", paddingTop: "14px" }}
                    >
                      <div className="dar " style={{ width: "100%" }}>
                        <img src={enclouse} alt="" />
                        <label htmlFor="image5" className="form-control">
                          {formData.image5 === null
                            ? "Upload"
                            : formData.image5.name.substring(0, 50)}
                          <input
                            type="file"
                            ref={imageRef5}
                            accept=".doc,.docx,application/pdf"
                            onChange={(e) => handlePDF(e, "image5", "cover")}
                            id="image5"
                            hidden
                            aria-describedby="emailHelp"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="aadhar-detail-card">

                      {formData.p_name !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">name</p>
                        {pEdit1 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="p_name"
                            value={formData.p_name}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handlePEdit1}>
                            {formData.p_name}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handlePEdit1} alt="" />
                      </div>}

                      {formData.p_sDate !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Policy Date</p>
                        {pEdit2 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="p_sDate"
                            value={formData.p_sDate}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handlePEdit2}>
                            {formData.p_sDate}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handlePEdit2} alt="" />
                      </div>}

                      {formData.p_eDate !== "" && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Policy end Date</p>
                        {pEdit3 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="p_eDate"
                            value={formData.p_eDate}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handlePEdit3}>
                            {formData.p_eDate}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handlePEdit3} alt="" />
                      </div>}

                      {formData.p_insured !== '' && <div className="flex justify-between items-center">
                        <p className="aadhar-detail-text">Sum Insured</p>
                        {pEdit4 ? (
                          <input
                            type="text"
                            className="card-input"
                            name="p_insured"
                            value={formData.p_insured}
                            onChange={handleChange}
                          />
                        ) : (
                          <p className="aadhar-detail-text" onClick={handlePEdit4}>
                            {formData.p_insured}
                          </p>
                        )}
                        <img src={EditIcon} onClick={handlePEdit4} alt="" />
                      </div>}

                    </div>
                  </div>

                </div>
                <div className="aadhar-card" style={{ marginTop: "30px" }}>
                  <div className="flex justify-between items-center">
                    <h6 className="aadhar-card-title">
                      Last 2yrs policy documents
                    </h6>
                    {/* <img src={show} alt="" /> */}
                  </div>
                  <div
                    className="flex justify-between items-center"
                    style={{ gap: "20px", paddingTop: "14px" }}
                  >
                    <div className="dar " style={{ width: "100%" }}>
                      <img src={enclouse} alt="" />
                      <label htmlFor="image6" className="form-control">
                        {formData.accident === "no"
                          ? formData.image6 === null
                            ? "Not Required"
                            : formData.image6.name.substring(0, 50)
                          : formData.image6 === null
                            ? "Upload"
                            : formData.image6.name.substring(0, 50)}
                        <input
                          type="file"
                          ref={imageRef6}
                          onChange={(e) => handleImage(e, "image6")}
                          id="image6"
                          hidden
                          aria-describedby="emailHelp"
                        />
                      </label>
                      <p
                        className="mb-1"
                        style={{
                          paddingTop: "10px",
                          color: "#1185CB",
                          fontFamily: "Inter",
                          fontSize: "9px",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        Not Required if you haven't ported the policy
                      </p>
                    </div>
                  </div>
                  <div className="aadhar-detail-card">

                    {formData.y_date !== '' && <div className="flex justify-between items-center">
                      <p className="aadhar-detail-text">
                        Policy Inception Date
                      </p>
                      {yEdit1 ? (
                        <input
                          type="text"
                          className="card-input"
                          name="y_date"
                          value={formData.y_date}
                          onChange={handleChange}
                        />
                      ) : (
                        <p className="aadhar-detail-text">{formData.y_date}</p>
                      )}
                      <img src={EditIcon} onClick={handleYEdit1} alt="" />
                    </div>}

                    {formData.y_policy !== '' && <div className="flex justify-between items-center">
                      <p className="aadhar-detail-text">
                        Currently Active Policy
                      </p>
                      {yEdit2 ? (
                        <input
                          type="text"
                          className="card-input"
                          name="y_policy"
                          value={formData.y_policy}
                          onChange={handleChange}
                        />
                      ) : (
                        <p className="aadhar-detail-text">
                          {formData.y_policy}
                        </p>
                      )}
                      <img src={EditIcon} onClick={handleYEdit2} alt="" />
                    </div>}

                    {formData.y_sum !== '' && <div className="flex justify-between items-center">
                      <p className="aadhar-detail-text">Sum Insured</p>
                      {yEdit3 ? (
                        <input
                          type="text"
                          className="card-input"
                          name="y_sum"
                          value={formData.y_sum}
                          onChange={handleChange}
                        />
                      ) : (
                        <p className="aadhar-detail-text">{formData.y_sum}</p>
                      )}
                      <img src={EditIcon} onClick={handleYEdit3} alt="" />
                    </div>}

                  </div>
                </div>
                <div className="row save m-lg-1 ">
                  <button onClick={() => setIsConfirmationDialogOpen(true)}>Save and Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={isConfirmationDialogOpen}
        onCancel={() => setIsConfirmationDialogOpen(false)}
        footer={false}
      >
        <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>Confirm Submission</h4>

        <div className="flex justify-between items-center">
          <p className="aadhar-detail-text">Diagnosis</p>
          {edit2 ? (
            <Select
              type="text"
              className="card-input"
              styles={{
                control: (base, state) => ({
                  ...base,
                  boxShadow: "none",
                  border: "none",
                  padding: "0px",
                  background: "transparent",

                }),
              }}
              name="diagnosis"
              value={options.find(option => option.value === formData.diagnosis)}
              onChange={option => handleChange({ target: { name: 'diagnosis', value: option.value } })}
              options={options}
            />
          ) : (
            <p className="aadhar-detail-text" onClick={handleEdit2}>
              {formData.diagnosis}
            </p>
          )}
          <img src={EditIcon} onClick={handleEdit2} alt="" />
        </div>

        <div className="flex justify-between items-center">
          <p className="aadhar-detail-text">
            Admission Prescribed For disease/condition
          </p>
          {edit3 ? (
            <input
              type="text"
              className="card-input"
              name="admission"
              value={formData.admission}
              onChange={handleChange}
            />
          ) : (
            <p className="aadhar-detail-text" onClick={handleEdit3}>
              {formData.admission}
            </p>
          )}
          <img src={EditIcon} onClick={handleEdit3} alt="" />
        </div>


        <div style={{ display: "flex", justifyContent: "space-between" }} >
          <div className="submit_br">
            <button onClick={onConfirm} style={{ marginTop: 10 }}>Confirm</button>
          </div>
        </div>
      </Modal>

      {loader ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Case;
