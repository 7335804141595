import React, { useContext, useEffect } from "react";
import SideBar from "../../component/SideBar";
import DoneImg from "../../images/done.svg";
import leftArrow from "../../images/left-arrow.svg";
import { useState } from "react";
import { baseUrl } from "../../context/common/data";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AlertContainer } from "../../component/Alerts/ToastifyContainer";
import SidebarContext from "../../context/sidebar/SidebarContext";
import { useDispatch, useSelector } from "react-redux";
import { notification, Space, Table } from "antd";
import { DeleteOutlined } from '@ant-design/icons';

const Facility = () => {
  const side = useContext(SidebarContext);
  const { currentUser, hospitalId } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    hospitalId: state.global.hospitalId,

  }));
  const dispatch = useDispatch();
  const { open, toggleOpen } = side;
  const navigate = useNavigate();
  const [selectedSurgeries, setSelectedSurgeries] = useState([]);
  const [selectedOT, setSelectedOT] = useState([]);
  const initialValue = {
    icuCount: "",
    otCount: "",
    room: "",
    rent: "",
    other: "",
    otherSurgery: ""
  };
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState(initialValue);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeCheckbox = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedSurgeries([...selectedSurgeries, value]);
    } else {
      setSelectedSurgeries(selectedSurgeries.filter((item) => item !== value));
    }
  };
  const handleChangeOT = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedOT([...selectedOT, value]);
    } else {
      setSelectedOT(selectedOT.filter((item) => item !== value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { icuCount, otCount, other, otherSurgery } = formData;

   
    if (tableData.length == 0) {
      notification.error({ message: "Add room and rent!" })
      return
    }
    if (selectedOT.length == 0) {
      notification.error({ message: "OT is Required!" })
      return
    }
    if (selectedSurgeries.length == 0) {
      notification.error({ message: "Surgeries is Required!" })
      return
    }

    const formDataUpdated = new FormData();
    formDataUpdated.append("icuCount", formData.icuCount);
    formDataUpdated.append("otCount", formData.otCount);
    formDataUpdated.append("ot", selectedOT.join(","));
    if (other !== null) {
      formDataUpdated.append("otherOT", other);
    }
    // formDataUpdated.append("room", formData.room);
    // formDataUpdated.append("rent", formData.rent);
    formDataUpdated.append('roomFacility', JSON.stringify(tableData))
    formDataUpdated.append("surgeries", selectedSurgeries.join(","));
    if (otherSurgery !== null) {
      formDataUpdated.append("otherSurgery", otherSurgery);
    }
    formDataUpdated.append("hospitalId", hospitalId);


    // setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/signup/addfacilities",
        data: formDataUpdated,
        headers: {
          "content-type": "multipart/form-data",
          // authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.status === 201) {
        setLoader(false);
        toast.success("SuccessFull.");
        dispatch({ type: "SET_CURRENT_STEP", payload: "insuranceAdded" });
      } else {
        toast.error("Error!");
        setTimeout(() => {
          setFormData(initialValue);
        }, 3000);
      }
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.status === 500) {
        setLoader(false);
      }
    }
  };
  const deleteFacilityRoom = (record) => {
    console.log(record, "RECORD")
    setTableData(tableData.filter((item) => item.key !== record.key));
  }
  const handleAdd = (e) => {
    e.preventDefault()
    if (formData.room !== '' && formData.rent !== '') {
      setTableData(prevData => [
        ...prevData,
        {
          key: Date.now(),
          room: formData.room,
          rent: formData.rent
        }
      ]);
      setFormData({
        icuCount: formData?.icuCount,
        otCount: formData?.otCount,
        room: '',
        rent: '',
        other: formData?.other,
        otherSurgery: formData?.otherSurgery
      });
    } else {
      notification.error({ message: 'Please fill in both Room and Rent fields.' });
    }
  };
  const columns = [
    {
      title: 'Room',
      dataIndex: 'room',
      key: 'room'
    },
    {
      title: 'Rent',
      dataIndex: 'rent',
      key: 'rent'
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (value, record) => (
        <Space size="middle">
          <DeleteOutlined style={{ color: 'red' }} onClick={() => deleteFacilityRoom(value)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="form-bg">
        <div style={{ marginBottom: "16px" }}>
          <div className="cards">
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Basic Information</h4>
              </div>
            </div>
            <div className="carding">
              <img src={DoneImg} alt="" />
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Documents upload</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">3</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="active-case">Add Facilities</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">4</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Existing Insurance</h4>
              </div>
            </div>
            <div className="carding">
              <p className="step-number">5</p>
              <h3 className="step-text">Step</h3>
              <div>
                <h4 className="inactive-case">Declaration</h4>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bg-gray card rounded bg-white shadow-sm"
          style={{ padding: "30px" }}
        >
          <div className="text-center">
            <form onSubmit={handleSubmit} className="row">
              <div className="owner_doctor">
                <h3 className="text-dark justify-content-start d-flex">
                  Add Facilities
                </h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4 col-sm-12 mb-3 text-start">
                        <label
                          htmlFor="icuCount"
                          className="form-label"
                        >
                          ICU Count
                        </label>
                        <div className="dar">
                          <select
                            className="form-control"
                            name="icuCount"
                            id="icuCount"
                            value={formData.icuCount}
                            onChange={handleChange}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3 text-start">
                        <label htmlFor="otCount" className="form-label">
                          OT Count
                        </label>
                        <div className="dar">
                          <select
                            className="form-control"
                            name="otCount"
                            value={formData.otCount}
                            id="otCount"
                            onChange={handleChange}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <h5 className="text-dark mb-2 hy justify-content-start d-flex">
                  <span style={{ color: 'red' }}>*</span>OT
                </h5>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <div>
                    <label
                      className="checkbox-container m-0"
                      htmlFor="major"
                    >
                      <input
                        className="custom-checkbox"
                        onChange={handleChangeOT}
                        value="Major"
                        type="checkbox"
                        name="ot"
                        id="major"
                      />
                      <span className="checkmark"></span>
                      Major
                    </label>
                  </div>
                  <div>
                    <label
                      className="checkbox-container m-0"
                      htmlFor="minor"
                    >
                      <input
                        className="custom-checkbox"
                        onChange={handleChangeOT}
                        value="Minor"
                        type="checkbox"
                        name="ot"
                        id="minor"
                      />
                      <span className="checkmark"></span>
                      Minor
                    </label>
                  </div>
                  <div className="form-check w-fit hos-doc form-check111 ">
                    <label className="form-check-label" htmlFor="other">
                      Other
                    </label>
                    <input
                      value={formData.other}
                      className="form-check-input formis"
                      type="text"
                      name="other"
                      id="other"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-4">
                <div className="row">
                  <div className="col-md-4 text-start">
                    <label htmlFor="room" className="form-label" >
                      <span style={{ color: 'red' }}>*</span>Room
                    </label>
                    <div className="dar">
                      <input
                        placeholder="Ex:- Single-Occupency, Double-Ocupancy...."
                        value={formData.room}
                        onChange={handleChange}
                        className="form-control"
                        name="room"
                        id="room"
                      >
                      </input>
                    </div>
                  </div>
                  <div className="col-md-4 text-start">
                    <label htmlFor="rent" className="form-label">
                      <span style={{ color: 'red' }}>*</span>Rent
                    </label>
                    <div className="dar">
                      <input
                        value={formData.rent}
                        name="rent"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="rent"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 text-start flex">
                    <div className="add_btn">
                      <button onClick={(e) => handleAdd(e)}>Add</button>
                    </div>
                  </div>
                </div>
              </div>
              {tableData.length !== 0 && <Table columns={columns} dataSource={tableData} pagination={false} style={{ width: '65%', margin: '1em 0 0 1em', border: '1px solid #1185CB', borderRadius: '10px' }} />}
              <div className="mt-5">
                <h3 className="text-dark mb-4 hy justify-content-start d-flex">
                  <span style={{ color: 'red' }}>*</span>Surgeries
                </h3>
                <div className="row hos-doc1">
                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit ">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries1"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Proctology"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries1"
                        />
                        <span className="checkmark"></span>
                        Proctology
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries2"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Urology"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries2"
                        />
                        <span className="checkmark"></span>
                        Urology
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries3"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Cardiology"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries3"
                        />
                        <span className="checkmark"></span>
                        Cardiology
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit form-check111">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries4"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="neuro"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries4"
                        />
                        <span className="checkmark"></span>
                        neuro
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries5"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Ophthal/Eyes"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries5"
                        />
                        <span className="checkmark"></span>
                        Ophthal/Eyes
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries6"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Laparoscopy"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries6"
                        />
                        <span className="checkmark"></span>
                        Laparoscopy
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries7"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Oncology"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries7"
                        />
                        <span className="checkmark"></span>
                        Oncology
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit form-check111">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries8"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value=" Orthopedics"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries8"
                        />
                        <span className="checkmark"></span>
                        Orthopedics
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries9"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Pulmanology"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries9"
                        />
                        <span className="checkmark"></span>
                        Pulmanology
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit">
                      <label
                        className="checkbox-container m-0"
                        htmlFor="surgeries10"
                      >
                        <input
                          className="custom-checkbox"
                          onChange={handleChangeCheckbox}
                          value="Gynecology'"
                          type="checkbox"
                          name="surgeries"
                          id="surgeries10"
                        />
                        <span className="checkmark"></span>
                        Gynecology
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="w-fit flex items-center">
                      <label
                        className="form-check-label me-2"
                        htmlFor="otherSurgery"
                      >
                        Other
                      </label>
                      <input
                        value={formData.otherSurgery}
                        className="form-check-input formis"
                        type="text"
                        name="otherSurgery"
                        id="otherSurgery"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xl-3 mb-3 hos-doc2">
                    <div className="form-check w-fit"></div>
                  </div>
                </div>
              </div>

              <div className="row save m-lg-1 " style={{ justifyContent: "unset" }}>
                <button>Save and Next</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {loader ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Facility;
