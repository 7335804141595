import React from "react";

const Popup3 = () => {
  return (
    <>
      <div className="clients-pop2">
        <div className="client-container2">
          <div className="form87">
            <h2>Declaration is pending</h2>
            <form>
              <div className="pending_btn pending_btn1">
                <button className="pend">Pending</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup3;
