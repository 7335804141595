import React, { useContext, useEffect, useState } from "react";
import SideBar from "../component/SideBar";
import { AlertContainer } from "../component/Alerts/ToastifyContainer";
import SidebarContext from "../context/sidebar/SidebarContext";
import NavBar from "../component/NavBar";
import axios from "axios";
import { baseUrl } from "../context/common/data";
import { useNavigate } from "react-router";
import moment from "moment";
import { EditOutlined, FolderViewOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, notification } from "antd";
import view from '../images/search.png'

const NewAdmission = () => {
  const side = useContext(SidebarContext);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [links, setLinks] = useState({});
  const { open, toggleOpen } = side;
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [filterStatus, setFilterStatus] = useState({
    approve: false,
    reject: false,
    reassign: false,
  });
  const [totalFilter, setTotalFilter] = useState({
    totalApp: 0,
    approveApp: 0,
    rejectApp: 0,
    reassignApp: 0
  })
  const itemsPerPage = 10;


  const handleCancel = () => {
    setVisible(false);
  };
  const handleClick = (url) => {
    window.open(url, '_blank');
  };
  function handleOnEdit(id) {
    navigate(`/patientData2/${id}`);
  }
  async function handleView(id) {
    console.log(id)
    try {
      const res = await axios.post(
        `${baseUrl}/hospital/fetchDischarge/${id}`
      );
      if (res.data.success) {
        console.log(res.data.data)
        setLinks(res.data.data)
        setVisible(true)
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  }
  useEffect(() => {
    async function getData() {
      try {
        const res = await axios({
          method: "get",
          url: baseUrl + `/patient/getallhospitalpatient/${localStorage.getItem("hospitalId")}`,
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        const respon = res?.data?.data
        setOriginalItems(respon); // Store the original data
        setItems(respon);
        // handleFilter(respon)
      } catch (e) { }
    }
    getData();
  }, []);

  useEffect(() => {
    handleFilter(originalItems); // Always apply filters to the originalItems
  }, [originalItems, filterStatus]);

  const handleFilter = (data) => {
    let filteredData = data;

    // Check if any filter is active
    const isAnyFilterActive = Object.values(filterStatus).some(status => status);

    // Apply the filters only if at least one filter is active
    if (isAnyFilterActive) {
      filteredData = data.filter(e =>
        (filterStatus.approve && e?.l1status?.toLowerCase() === "approve") ||
        (filterStatus.reject && e?.l1status?.toLowerCase() === "reject") ||
        (filterStatus.reassign && e?.l1status?.toLowerCase() === "reassign")
      );
    }

    setTotalFilter({
      totalApp: data?.length,
      approveApp: data?.filter(e => e?.l1status?.toLowerCase() === "approve")?.length,
      rejectApp: data?.filter(e => e?.l1status?.toLowerCase() === "reject")?.length,
      reassignApp: data?.filter(e => e?.l1status?.toLowerCase() === "reassign")?.length,
    });
    let newFilteredData = []
    filteredData.forEach((value, index) => {
      if (value?.status === 'Completed') {
        newFilteredData.push(value)
      }
    });
    // Update the displayed items based on the filters
    setItems(newFilteredData);
  };


  const handleCheckboxChange = (selectedStatus) => {
    // Determine if the current status is already active
    const isCurrentlyActive = filterStatus[selectedStatus];

    // Reset all filters
    setFilterStatus({
      approve: false,
      reject: false,
      reassign: false
    });

    // If the current status was not active, set it to true
    if (!isCurrentlyActive) {
      setFilterStatus(prevState => ({
        ...prevState,
        [selectedStatus]: true
      }));
    }
  };

  const search = async (val) => {
    try {
      const res = await axios.post(
        `${baseUrl}/patient/getallSearchedhospitalpatient/${localStorage.getItem("hospitalId")}`,
        val
      );
      const respon = res?.data?.data
      console.log(res?.data, "CHECK")
      if (res?.data?.success) {
        setOriginalItems(respon);
        setItems(respon);
      } else {
        notification.error({ message: res?.data?.message || 'Something went wrong!' })
      }

    } catch (e) { }
  }


  const totalPages = Math.ceil(items.length / itemsPerPage);

  const displayItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  return (
    <>
      <AlertContainer />
      <div className="dashboard">
        <div
          className="side-overlay"
          style={{ left: open ? "0" : "100%" }}
          onClick={toggleOpen}
        ></div>
        <SideBar />
        <div className="dashboard-content">
          <NavBar />
          <div className="form-bg">

            <div className="ty_table mt-4" style={{ margin: '1em' }}>
              <h2 style={{ margin: '1em 0 1em 0' }}>Discharge List</h2>
              <Form onFinish={search} style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name="applicationNumber">
                  <Input placeholder="Search By Application No." style={{ height: '30px', width: '100%', borderRadius: '5px', outline: 'none', paddingLeft: '0.5em' }} />
                </Form.Item>
                <Form.Item name="patientName">
                  <Input placeholder="Search By Patient Name" style={{marginLeft:'1em', height: '30px', borderRadius: '5px', outline: 'none', paddingLeft: '0.5em' }} />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" style={{ backgroundColor: "rgb(62, 120, 155)", color: "white", marginLeft: '1.5em' }}>Search</Button>
                </Form.Item>
              </Form>
              <table className="table mt-0">
                <thead>
                  <tr className="app-table-head">
                    <th scope="col">Application Number</th>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Submission Date</th>
                    <th scope="col">Category</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {displayItems().map((element, index) => {
                    return (
                      <>
                        <tr key={index} className="app-table-body">
                          <td>{element?._id}</td>
                          <td>{element?.patient?.name || ""}</td>
                          <td>
                            {moment(element?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>{element?.patientType}</td>
                          <td

                          >
                            {element?.status}
                          </td>
                          <td >
                            <FolderViewOutlined onClick={() => handleView(element?._id)} title="View Discharge Doc." />

                            <EditOutlined onClick={() => handleOnEdit(element?._id)} style={{ marginLeft: '0.5em' }} />

                          </td>

                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              <div className="prev_mect">
                <div className="prev">
                  <span onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                  </span>
                </div>
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    onClick={() => handlePageChange(number)}
                    className={currentPage === number ? "active" : ""}
                  >
                    {number}
                  </button>
                ))}
                <div className="next">
                  <span
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
      <Modal
        open={visible}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: 'center' }} // Center align modal content
      >
        <h4 style={{ marginBottom: '1em' }}>VIEW DISCHARGE DOCUMENTS.</h4>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {Object.keys(links).map((key, index) => (
            <div key={index} style={{ marginBottom: '1em', marginRight: '1em', alignItems: 'center' }}>
              <Button
                onClick={() => handleClick(links[key])}
                style={{
                  backgroundColor: '#3c8dc3',
                  color: 'white',
                  borderRadius: '5px',
                  padding: '0.5em 1em',
                  cursor: 'pointer',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  width: '100%', // Set button width to 100% of the container
                }}
              >
                <img src={view} alt="view" style={{ height: '19px', marginRight: '0.5em' }} />
                <span>{key}</span>
              </Button>
            </div>
          ))}
        </div>
      </Modal>



    </>
  );
};

export default NewAdmission;

