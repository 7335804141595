import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.svg";
import { AlertContainer } from "../../component/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../context/common/data";
import { useDispatch } from "react-redux";
import { Button } from "antd";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loginMode, setLoginMode] = useState("password");
  const otpFields = useRef([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);


  const handlePasswordLogin = async () => {
    if (email === "" && toast.error("Email is Required!")) return;
    if (password === "" && toast.error("Password is Required!")) return;
    var raw = JSON.stringify({
      email: email,
      password: password,
    });
    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/hospital/login",
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res?.data?.success) {
        localStorage.setItem("hospitalLogin", true)
        localStorage.setItem("hospitalId", res?.data?.data?.user._id)
        localStorage.setItem("hospitalName", res?.data?.data?.user.hospitalName)
        dispatch({ type: "SET_CURRENT_USER", payload: res?.data?.data.user })
        localStorage.setItem("hospitalLogo", res?.data?.data?.user.logo)
        setLoader(false);
        setEmail("");
        setPassword("");
        setTimeout(() => {
          navigate("/patientData");
        }, 3000);
      } else {
        if (res.data?.data) {
          const obj = res?.data?.data
          // localStorage.setItem("hospitalId", res?.data?.data?.hospitalId)
          dispatch({ type: "SET_HOSPITAL_ID", payload: res?.data?.data.hospitalId })

          for (const key in obj) {
            if (obj.hasOwnProperty(key) && !obj[key]) {
              dispatch({ type: "SET_CURRENT_STEP", payload: key });
              break;
            }
          }
          navigate('/hospitalSignup')
          toast.error(res.data.message)
        } else {
          toast.error(res.data.message)
        }

      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      setEmail("");
      setPassword("");
      setLoader(false);
    }
  };
  const handleChange = (index, event) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = event.target.value;
    setOtp(updatedOtp);
    if (event.target.value && otpFields.current[index + 1]) {
      otpFields.current[index + 1].focus();
    }
  };

  const toggleLoginMode = () => {
    setLoginMode(loginMode === "password" ? "otp" : "password");
  };

  const handleKeyUp = (index, event) => {
    if (event.keyCode === 8 && otpFields.current[index - 1]) {
      otpFields.current[index - 1].focus();
    }
  };

  async function handleOnSubmit() {
    if (email === "" && toast.error("Email is Required!")) return;
    var raw = JSON.stringify({
      email: email,
    });
    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/hospital/sendotp",
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res?.status === 200) {
        setLoader(false);
        toast.success("SuccessFull.");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (e?.response?.status === 500) {
        setEmail("");
        setLoader(false);
      }
      setTimeout(() => {
        if (
          e?.response?.data?.message === "No Hospital Register with this number"
        ) {
          navigate("/hospitalSignup");
        }
      }, 3000);
    }
  }

  async function handleLogin() {
    if (email === "" && toast.error("Email is Required!")) return;
    if (otp[0] === "" && toast.error("otp is Required!")) return;
    if (otp[1] === "" && toast.error("otp is Required!")) return;
    if (otp[2] === "" && toast.error("otp is Required!")) return;
    if (otp[3] === "" && toast.error("otp is Required!")) return;
    if (otp[4] === "" && toast.error("otp is Required!")) return;
    if (otp[5] === "" && toast.error("otp is Required!")) return;
    var otps = JSON.stringify({
      email: email,
      otp: otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5],
    });
    try {
      const res = await axios({
        method: "post",
        url: baseUrl + "/hospital/submitotp",
        data: otps,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res)
      if (res?.status === 200) {
        localStorage.setItem("hospitalLogin", true)
        setLoader(false);

        setEmail("");
        toast.success("SuccessFull.");
        setTimeout(() => {
          navigate("/superAdmin/patientData");
        }, 3000);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (e?.response?.status === 500) {
        setLoader(false);
      }
    }
  }



  return (
    <>
      <AlertContainer />

      <div style={{ display: 'flex' }}>
        <div className="Login">
          <div className="Login-left">
            <img src={logo} alt="" />
          </div>
        </div>

        <div style={{ width: '60%', maxWidth: '800px', margin: 'auto' }}>

          <center>
            <h1>Login</h1>

            <div style={{ marginTop: '2em', display: 'flex' }}>
              <label style={{ marginRight: '3em' }}><span style={{ color: 'red' }}>*</span>Email:</label>
              <input
                className="inp"
                value={email}
                placeholder="Enter your email Id"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {loginMode === "password" ? (
              <>
                <div style={{ marginTop: '2em', display: 'flex' }}> <label style={{ marginRight: '1em' }}><span style={{ color: 'red' }}>*</span>Password:</label>
                  <input

                    type="password"
                    className="inp"
                    value={password}
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div
                  style={{ color: 'rgb(170, 178, 188', backgroundColor: 'transparent', border: 'none', textDecoration: 'none', cursor: 'pointer', marginTop: '1em' }}
                  onClick={toggleLoginMode
                  }>
                  Login with OTP?
                </div>
              </>
            ) : (
              <>
                <button className="getotp" onClick={handleOnSubmit}>
                  Get OTP
                </button>
                <h3>Enter OTP</h3>
                <p>Please enter the OTP sent to Your Mail</p>
                <div className="otp-field" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={digit}
                      onChange={(e) => handleChange(index, e)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                      ref={(input) => (otpFields.current[index] = input)}
                    />
                  ))}
                </div>
                <div className="toggle-login-mode"
                  style={{ color: 'rgb(170, 178, 188', backgroundColor: 'transparent', border: 'none', textDecoration: 'none', cursor: 'pointer' }}
                  onClick={toggleLoginMode
                  }>
                  Login with Password?
                </div>
              </>
            )}
            <div style={{ width: "300px", display: "flex", justifyContent: "space-around" }}>
              <Button
                className="log"
                onClick={loginMode === "password" ? handlePasswordLogin : handleLogin}
              >
                Login
              </Button>
              <Button
                className="log"
                onClick={() => navigate('/hospitalSignup')}
              >
                Register
              </Button>
            </div>

          </center>

          {/* <center >
            <h1>Login</h1>
            <label>Enter your Email*
              <input
                className="inp"
                value={email}
                placeholder="Enter your email Id"
                onChange={(e) => setEmail(e.target.value)}
              /></label>
            {loginMode === "password" ? (
              <>
                <label className="mt-5">Enter your Password
                  <input
                    type="password"
                    className="inp"
                    value={password}
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </label>
                <div
                  style={{ color: 'rgb(170, 178, 188', backgroundColor: 'transparent', border: 'none', textDecoration: 'none', cursor: 'pointer', marginTop: '1em' }}
                  onClick={toggleLoginMode
                  }>
                  Login with OTP?
                </div>
              </>
            ) : (
              <>
                <button className="getotp" onClick={handleOnSubmit}>
                  Get OTP
                </button>
                <h3>Enter OTP</h3>
                <p>Please enter the OTP sent to Your Mail</p>
                <div className="otp-field">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={digit}
                      onChange={(e) => handleChange(index, e)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                      ref={(input) => (otpFields.current[index] = input)}
                    />
                  ))}
                </div>
                <div className="toggle-login-mode"
                  style={{ color: 'rgb(170, 178, 188', backgroundColor: 'transparent', border: 'none', textDecoration: 'none', cursor: 'pointer' }}
                  onClick={toggleLoginMode
                  }>
                  Login with Password?
                </div>
              </>
            )}
            <div style={{ width: "300px", display: "flex", justifyContent: "space-between" }}>
              <Button
                className="log"
                onClick={loginMode === "password" ? handlePasswordLogin : handleLogin}
              >
                Login
              </Button>
              <Button
                className="log"
                onClick={() => navigate('/hospitalSignup')}
              >
                Register
              </Button>
            </div>
          </center>*/}

        </div>

      </div>


    </>
  );
};

export default Login;
